<div style="position:relative;height: 100%;width: 500px;padding: 20px">
  <div style="height: 126px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #1BA8B1;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Cotizador
    </p>

    <div style="display: flex;">
      <span style="white-space: nowrap;vertical-align: middle;color: #1BA8B1;line-height: 10px">
        Reporte
      </span>
      <hr style="margin: auto auto auto 8px; height: 1px;width: 100%;border-top: 1px solid #E1E1E1;">
    </div>
  </div>
  <div class="detail">
    <div class="head">
      <div class="title">Martin Juarez Esquivel</div>
      <div class="date">12 Jul 2019</div>
    </div>
    <div class="body">
      marjuaes@axr.com
    </div>
    <hr style="margin: 10px 0;">
    <div>
      <div style="color: #1BA8B1;font-size: 12px;"><strong>Tipo de póliza:</strong>&nbsp;Seguro Robo</div>
      <div style="color: #1BA8B1;font-size: 12px;"><strong>Tipo de vehículo:</strong>&nbsp;SUV</div>
      <div style="color: #1BA8B1;font-size: 12px;"><strong>Año:</strong>&nbsp;2018</div>
      <div style="color: #1BA8B1;font-size: 12px;"><strong>Modelo:</strong>&nbsp;Buick Enclave - At/350 CV</div>
    </div>
  </div>
  <div style="display: flex; margin-top: 20px">
    <span style="white-space: nowrap;vertical-align: middle;color: #1BA8B1;line-height: 10px">
      <strong>Chrysler 300C 2019</strong><br>
      <sub>Seguro de daños materiales</sub>
    </span>
    <span style="margin: auto auto auto 8px; height: 1px;width: 100%;border-top: 1px solid #E1E1E1;"></span>
  </div>
  <div class="row">
    <div class="col-md-6 text-center mt-20" *ngFor="let item of [1,2,3,4]">
      <div class="p-badge" [ngStyle]="{'background': 'linear-gradient(206.57deg, rgba(255, 255, 255, 0.2) -90%, red 60%)'}">
        <img src="../../assets/img/tmp/Q.png">
      </div>
      <div class="mt-10">
        <div class="header" style="color: #1BA8B1;"><strong>Quálitas - Daños materiales</strong></div>
        <div class="body">
          <ul style="list-style: none;padding: 0;line-height: 12px;font-size: 12px;color:#7b7692;text-align: left;">
            <li>Cobertura uno</li>
            <li>Cobertura dos</li>
            <li>Cobertura tres</li>
          </ul>
        </div>
        <div class="footer" style="color: #1BA8B1;font-weight: bold">{{ '33486.33' | CustomCurrency}} MXN/AÑO</div>
        <button class="btn btn-custom btn-xs">Emitir póliza</button>
      </div>
    </div>
  </div>
</div>
