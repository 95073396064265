<div id="login-body" class="wrapper wrapper-full-page login-background" >
    <div class="content">
      <div class="login-box">
        <div class="left-side">
          <div>
            <div>Bienvenido a</div>
            <img src="../../assets/img/sx-logo-bkuic.svg" >
          </div>
          <div class="version">V 3.0.4</div>
        </div>
        <div class="right-side">
          <form [formGroup]="loginForm" (ngSubmit)="authentication()">
            <div class="text-center">
              <p class="title">Inicio de sesión</p>
              <div class="description">Para comenzar, ingresa los datos de la cuenta con la que accedes al portal en linea</div>
            </div>
            <div>
              <div class="form-group form-group-sm has-feedback">
                <label for="email">Email</label>
                <input type="text" id="email" class="form-control input-no-border" formControlName="username" required>
                <span class="sx-iconsys-icon form-control-feedback">m</span>
              </div>
              <div class="form-group form-group-sm has-feedback">
                <label for="password">Contraseña</label>
                <input type="password" id="password" class="form-control input-no-border" formControlName="password">
                <span class="sx-iconsys-icon form-control-feedback">l</span>
              </div>
            </div>

            <div class="text-center">
              <!--<div class="forgot-pass">
                <a href="javascript:;" (click)="openModalWithComponentRecovery()">Recuperar creedenciales</a>
              </div>-->
              <button type="submit" class="btn btn-sm btn-custom" [disabled]="loading">
                <strong>Acceder</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
  </div>
</div>

<ng-template #templateRestorePassword>
  <div class="modal-body text-center">
    <p>¿ESTÁ SEGURO QUE DESEA REESTABLECER LA CONTRASEÑA DEL ASEGURADO?</p>
    <button type="button" class="btn btn-custom  btn-srx" (click)="bsModalRef.hide()" >ACEPTAR</button>
  </div>
</ng-template>
