<div>

  <div class="row" style=" background-color: #ffffff; border-radius: 7px; padding-top: 30px;">

    <div class="col-md-12 text-right">
      <i class="glyphicon glyphicon-resize-small" (click)="goToBack()" style="cursor: pointer;" ></i>
    </div>

    <div class="col-md-8" style="border-right: 1px solid #EAECEF;">
      <div class="col-md-6 title-section">
        <div class="col-xs-12">Siniestralidad / Prima neta devengada</div>
      </div>
      <div class="col-md-6">
        <div class="title-section pull-right" >
          <div class="btn-group pull-right" dropdown>
            <button id="button-data-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
              {{dataTypeFilterSelected.name}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-data-type" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-data-types">
              <li role="menuitem" *ngFor="let item of dataTypeFilters" (click)="changeDataTypeFilter(item)">
                <a class="dropdown-item" href="javascript:;" >{{item.name}}</a>
              </li>
            </ul>
          </div>
          <div class="btn-group pull-right" dropdown>
            <button id="button-report-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
              {{staticsIncidentPolicyTypeSelected.description}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-report-type" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-report-types">
              <li role="menuitem" *ngFor="let policyType of policyTypeList" (click)="changePolicyTypeStaticsIncident(policyType)">
                <a class="dropdown-item" href="javascript:;" >{{policyType.description}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="chart-container display-flex" *ngIf="showStaticsAccidentsNoData">
          <div class="col-md-12 text-center" style="font-size: 20px; font-weight: bold; margin: auto;">Sin datos que mostrar</div>
        </div>
        <div class="chart-container" [hidden]="showStaticsAccidents">
          <canvas #line1Chart id="line1Chart" >{{ line1Chart }}</canvas>
        </div>
      </div>


    </div>

    <div class="col-md-4">
      <div class="col-md-12 title-section">
        <div class="col-md-6">Estatus de cobranza</div>
        <div class="col-md-6">
          <div class="title-section pull-right">
            <div class="btn-group pull-right" dropdown>
              <button id="button-report-type" dropdownToggle type="button"
                      class="btn btn-link dropdown-toggle subtitle-section-menu">
                {{staticsCollectionPolicyTypeSelected.description}}
                <span class="sx-iconsys-icon">1</span>
              </button>
              <ul id="dropdown-report-type2" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                  aria-labelledby="button-report-type">
                <div *ngIf="policyTypeCollecionList !== null && policyTypeCollecionList.length > 0 else nobranch">
                  <li role="menuitem" *ngFor="let policyType of policyTypeList"
                      (click)="changePolicyTypeStaticsCollection(policyType)">
                    <a class="dropdown-item" href="javascript:;">{{policyType.description}}</a>
                  </li>
                </div>

                <ng-template #nobranch>
                  <li role="menuitem">
                    <a class="dropdown-item" href="javascript:;">Sin tipo de póliza</a>
                  </li>
                </ng-template>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-12" >
        <div class="chart-container">
          <canvas #pieChart id="pieChart">{{ pieChart }}</canvas>
        </div>
      </div>
    </div>

    <div class="col-md-12 title-section" style="border-top: 1px solid #EAECEF;">
      <div class="col-md-12"><br><br><br></div>
    </div>

    <div class="col-md-3" style="border-right: 1px solid #EAECEF;">
      <div class="col-md-12 title-section">
        <div *ngIf="branchIdSelected !== '3'" class="col-xs-12">Población Total</div>
        <div *ngIf="branchIdSelected === '3'" class="col-xs-12">Parque Vehicular</div>
      </div>
      <div class="col-md-12">
        <div class="chart-container" >

          <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let p of totalPopulations">
            <br>
            <div class="card card-stats">
                <div class="row">
                  <div class="col-12 col-md-12 receipt-detail">
                    <div class="title-section" >
                      <strong>{{p.name}}</strong>
                    </div>
                  </div>
                </div>
              <div class="card-footer ">
                <div *ngIf="branchIdSelected !== '3'" class="title-section" style="font-size: 14px; font-weight: bold; text-align: righ?;">
                  <strong>Asegurados: {{p.insured}} </strong><br/>
                  <strong>Dependientes: {{p.kinship}}</strong>
                </div>
                <div *ngIf="branchIdSelected === '3'" class="title-section" style="font-size: 14px; font-weight: bold; text-align: righ?;">
                <strong>Autos Asegurados: {{p.insured}} </strong>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-5" style="border-right: 1px solid #EAECEF;">
      <div class="col-md-12 title-section">
        <div class="col-xs-12">Uso de póliza</div>
      </div>
      <div class="col-md-12">
        <div class="chart-container display-flex" *ngIf="showStaticsInsurancePremium">
          <div class="col-md-12 text-center" style="font-size: 20px; font-weight: bold; margin: auto;">Sin datos que mostrar</div>
        </div>
        <div class="chart-container" [hidden]="showStaticsInsurancePremium">
          <canvas id="line2Chart" #line2Chart></canvas>
        </div>
      </div>
    </div>

    <div class="col-md-4" style="border-right: 1px solid #EAECEF;">

      <div class="col-md-12 title-section">
        <div *ngIf="branchIdSelected !== '3'" class="col-md-6">Siniestralidad empleados</div>
        <div *ngIf="branchIdSelected === '3'" class="col-md-6">Siniestralidad flotilla</div>

        <div class="col-md-12">
          <div class="chart-container display-flex" *ngIf="showStaticsIncident">
            <div class="col-md-12 text-center" style="font-size: 20px; font-weight: bold; margin: auto;">Sin datos que mostrar</div>
          </div>
          <div class="chart-container" [hidden]="showStaticsIncident">
            <canvas #lineChart id="lineChart" >{{ chart }}</canvas>
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-12 title-section" style="border-top: 1px solid #EAECEF;" >
      <div class="col-md-12"><br><br><br></div>

    </div>
    <div class="col-md-8" style="border-right: 1px solid #EAECEF;" *ngIf="debtorLife>0">

      <div class="col-md-12 title-section">
        <div class="col-md-6">Vida deudor</div>
        <div class="col-md-6">

          <div class="btn-group pull-right" dropdown>
            <button id="button-report-types5" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
              {{staticsCompanyTypeSelected}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-report-type5" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-report-types">
              <li role="menuitem" *ngFor="let company of dataTypeFiltersDebtor" (click)="changeCompanyTypeStaticsDebtorLife(company)">
                <a class="dropdown-item" href="javascript:;" >{{company}}</a>
              </li>

            </ul>
          </div>


          <div class="title-section pull-right" >
            <div class="btn-group pull-right" dropdown>
              <button id="button-data-types-debtor" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
                {{dataTypeFilterSelectedDebtorLife.name}}
                <span class="sx-iconsys-icon">1</span>
              </button>
              <ul id="dropdown-data-type-debtor" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-data-types">
                <li role="menuitem" *ngFor="let item of dataTypeDetorFilter" (click)="changeDataTypeFilterDebtor(item)">
                  <a class="dropdown-item" href="javascript:;" >{{item.name}}</a>
                </li>
              </ul>
            </div>



          </div>
        </div>

        <div class="col-md-12">
          <div class="chart-container display-flex" *ngIf="showStaticsDebtorlife">
            <div class="col-md-12 text-center" style="font-size: 20px; font-weight: bold; margin: auto;">Sin datos que mostrar</div>
          </div>
          <div class="chart-container" [hidden]="showStaticsIncident">
            <canvas #line3Chart id="line3Chart" >{{ chart }}</canvas>
          </div>
        </div>

      </div>

    </div>

  </div>
</div>
