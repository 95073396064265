<div class="wrapper wrapper-full-page" >
  <div class="full-page login-page" data-color="" data-image="../../assets/img/Surexs-dt-bg.png">
    <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
    <div class="content">
      <div class="container">
        <div class="login-box">
          <form [formGroup]="recoveryForm" (ngSubmit)="recovery()" *ngIf="loading">
            <div class="title">
              <div>Bienvenido a</div>
              <img src="../../assets/img/sx-logo-blue.png">
            </div>
            <div class="text-center">
              <p class="description">Por favor ingrese su nueva contraseña</p>
            </div>
            <div>
              <div class="form-group has-feedback">
                <label for="password">Contraseña</label>
                <input type="password" id="password" class="form-control input-no-border" formControlName="password">
                <span class="glyphicon glyphicon-lock form-control-feedback form-control-feedback-" aria-hidden="true" style="opacity: 0.5;"></span>
              </div>
            </div>
            <div class="button-area text-center">
              <button type="submit" class="btn btn-custom" [disabled]="recoveryForm.invalid">
                <strong>Actualizar</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div class="full-page-background" style="background-image: url(../../assets/img/Surexs-dt-bg.png) "></div>
  </div>
</div>
