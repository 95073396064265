<div style="position:relative;height: 100%;width: 340px">
  <div style="height: 130px;width: 100%; border-bottom: 1px solid #C4C4C4; text-align: center;padding: 22px">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #1BA8B1;font-size: 16px;font-weight: 600;line-height: 19px;margin-top: 33px">
      Soporte Surexs
    </p>
  </div>
  <div style="max-height: calc(100% - 200px);padding: 20px;position:absolute;bottom: 70px;overflow-y: auto;width: 100%;" id="chatContainer" #chatContainer>

    <!--<div *ngFor="let m of msgList" >
      <div [class]="userId == m.u._id?'msg-sent':'msg-received'">
        <p *ngIf="m.msg != ''" class="msg"> {{m.msg}}</p>
        <img [src]="shared.gProfileImg" *ngIf="userId == m.u._id" />
        <img src="../../assets/img/favicon.ico" *ngIf="userId != m.u._id" />
        <div class="timestamp">{{m._updatedAt.$date | date:'medium'}}</div>
      </div>
    </div>-->
    <div *ngFor="let m of msgList" ngif="false">
      <div [class]="userId == m.sender_email?'msg-sent':'msg-received'">
        <p *ngIf="m.content != ''" class="msg"> {{m.content}}</p>
        <!--<div *ngFor="let m1 of m.attachments">
          <img src="data:{{m1.image_type}};base64,{{m1.image_preview}}" class="msg">
        </div>-->
        <img [src]="shared.gProfileImg" *ngIf="userId == m.sender_email" />
        <img src="../../assets/img/favicon.ico" *ngIf="userId != m.sender_email" />
        <div class="timestamp">{{m.timestamp }}</div>
      </div>
    </div>
  </div>
  <div style="padding: 20px;border-top: 1px solid #C4C4C4;position:absolute;height: 70px;bottom: 0;width: 100%;">
    <form [formGroup]="chatForm" (ngSubmit)="sendMessage(null)">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Escribe tu mensaje" formControlName="message" required>
        <span class="input-group-btn">
          <button class="btn btn-default" type="submit"><i class="glyphicon glyphicon-send"></i></button>
        </span>
      </div><!-- /input-group -->;
    </form>
  </div>
</div>
