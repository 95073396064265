import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SharedService} from './shared-service.service';

@Injectable({
  providedIn: 'root'
})
export class RequestOptionsInterceptor implements HttpInterceptor {

  constructor(private shared: SharedService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    const authHeader = 'Basic ' + localStorage.getItem('token');
    const roleId = localStorage.getItem('roleId');
    const roleHeader = '' + roleId;

    let  newHeaders = req.headers.set('Authorization', authHeader);
    newHeaders = newHeaders.set('roleId', roleHeader);

    // Sí la vista es de solo lectura para un asegurado
    if (this.shared.fInsuredDataReadonly) {
      const insured = JSON.parse(localStorage.getItem('insuredView'));
      newHeaders = newHeaders.set('insuredId', insured.userId.toString());
    }
    if (Number(roleId) === 3 && (!req.url.includes('/ammia/v1.0/protected') && !req.url.includes('/ammia/v1.0/public'))) {
      newHeaders = newHeaders.set('customerId', this.shared.gCurrentCustomerId.value.toString());
      newHeaders = newHeaders.set('branchId', this.shared.gCurrentBranchId.value.toString());
    } else {
      newHeaders = newHeaders.set('customerId', this.shared.gCurrentCustomerId.value.toString());
    }

    // Clone the request to add the new header.
    const headReq = req.clone(
      {headers: newHeaders});
    // Pass on the cloned request instead of the original request.
    return next.handle(headReq);
  }
}
