import {Component, OnInit} from '@angular/core';
import {WebsocketService} from '../websocket.service';

@Component({
  selector: 'app-main-panel',
  templateUrl: './main-panel.component.html',
  styleUrls: ['./main-panel.component.css']
})
export class MainPanelComponent implements OnInit {

  constructor(private wsRocket: WebsocketService) { }

  ngOnInit() {
    // this.wsRocket.connectToHostname('');
    // this.wsRocket.signIn('mrsapper', 'Qwerty123.');
    // this.wsRocket.signIn('jramirez', 'Qwerty123.');
    // this.wsRocket.listChannels();
    // this.wsRocket.sendChatMessage();
    // this.streamingRoomMessages('myfcE87t6SqK9c6aA');
    // this.streamingUserMsgNotification();
  }
}

