import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {MainPanelComponent} from './main-panel/main-panel.component';
import {RecoveryComponent} from './recovery/recovery.component';
import {AdminHomeComponent} from './admin-home/admin-home.component';
import {AdminReportsComponent} from './admin-reports/admin-reports.component';
import {AdminPoliciesComponent} from './admin-policies/admin-policies.component';
import {AdminStaticsComponent} from './admin-statics/admin-statics.component';
import {AdminInsuredComponent} from './admin-insured/admin-insured.component';
import {RequestTempDetailComponent} from './request-temp-detail/request-temp-detail.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'user',
    component: MainPanelComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      }
    ]
  },
  {
    path: 'admin',
    component: MainPanelComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: AdminHomeComponent
      },
      {
        path: 'reports',
        component: AdminReportsComponent
      },
      {
        path: 'policies',
        component: AdminPoliciesComponent
      },
      {
        path: 'statics',
        component: AdminStaticsComponent
      },
      {
        path: 'insured',
        component: AdminInsuredComponent
      },
      {
        path: 'request-detail',
        component: RequestTempDetailComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
