import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {HomeComponent} from './home/home.component';
import {ApiService} from './api.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MainPanelComponent} from './main-panel/main-panel.component';
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';

import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ProfileComponent} from './profile/profile.component';
import {NewIncidentComponent} from './new-incident/new-incident.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {NotificationMdlComponent} from './notification-mdl/notification-mdl.component';
import { RecoveryPasswordMdlComponent } from './recovery-password-mdl/recovery-password-mdl.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { ValidateCodeMdlComponent } from './validate-code-mdl/validate-code-mdl.component';
import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
import {WebsocketService} from './websocket.service';
import { ChatComponent } from './chat/chat.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { PolicyComponent } from './policy/policy.component';
import { IncidentComponent } from './incident/incident.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';
import { RequestComponent } from './request/request.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AdminPoliciesComponent } from './admin-policies/admin-policies.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AdminStaticsComponent } from './admin-statics/admin-statics.component';
import {RequestOptionsInterceptor} from './request-options.interceptor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {BsDatepickerModule, BsLocaleService} from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeMx from '@angular/common/locales/es-MX';
import {CustomCurrencyPipe} from './custom.currencypipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AdminInsuredComponent } from './admin-insured/admin-insured.component';
import { MassiveRequestComponent } from './massive-request/massive-request.component';
import { MassiveRequestVehicleComponent } from './massive-request-vehicle/massive-request-vehicle.component';
import { VehicleRequestComponent } from './vehicle-request/vehicle-request.component';
import { AdminHomeConcessionaireComponent } from './admin-home-concessionaire/admin-home-concessionaire.component';
import { AdminHomeGeneralComponent } from './admin-home-general/admin-home-general.component';
import { QuoteDetailComponent } from './quote-detail/quote-detail.component';
import { QuoteFormComponent } from './quote-form/quote-form.component';
import { QuoteHistoryComponent } from './quote-history/quote-history.component';
import { DebtorlifeComponent } from './debtorlife/debtorlife.component';
import { MenuComponent } from './menu/menu.component';
import { DebtorlifeValidationComponent } from './debtorlife-validation/debtorlife-validation.component';
import {UpperCaseTextDirective} from './upper-case-text.directive';
import {UploadCertificateComponent} from './upload-certificate/upload-certificate.component';
import { RequestTempDetailComponent } from './request-temp-detail/request-temp-detail.component';
import { NewRequestCarComponent } from './new-request-car/new-request-car.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgSelectModule} from '@ng-select/ng-select';
import {UiSwitchModule} from 'ngx-ui-switch';
import {PushNotificationModule} from 'ng-push-notification';

registerLocaleData(localeMx, 'es-MX');
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SidebarComponent,
        HomeComponent,
        MainPanelComponent,
        FooterComponent,
        NavbarComponent,
        ProfileComponent,
        NewIncidentComponent,
        NotificationMdlComponent,
        RecoveryPasswordMdlComponent,
        RecoveryComponent,
        ValidateCodeMdlComponent,
        ChatComponent,
        NewRequestComponent,
        PolicyComponent,
        IncidentComponent,
        AdminHomeComponent,
        AdminReportsComponent,
        RequestComponent,
        AdminPoliciesComponent,
        AdminStaticsComponent,
        CustomCurrencyPipe,
        AdminInsuredComponent,
        MassiveRequestComponent,
        MassiveRequestVehicleComponent,
        VehicleRequestComponent,
        AdminHomeConcessionaireComponent,
        AdminHomeGeneralComponent,
        QuoteDetailComponent,
        QuoteFormComponent,
        QuoteHistoryComponent,
        DebtorlifeComponent,
        MenuComponent,
        DebtorlifeValidationComponent,
        UpperCaseTextDirective,
        UploadCertificateComponent,
        RequestTempDetailComponent,
        NewRequestCarComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        NgxUsefulSwiperModule,
        PaginationModule.forRoot(),
        TypeaheadModule.forRoot(),
        TabsModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        InfiniteScrollModule,
        CollapseModule.forRoot(),
        NgSelectModule,
        UiSwitchModule,
        PushNotificationModule.forRoot()
    ],
  entryComponents: [
    NewIncidentComponent,
    NotificationMdlComponent,
    RecoveryPasswordMdlComponent,
    ValidateCodeMdlComponent
  ],
  providers: [ApiService,
    WebsocketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestOptionsInterceptor,
      multi: true,
    },
    DatePipe,
    { provide: LOCALE_ID, useValue: 'es-MX' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(localeService: BsLocaleService) {
    defineLocale('es', esLocale);
    localeService.use('es');
  }
}
