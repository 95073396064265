<div style="position:relative;min-height: 100%;width: 340px;padding: 20px">
  <div style="height: 126px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true"
          style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;"
          (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #1BA8B1;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Nueva Solicitud de autos
    </p>
    <div style="text-align: left">
      <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;">Solicitud de Autos.</span>
      <span
        style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 173px;border-top: 1px solid #E1E1E1;"></span>
    </div>
  </div>


  <div style="min-height: calc(100% - 180px);width: 100%;color: #1BA8B1">

    <!--+---------------------------------------------------------------------------------------------------+
        |-----------------------------------------|  A C C E S O S |----------------------------------------|
        +---------------------------------------------------------------------------------------------------+-->
    <div class="form-group form-group-sm">
      <label>Tipo de solicitud</label>
      <div class="btn-group btn-group-justified" role="group" aria-label="...">
        <div class="btn-group" role="group">
          <button [disabled]="currentRoleId != 3" type="button" class="btn btn-default"
                  [ngClass]="lclRequestType==0?'action-selected':''" (click)="selectRequestType(0)"
                  style="height: 30px;color: #9499B8;font-size: 11px;font-weight: 500;">Alta
          </button>
        </div>
        <div class="btn-group" role="group">
          <button [disabled]="currentRoleId != 3" type="button" class="btn btn-default"
                  [ngClass]="lclRequestType==1?'action-selected':''" (click)="selectRequestType(1)"
                  style="height: 30px;color: #9499B8;font-size: 11px;font-weight: 500;">Baja
          </button>
        </div>
      </div>
    </div>


    <form id="requestForm" [ngSwitch]="lclRequestType" #f="ngForm" (ngSubmit)="sendRequestData()" [noValidate]="false">

      <!--+---------------------------------------------------------------------------------------------------+
          |-------------------------------------------|  A L T A  |-------------------------------------------|
          +---------------------------------------------------------------------------------------------------+-->
      <div *ngSwitchCase="0">
               <div class="form-group form-group-sm">
          <label class="control-label">Pólizas</label>
          <div class="row">
            <ng-container *ngFor="let subranch of policiesList;  let i = index">
              <div class="form-group col-md-6">
                <label class="item"
                       style="font-size: small">{{subranch.subranch + ' ' + subranch.numberPolicy}}</label>
                <br>
                <ng-container *ngFor="let category of subranch.policyCategoriesList; let cat = index">
                  <input type="checkbox" [(ngModel)]="category.selected" [ngModelOptions]="{standalone: true}"/>
                  <label
                    style="font-size: xx-small; vertical-align: middle; color: #1BA8B1"> {{category.category}}</label>
                  <br>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <div style="text-align: left;padding-bottom: 10px">
          <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;">Auto</span>
          <span
            style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 220px;border-top: 1px solid #E1E1E1;"></span>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Marca</label>
          <ng-select (change)="typeaheadOnSelectMarca($event)"
                     [items]="dataSourceMarca"
                     bindLabel="name"
                     bindValue="name"
                     notFoundText="No se encontraron registros"
                     [(ngModel)]="dataToSend.mark"
                     name="mark"
                     id="mark">
          </ng-select>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Modelo</label>
          <ng-select (change)="typeaheadOnSelectModelo($event)"
                     [items]="dataSourceModelo"
                     bindLabel="name"
                     bindValue="id"
                     notFoundText="No se encontraron registros"
                     [(ngModel)]="dataToSend.model"
                     name="model"
                     id="modelo">
          </ng-select>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Versión</label>
          <ng-select
            [items]="dataSourceVersion"
            bindLabel="name"
            bindValue="name"
            notFoundText="No se encontraron registros"
            [(ngModel)]="dataToSend.version"
            id="version"
            name="version">
          </ng-select>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Placa</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.plate" name="plate" required
                 upperCaseText maxlength="10">
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">No. Serie</label>
          <input type="text" class="form-control" pattern="^[0-9A-HJ-NPR-Za-hj-npr-z\\d]{11}[0-9A-Z]{6}$" maxlength="17"
                 [(ngModel)]="dataToSend.serialNumber" name="serie" required #serie="ngModel">
          <div *ngIf="serie.invalid && serie.touched" class="alert alert-danger">
           Número de serie no válido
          </div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Número de motor</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.engineNumber" name="motor"
                 required>
        </div>
        <div *ngIf="dataToSend.model === yearActual || dataToSend.model === (yearActual + 1) || (monthActual === 1 && yearActual - 1)" class="form-group form-group-sm">
          <label class="control-label">¿La factura tiene menos de 10 días de ser emitida?</label>
          <div class="d-block my-3">
            <div class="custom-control custom-radio">
              <input id="authorize" type="radio" class="custom-control-input" se [value]="1"
                     (click)="changeValorFactura(1)"
                     [(ngModel)]="facturevalue" aria-selected="true" name="facturevalue">
              <label class="custom-control-label" for="authorize"> Si</label>
            </div>
            <div class="custom-control custom-radio">
              <input id="decline" type="radio" class="custom-control-input" [value]="2" (click)="changeValorFactura(2)"
                     [(ngModel)]="facturevalue"
                     name="facturevalue">
              <label class="custom-control-label" for="decline"> No</label>
            </div>
          </div>
        </div>

        <div *ngIf="facturevalue === 1 || facturevalue === '1'" class="form-group form-group-sm">
          <label class="control-label">Valor factura</label>
          <div class="input-group">
            <div class="input-group-addon">$</div>
            <input type="number" step="0.01" class="form-control" [(ngModel)]="dataToSend.valueInvoice" name="valueInvoice">
          </div>

          <div class="form-group form-group-sm">
            <label class="control-label">Archivo adjunto de la factura</label>
            <div class="form-group">
              <label class="btn btn-custom btn-sm btn-block"><i class="fa fa-folder-open"></i>&nbsp;Buscar...
                <input type="file" style="display: none" (change)="onChangeInputFile($event)" name="invoice">
              </label>
            </div>
          </div>
          <div class="form-group form-group-sm">
            <label class="control-label">Nombre de archivo</label>
            <div class="form-group">
              <label class="text-primary" *ngIf="fileUpload == undefined" [hidden]="error.active">----------</label>
              <label class="text-primary" style="width: 100%" *ngIf="fileUpload != undefined"
                     [hidden]="error.active">
                {{ fileUpload.name }} <span class="pull-right">{{shared.formatBytes(fileUpload.size, 2)}}</span>
              </label>
              <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
            </div>
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="control-label">Fecha de alta</label>
          <div class="input-group">
            <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
            <input type="text"
                   placeholder="00-00-0000"
                   class="form-control"
                   bsDatepicker
                   [bsConfig]="bsEntryDate"
                   [(ngModel)]="dataToSend.startDate"
                   name="entryDate"
                   required>
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="control-label title-element-car-policie">Uso</label>
          <ng-select [items]="dataSourceUse"
                     bindLabel="name"
                     bindValue="id"
                     notFoundText="No se encontraron registros"
                     id="use"
                     name="use"
                     [(ngModel)]="dataToSend.use"
                     dropdownPosition="bottom"
                     class="select-policies">
          </ng-select>
        </div>
        <!-- Tiene propietario el auto-->

        <div class="form-group form-group-sm">
          <label>¿El auto tiene propietario?</label>
          <ui-switch (change)="onChangeAddTitular($event)" checkedTextColor="#000000" color="#3232cd" size="small"
                       [checked]="addTitular"
                       name="addTitular"
                       labelOff="NO" labelOn="SI"
                       switchColor="#233568">
          </ui-switch>
        </div>

        <div *ngIf="addTitular">
          <div style="text-align: left;padding-bottom: 10px">
            <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;">Propietario del auto</span>
            <span
              style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 220px;border-top: 1px solid #E1E1E1;"></span>

            <div class="form-group form-group-sm">
              <label class="control-label">Nombre(s)</label>
              <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" upperCaseText>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Apellido Paterno</label>
              <input type="text" class="form-control" [(ngModel)]="dataToSend.firstName" name="pLastname" upperCaseText>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Apellido Materno</label>
              <input type="text" class="form-control" [(ngModel)]="dataToSend.lastName" name="sLastname" upperCaseText>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Correo electrónico</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="fa fa-envelope"></i></div>
                <input type="text" class="form-control" [(ngModel)]="dataToSend.email" name="email" required>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <!---------------||||| Adaptaciones de auto |||||------------------->
        <div style="text-align: left;padding-bottom: 10px">
              <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;cursor: pointer;"
                    title="Agregar Adaptaciones" (click)="activateAdaptationRegistrationInCar()">
                <i class="fa fa-plus-circle"></i>&nbsp;Adaptaciones
              </span>
          <span
            style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 145px;border-top: 1px solid #E1E1E1;"></span>
          <span class="badge pull-right" (click)="showAdaptations(templateAdaptationInfo)">
                <i *ngIf="invoiceAdaptationList?.length <= 0" class="fa-solid fa-car-on fa-sm"></i>
                <span style="cursor: pointer"
                      *ngIf="invoiceAdaptationList?.length > 0">+{{invoiceAdaptationList?.length}}</span>
              </span>
        </div>
        <div *ngIf="attachAdaptatio">
          <div class="form-group form-group-sm">
            <label class="control-label">Nombre de la adaptación</label>
            <input type="text" class="form-control" [(ngModel)]="adaptation.name" name="adaptationname"
                   upperCaseText>
          </div>
          <div class="form-group form-group-sm">
            <label class="control-label">Valor de la adaptación</label>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" [(ngModel)]="adaptation.value" name="value"
                     upperCaseText>
            </div>
          </div>
          <div class="form-group form-group-sm">
            <label class="control-label">Archivo adjunto de la factura</label>
            <div class="form-group">
              <label class="btn btn-custom btn-sm btn-block"><i class="fa fa-folder-open"></i>&nbsp;Buscar...
                <input type="file" style="display: none" (change)="onChangeInputFileAdaptation($event)" name="antiquie">
              </label>
            </div>
          </div>
          <div class="form-group form-group-sm">
            <label class="control-label">Nombre de archivo</label>
            <div class="form-group">
              <label class="text-primary" *ngIf="fileUploadAdaptation == undefined"
                     [hidden]="error.active">----------</label>
              <label class="text-primary" style="width: 100%" *ngIf="fileUploadAdaptation != undefined"
                     [hidden]="error.active">
                {{ fileUploadAdaptation.name }} <span
                class="pull-right">{{shared.formatBytes(fileUploadAdaptation.size, 2)}}</span>
              </label>
              <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
            </div>
          </div>
        </div>
      </div>

      <!--+---------------------------------------------------------------------------------------------------+
          |-------------------------------------------|  B A J A  |-------------------------------------------|
          +---------------------------------------------------------------------------------------------------+-->
      <div *ngSwitchCase="1">
        <!--|||||||||||||||||||||||||||||||||||  A U T O  |||||||||||||||||||||||||||||||||||-->

        <div class="form-group has-feedback">
          <label class="control-label">Auto</label>
          <span class="glyphicon glyphicon-search form-control-feedback" aria-hidden="true"></span>
          <input [(ngModel)]="searchItemSelected"
                 [typeaheadAsync]="true"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 typeaheadWaitMs="1000"
                 adaptivePosition = true
                 typeaheadOptionField="version"
                 placeholder="Buscar…"
                 class="form-control"
                 name="insured"
                 autocomplete="off">
          <div *ngIf="typeaheadLoading">Loading</div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Pólizas</label>
          <div class="row">
            <ng-container *ngFor="let subranch of policiesList;  let i = index">
              <div class="form-group col-md-6">
                <label class="item"
                       style="font-size: small">{{subranch.subranch + ' ' + subranch.numberPolicy}}</label>
                <br>
                <ng-container *ngFor="let category of subranch.policyCategoriesList; let cat = index">
                  <input type="checkbox" [(ngModel)]="category.selected" [ngModelOptions]="{standalone: true}"/>
                  <label
                    style="font-size: xx-small; vertical-align: middle; color: #1BA8B1"> {{category.category}}</label>
                  <br>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Fecha de baja</label>
          <div class="input-group">
            <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
            <input type="text"
                   placeholder="00-00-0000"
                   class="form-control"
                   bsDatepicker
                   [bsConfig]="bsLeavingDate"
                   [(ngModel)]="dataToSend.leavingDate"
                   name="leavingDate">
          </div>
        </div>
      </div>
      <!--+---------------------------------------------------------------------------------------------------+
                |----------------------------------------|  G E N E R A L  |----------------------------------------|
                +---------------------------------------------------------------------------------------------------+-->
      <div *ngSwitchCase="2">
        <div class="form-group has-feedback">
          <label class="control-label">Número de serie</label>
          <span class="glyphicon glyphicon-search form-control-feedback" aria-hidden="true"></span>
          <input [(ngModel)]="searchItemSelected"
                 [typeaheadAsync]="true"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 typeaheadWaitMs="1000"
                 typeaheadOptionField="serialNumber"
                 placeholder="Buscar…"
                 class="form-control"
                 name="serial"
                 autocomplete="off">
          <div *ngIf="typeaheadLoading">Loading</div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Auto</label>
          <div class="input-group">
            {{lclSerialSelected.description || '-------------------'}}
          </div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Asunto</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.subject" name="subject" required>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Descripción</label>
          <textarea rows="3" class="form-control" [(ngModel)]="dataToSend.description" name="description" required></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="text-center" [hidden]="!displayForm">
    <div class="btn-group btn-group-justified" role="group" aria-label="...">
      <div class="btn-group" role="group"
           *ngIf="(lclRequestType == 0 && dataToSend.upType == 0 && attachAdaptatio) || (lclRequestType == 0 && dataToSend.upType == 1)">
        <button type="button" class="btn btn-sm btn-custom" (click)="addNewAdaptation()">Nueva adaptación</button>
      </div>
      <div class="btn-group" role="group">
        <button type="submit" class="btn btn-sm btn-custom" form="requestForm">Reportar</button>
      </div>
    </div>
  </div>
</div>

<ng-template #templateAdaptationInfo>
  <div class="modal-header">
    ADAPTACIONES
  </div>
  <div class="modal-body">
    <div class="insured-tab-body">
      <table class="table table-condensed table-striped">
        <thead>
        <th>Adaptación</th>
        <th>Valor</th>
        <th>Nombre factura</th>
        <th>Opciones</th>
        </thead>
        <tbody>
        <tr *ngFor="let i of invoiceAdaptationList; index as in" style="cursor: pointer">
          <td>{{i.name}}</td>
          <td>{{i.value}}</td>
          <td>{{i.nameFileInvoice}}</td>
          <td>
            <button type="button" class="btn btn-sm btn-custom" (click)="deleteAdaptation(in)">Eliminar</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

