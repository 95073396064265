
import { RealTimeAPI } from 'rocket.chat.realtime.api.rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

import * as crypto from 'crypto-js';
import {SharedService} from './shared-service.service';
import {ApiService} from './api.service';

const API_ROCKET = environment.api_rocket;

@Injectable()
export class WebsocketService {
  surexsChatAgents: any = [];
  private realTimeAPI;

  constructor(private shared: SharedService,
              private apiRest: ApiService) { }

  /**
   * Método para abrir la conezxión con el servidor rocket.chat
   */
  connectToHost() {
    this.realTimeAPI = new RealTimeAPI(API_ROCKET);
    this.realTimeAPI.connectToServer().subscribe(
      (data) => {
        if (data.hasOwnProperty('msg') === true) {
          if (data.msg === 'connected') {
            const token = window.atob(localStorage.getItem('token'));
            const credetials = token.split(':');
            this.signIn(credetials[0], credetials[1]);
          }
        }
      }
    );
    this.realTimeAPI.keepAlive();
  }

  disconnectToHost() {
    this.realTimeAPI.disconnect();
  }

  /**
   * Método para iniciar sesión en rocket.chat
   *
   * @param username Nombre de usuario/email
   * @param password Contraseña de usuario
   */
  signIn(username: string, password: string) {
    const params = [
      {
        user: {email: username},
        password: {
          digest: localStorage.getItem('rpass'),
          algorithm: 'sha-256'
        }
      }
    ];
    return this.realTimeAPI.callMethod('login', ...params)
      .subscribe(
        (response) => {
          if (response.result) {
            localStorage.setItem('ruid', response.result.id);
            const groupName = username.split('@')[0];
            this.listChannels(groupName);
          }
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Método para listar los grupos correspondientes al usuario y verificar sí ya tiene creado el grupo indicado en el parametro
   * @param groupName Nombre del grupo
   */
  listChannels(groupName) {
    return this.realTimeAPI.callMethod('rooms/get')
      .subscribe((data) => {
        if (data.result) {
          let groupExist: any = {};
          data.result.forEach((item, index) => {
            if (item.name === groupName) {
              groupExist = item;
            }

            if (index === (data.result.length - 1) && groupExist._id) {
              localStorage.setItem('rid', groupExist._id);
              this.loadHistory().subscribe( (data) => {
                this.shared.gMsgList = (data.result.messages.reverse());
                this.shared.fStreamRoomMessages.next(true);
              });
            } else if (index === (data.result.length - 1) && groupExist._id === undefined) {
              this.invokeServiceForGetSurexsChatAgent(groupName);
            }
          });
        } else {
          this.invokeServiceForGetSurexsChatAgent(groupName);
        }
      });
  }
  sendChatMessage(pMsg) {
    const params = [{
      rid: localStorage.getItem('rid'),
      msg: pMsg
    }
    ];
    return this.realTimeAPI.callMethod('sendMessage', ...params);
  }
  streamRoomMessages() {
    const roomId: string = localStorage.getItem('rid');
    return this.realTimeAPI.getSubscription('stream-room-messages', roomId, false);
  }

  streamnotifyUser() {

    const params = [ 'AkLFGJqHwZiptxN8u', false];
    return this.realTimeAPI.getSubscription('stream-notify-user', 'AkLFGJqHwZiptxN8u/notification', false);
  }

  invokeServiceForGetSurexsChatAgent(groupName) {
    this.apiRest.getAgentsChatSurexs()
      .subscribe(
        (response: any) => {
          response.forEach((item, index) => {
            this.surexsChatAgents.push(item.username);

            if (index === response.length - 1) {
              this.createChannel(groupName);
            }
          });
        }, error => {
          console.error(error);
        }
      );
  }
  /**
   * Método para crear un grupo y agregar los Agentes de Surexs
   * @param groupName Nombre del Grupo
   */
  createChannel(groupName) {
    const params = [groupName, this.surexsChatAgents];
    return this.realTimeAPI.callMethod('createPrivateGroup', ...params)
      .subscribe(
        (response) => {
          if (response.result) {
            localStorage.setItem('rid', response.result.rid);
            this.loadHistory().subscribe( (data) => {
              this.shared.gMsgList = (data.result.messages.reverse());
              this.shared.fStreamRoomMessages.next(true);
            });
           // this.shared.fStreamRoomMessages.next(true);
          }
        }, error => {
          console.info(error);
        }
      );
  }
  /**
   * Método para recuperar el historial del usuario
   */
  loadHistory() {
    const params = [ localStorage.getItem('rid'), null, 50, null ];
    return this.realTimeAPI.callMethod('loadHistory', ...params);
  }
}

