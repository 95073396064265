import {Component, OnInit, TemplateRef} from '@angular/core';
import {SharedService} from '../shared-service.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {Observable} from 'rxjs';
import {Documents} from '../upload-certificate/documents';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {mergeMap} from 'rxjs/operators';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {InvoiceType} from './InvoiceType';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-new-request-car',
  templateUrl: './new-request-car.component.html',
  styleUrls: ['./new-request-car.component.css']
})
export class NewRequestCarComponent implements OnInit {
  displayForm = true;
  attachAdaptatio = false;
  // Variables para el multiselect de Pólizas ->
  dropdownSettingsPolicies = {};
  policiesSelectedItems: any = [];

  // Variables para el multiselect de Dependientes ->
  dropdownSettingsDependents = {};
  dependentsSelectedItems: any = [];

  // Objetos para los DatePickers ->
  bsBirthdate: Partial<BsDatepickerConfig>;
  bsEntryDate: Partial<BsDatepickerConfig>;
  bsLeavingDate: Partial<BsDatepickerConfig>;

  policiesList: any = []; // Almacena la lista de pólizas retornada por el servicio
  // Almacena la lista de dependientes retornada por el servicio || Almacena la lista de dependientes a dar de alta(alta de Dependiente)
  invoiceAdaptationList: any = [];
  relationshipList: any = []; // Almaccena la lista de parentescos retornada por el servicio
  dataToSend: any = {}; // Almacena los datos de envío
  selected: boolean;
  adaptation: any = {}; // Almacena los datos del dependiente dentro del apartado de Asegurados

  lclRequestType: number; // Almacena el tipo de Solicitud que se enviará
  disabledSalary: boolean; // Bandera para Habilitar/Inhabilitar el input para Salario

  // Variables para typeahead (búsqueda de asegurados) ->
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  lclCarSelected: any = {};
  monthActual: number;
  yearActual: number;
  gendersCatalog: any = [];
  currentRoleId: number;
  statsend: boolean;

  addTitular: boolean;
  dataSourceUse: any = [];

  // Variables para Solicitudes
  uploadedFilesInvoice: Array<Documents> = []; // Documentos del cliente
  uploadedFilesInvoiceAdaptation: Array<Documents> = []; // Documentos del cliente
  public fileUpload: File;
  public fileUploadAdaptation: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];

  /* NUEVOS */
  dataSourceMarca: any;
  dataSourceModelo: any;
  dataSourceVersion: any;
  searchMarcaSelected: any = {};
  searchVehicleTypeSelected: string;
  searchModeloSelected: any = {};
  facturevalue: any;
  modalAdaptationtRef: BsModalRef;

  constructor(private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              private modalService: BsModalService) {

    this.invokeServiceGetUseVehicleList();
    this.addTitular = false;
    this.yearActual = new Date().getFullYear();
    this.monthActual = new Date().getMonth();

    this.currentRoleId = Number(localStorage.getItem('roleId'));
    /**
     * Observable para monitorear los cambios en typeahead de Autos
     */
    this.dataSource = new Observable((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getCarAsObservable(token))
    );
  }

  ngOnInit() {
    /*NUEVOS*/
    this.invokeMakesCatalogList();
    this.invokeModelYearCatalogList();


    // Inicializamos configuración para dropdown de Pólizas ->
    this.dropdownSettingsPolicies = {
      singleSelection: false,
      idField: 'categoryId',
      textField: 'number',
      selectAllText: 'Todas',
      unSelectAllText: 'Ninguna',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No se encontraron pólizas',
      searchPlaceholderText: 'Buscar'
    };
    // Inicializamos configuración para dropdown de Dependientes ->
    this.dropdownSettingsDependents = {
      singleSelection: false,
      idField: 'dependentId',
      textField: 'name',
      selectAllText: 'Todos',
      unSelectAllText: 'Ninguno',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No se encontraron dependientes',
      searchPlaceholderText: 'Buscar'
    };
    // Iniciamos configuración para DatePicker de Fecha de Nacimiento ->
    this.bsBirthdate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      maxDate: new Date()
    });
    // Iniciamos configuración para DatePicker de Fecha de Alta ->
    const currentDateMinus30Days = new Date();
    currentDateMinus30Days.setDate(currentDateMinus30Days.getDate() - 30);
    this.bsEntryDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: currentDateMinus30Days
    });
    // Iniciamos configuración para DatePicker de Fecha de Baja ->
    this.bsLeavingDate = Object.assign({}, {
      adaptivePosition: true,
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: currentDateMinus30Days,
    });

    this.disabledSalary = true; // Inhabilitamos el input para Salario

    // Inicializamos el objeto de datos de envio
    this.dataToSend = {
      upType: 0, // Tipo de alta Asegurado
    };

    this.selectRequestType(0); // Por default mostramos el registro de solitud de alta de autos
    this.statsend = false;
  }

  private invokeServiceForGetGendersCatalog() {
    this.api.getGendersCatalog()
      .subscribe(
        response => {
          this.gendersCatalog = response;
          this.dataToSend.gender = this.gendersCatalog[0].genderId;
        }, error => {
          console.error(error);
        }
      );
  }
  /**
   * Método para cambiar variables o invocar servicios según el tipo de registro que se realizará
   *
   * @param item [number] Identificador el tipo de Solicitud
   * 0 = Alta
   * 1 = Baja
   * 2 = General
   */
  public selectRequestType(item) {
    this.lclRequestType = item;
    this.dataToSend = {};
    this.policiesSelectedItems = [];
    this.policiesList = [];
    this.searchItemSelected = undefined;
    this.lclCarSelected = {};
    switch (this.lclRequestType) {
      case 0:
        this.dataToSend.upType = 0;
        this.invokeServiceForGetPolicies();
        this.invokeServiceForGetGendersCatalog();
        break;
      case 1:
        this.dataToSend.upType = 0;
        break;
      case 2:
        this.dataToSend.upType = 0;
        this.invokeServiceForGetPolicies();
        this.getUserProfile();
        break;
    }
  }

  /**
   * Método para cambiar variables o invocar servicios según el tipo de Solicitud y tipo de persona
   *
   * valores para variable :lclRequestType
   * 0 = Alta
   * 1 = Baja
   * 2 = General
   *
   * @param item [number] Identificador para el tipo de persona
   * 0 = Asegurado
   * 1 = Dependiente
   *
   */
  public changeUpType(item) {
    this.dataToSend = {};
    this.dataToSend.upType = item;
    this.policiesSelectedItems = [];
    this.dependentsSelectedItems = [];
    this.policiesList = [];
    this.searchItemSelected = undefined;
    this.lclCarSelected = {};
    switch (this.lclRequestType) {
      case 0:
        setTimeout(() => {
          switch (this.dataToSend.upType) {
            case 0:
              this.invokeServiceForGetPolicies();
              break;
            case 1:
              break;
          }
        }, 500);
        break;
      case 1:
        break;
    }
  }

  public showAdaptations(template: TemplateRef<any>) {
    this.modalAdaptationtRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }

  private getUserProfile() {
    this.api.getUserProfile()
      .then(
        (data: any) => {
          this.dataToSend.name = data.name;
          this.dataToSend.pLastname = data.primaryLastName;
          this.dataToSend.sLastname = data.secondaryLastName;
        }, error => {
          console.log(error);
        }
      );
  }

  /**
   * Método para obtener la lista de pólizas
   * 1.- Sí el tipo de Solicitud es 'Alta' indicado por la variable :lclRequestType = 0
   *    1.1.- Sí el tipo de 'Alta' es 'Asegurado' indicado por la propiedad :dataToSend.upType = 0
   *        la lista de pólizas se obtiene a través del servicio REST @getPoliciesMin
   *        se almacena la respuesta en la variable de tipo array :policiesList
   *    1.2.- Sí el tipo de 'Alta' es 'Dependiente' indicado por la propiedad :dataToSend.upType = 1
   *        la lista de pólizas se obtiene a través del servicio REST @getPoliciesMinByInsuredId
   *        se almacena la respuesta en la variable de tipo array :policiesList
   *
   * 2.- Sí el tipo de Solicitud es 'Baja' indicado por la variable :lclRequestType = 1
   *    la lista de pólizas se obtiene a través del servicio REST @getPoliciesMinByInsuredId
   *    se almacena la respuesta en la variable de tipo array :policiesList
   *
   * @param insuredId [number] Id del Asegurado para filtrar las pólizas
   */
  private invokeServiceForGetPolicies(carId?) {
    switch (this.lclRequestType) {
      case 0:
          this.api.getPoliciesClientCarMin()
            .subscribe(
              (data) => {
                this.policiesList = data;
              }, error => {
                console.error(error.status);
                console.error(error.statusText);
                console.error(error.message);
              }
            );
          break;
      case 1:
        this.policiesList = [];
        this.api.getPoliciesClientCarId(carId)
          .subscribe(
            response => {
              this.policiesList = response;
            }, error => {
              console.error(error);
            }
          );
        break;
    }
  }


  /**
   * Método para habilitar/inhabilitar input para 'Salario'
   * para habilitar el input de 'Salario' debe de haber por lo menos una póliza de tipo 'Vida' (typePolicy = 10) seleccionada
   *
   * 1.- Inhabilitamos el input 'Salario'
   * 2.- Iteramos el arreglo :policiesSelectedItems que contiene las pólizas seleccionadas por el usuario (iteracion A)
   * 3-. Buscamos la iteración A en el arreglo que contiene todas las pólizas retornadas por el servicio REST
   *        almacenadas en la variable de tipo array :policiesList y retornamos la coincidencia en la constante :policy
   * 4.- Verificamos sí la constante :policy es una póliza de tipo 'Vida' :typePolicy = 10
   * 5.- Sí es de tipo 'Vida' habilitamos el input 'Salario'
   *
   * El arreglo :policiesSelectedItems no contiene la informacion completa de las pólizas por eso buscamos sus elementos
   * en el arreglo :policiesList que contiene la información completa de las pólizas
   */
  public changeStatusOnSalaryField() {
    this.disabledSalary = true;
    setTimeout(() => {
      try {
        this.policiesSelectedItems.forEach(item => {
          const policy = this.policiesList.filter(x => x.policyId === item.policyId);
          if (policy[0].typePolicy === 10) {
            this.disabledSalary = false;
            throw new Error('break');
          } else {
            this.disabledSalary = true;
          }
        });
      } catch (e) {
      }
    }, 500);
  }


  validateDuplicatePolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let foundedPolicyRepetead = false;
    for (const policySubranchCategory of policySubranchCategories) {
      let policy;
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          if (policy === policySubranchCategory.idPolicy) {
            this.toastr.warning('Solo puede asignarse una categoría por póliza, favor de modificar las pólizas seleccionadas');
            foundedPolicyRepetead = true;
            return;
          } else {
            policy = policySubranchCategory.idPolicy;
          }

          policy = policySubranchCategory.idPolicy;
        }
      }
    }
    return foundedPolicyRepetead;
  }

  validateSelectedPolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let isSelected = false;
    for (const policySubranchCategory of policySubranchCategories) {
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          isSelected = true;
        }
      }
    }
    return isSelected;
  }

  /**
   * Cambia el elemento typeahead para búsqueda de 'Asegurados' a modo 'Loading'
   * @param e
   */
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  /**
   * Método para efectuar acciones cuando se ha seleccionado un elemento del typeahead de 'Autos'
   *
   * 1.- Almacenamos el elemento selecccionado en la variable :lclInsuredSelected
   * 2.- Sí el tipo de Solicitud es 'Alta' indicado por la variable :lclRequestType = 0
   *     invocamos la funcion @invokeServiceForGetPolicies() y le pasamos como parametro el Id del Asegurado
   * 3.- Sí el tipo de Solicitud es 'Baja' indicado por la variable :lclRequestType = 1
   *    3.1.- Sí el tipo de 'Baja' es 'Asegurado' indicado por la propiedad :dataToSend.upType = 0
   *          invocamos la funcion @invokeServiceForGetPolicies() y le pasamos como parametro el Id del Asegurado
   *    3.2.- Sí el tipo de 'Baja' es 'Dependiente' indicado por la propiedad :dataToSend.upType = 1
   *          invocamos la funcion @invokeServiceForGetDependentsByInsuredId() y le pasamos como parametro el Id del Asegurado
   *
   * @param e Elemento seleccionado
   */
  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.lclCarSelected = e.item;
    switch (this.lclRequestType) {
      case 0:
        this.invokeServiceForGetPolicies(this.lclCarSelected.carId);
        break;
      case 1:
        this.invokeServiceForGetPolicies(this.lclCarSelected.carId);
        break;
      case 2:
        this.invokeServiceForGetPolicies(this.lclCarSelected.carId);
        break;
    }
  }

  /**
   * Función para recuperar las coincidencias de la búsqueda en el typeahead de 'Autos'
   *
   * 1.- Invocamos el servicio REST @getFinderCar y retornamos la respuesta
   * @param token [string] Filtro para realizar la búsqueda
   */
  getCarAsObservable(token: string): Observable<any> {
    return this.api.getFinderCar(token).pipe();
  }

  /**
   * Método par agregar un nuevo dependiente a la lista
   *
   * 1.- Recuperamos los datos del dependiente y los agregamos a lista
   * 2.- Limpiamos el formulario
   */
  public addNewAdaptation() {
    let objToSend01 = {};
    console.log(this.dataToSend.upType);
    switch (this.dataToSend.upType) { // this.lclRequestType
      case 0:
        console.log(this.adaptation);
        if (this.adaptation.name === undefined
          || this.adaptation.value === undefined
          || this.uploadedFilesInvoiceAdaptation.length === 0) {
          this.toastr.warning('Por favor llene todos los campos requeridos');
          return;
        }


        setTimeout(() => {
          objToSend01 = {
            name: this.adaptation.name,
            value: this.adaptation.value,
            nameFileInvoice: this.uploadedFilesInvoiceAdaptation[0].name,
            baseInvoice: this.uploadedFilesInvoiceAdaptation[0].base64,
            invoiceType: InvoiceType.ADAPTATION_INVOICE
          };

          this.invoiceAdaptationList.push(objToSend01);

          this.adaptation.name = undefined;
          this.adaptation.value = undefined;
          this.uploadedFilesInvoiceAdaptation = [];
          this.fileUploadAdaptation = null;
        }, 10);
        break;
      case 1:
        setTimeout(() => {
          objToSend01 = {
            name: this.adaptation.name,
            value: this.adaptation.value,
            nameFileInvoice: this.uploadedFilesInvoiceAdaptation[0].name,
            baseInvoice: this.uploadedFilesInvoiceAdaptation[0].base64,
            invoiceType: InvoiceType.ADAPTATION_INVOICE
          };

          this.invoiceAdaptationList.push(objToSend01);

          this.adaptation.name = undefined;
          this.adaptation.value = undefined;
          this.uploadedFilesInvoiceAdaptation = [];
        }, 10);
        break;
    }
  }

  /**
   * Método para realizar el envío del regsitrso de 'Solicitud'
   *
   * 1.- Sí el tipo de Solicitud es 'Alta' indicado por la variable :lclRequestType = 0
   *    1.1.- Sí el tipo de 'Alta' es 'Asegurado' indicado por la propiedad :dataToSend.upType = 0
   *          construimos el objeto con las propiedades especificas para este tipo de Solicitud
   *          enviamos el objeto al servicio REST @postRequestInsuredAdd
   * 2.- Sí el tipo de Solicitud es 'Baja' indicado por la variable :lclRequestType = 1
   *    2.1.- Sí el tipo de 'Baja' es 'Asegurado' indicado por la propiedad :dataToSend.upType = 0
   *          construimos el objeto con las propiedades especificas para este tipo de Solicitud
   *          enviamos el objeto al servicio REST @postRequestInsuredRemove
   *    2.2.- Sí el tipo de 'Baja' es 'Dependiente' indicado por la propiedad :dataToSend.upType = 1
   *          construimos el objeto con las propiedades especificas para este tipo de Solicitud
   *          enviamos el objeto al servicio REST @postRequestDependentRemove
   *
   * 3.- Sí el tipo de Solicitud es 'General' indicado por la variable :lclRequestType = 2
   *     construimos el objeto con las propiedades especificas para este tipo de Solicitud
   *     enviamos el objeto al servicio REST @postRequestData
   */
  sendRequestData() {
    if (this.statsend) {
      return;
    }
    this.statsend = true;
    switch (this.lclRequestType) {
      case 0:
        if (this.validateDuplicatePolice(this.policiesList)) {
          this.statsend = false;
          return;
        }
        if (!this.validateSelectedPolice(this.policiesList)) {
          this.toastr.warning('Es necesario seleccionar al menos una categoría');
          this.statsend = false;
          return;
        }

        if (this.adaptation.name !== undefined
          && this.adaptation.value !== undefined
          && this.uploadedFilesInvoiceAdaptation.length !== 0) {
          const lastAdptation = {
            name: this.adaptation.name,
            value: this.adaptation.value,
            nameFileInvoice: this.uploadedFilesInvoiceAdaptation[0].name,
            baseInvoice: this.uploadedFilesInvoiceAdaptation[0].base64,
            invoiceType: InvoiceType.ADAPTATION_INVOICE
          };

          this.invoiceAdaptationList.push(lastAdptation);
          this.adaptation = {};
          this.uploadedFilesInvoiceAdaptation = [];

        }

        const objToSend00 = {
          mark: this.dataToSend.mark,
          model: this.dataToSend.model,
          version: this.dataToSend.version,
          plate: this.dataToSend.plate,
          serialNumber: this.dataToSend.serialNumber,
          engineNumber: this.dataToSend.engineNumber,
          valueInvoice: this.dataToSend.valueInvoice,
          baseFileInvoice: this.uploadedFilesInvoice.length === 0 ? '' : this.uploadedFilesInvoice[0].base64,
          nameFileInvoice: this.uploadedFilesInvoice.length === 0 ? '' : this.uploadedFilesInvoice[0].name,
          startDate: this.datePipe.transform(this.dataToSend.startDate, 'yyyy-MM-dd'),
          policySubranchCategories: this.policiesList,
          fileInvoiceList: this.invoiceAdaptationList,
          name: this.dataToSend.name,
          firstName: this.dataToSend.firstName,
          lastName: this.dataToSend.lastName,
          email: this.dataToSend.email,
          use: this.dataToSend.use
        };

        this.api.postRequestCarAdd(objToSend00)
          .subscribe(
            response => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.shared.fUpdateRequests.next(true);
              this.shared.fCloseSidebar();
            }, error => {
              if (error.status === 500) {
                if (error.error.message !== undefined) {
                  this.toastr.warning(error.error.message, 'Notificación');
                  this.invoiceAdaptationList = [];
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
              this.statsend = false;
            }
          );
        break;
      case 1:
        case 0:
          if (!this.validateSelectedPolice(this.policiesList)) {
            this.toastr.warning('Es necesario seleccionar al menos una categoría');
            this.statsend = false;
            return;
          }
          const objToSend10 = {
            carId: this.lclCarSelected.carId,
            startDate: this.datePipe.transform(this.dataToSend.leavingDate, 'yyyy-MM-dd'),
            policySubranchCategories: this.policiesList,
          };

          this.api.postCancellationVehicleRequestData(objToSend10)
            .subscribe(response => {
                this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
                this.shared.fUpdateRequests.next(true);
                this.shared.fCloseSidebar();
              }, error => {
                console.error(error);
                if (error.status === 500) {
                  if (error.error.message !== undefined) {
                    this.toastr.warning(error.error.message, 'Notificación');
                  } else {
                    this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                  }
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
                this.statsend = false;
              }
            );
          break;
      case 2:
        const policiesListGeneral = this.policiesSelectedItems.map(x => ({policyId: x.policyId}));
        const objToSend2 = {
          policySubranchCategories: this.policiesList,
          subject: this.dataToSend.subject,
          description: this.dataToSend.description,
          isincumbent: this.dataToSend.incumbent,
          employedNum: this.dataToSend.employeeNumber
        };
        this.api.postRequestData(objToSend2)
          .then(
            (response) => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.shared.fUpdateRequests.next(true);
              this.shared.fCloseSidebar();
            }, error => {
              console.error(error.status);
              console.error(error.statusText);
              console.error(error.message);
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.statsend = false;
            }
          );
        break;
    }
  }

  onCalculateAge(birthdate: any): boolean {
    let timeDiff;

    timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());

    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

    if (Number(age) > 18) {
      return true;
    } else {
      return false;
    }
    console.log(age);
  }

  onCalculateAgeDependent(birthdate: any): boolean {
    let timeDiff;
    timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());

    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    let isAgeAllowed = true;

    this.policiesSelectedItems.map(x => {
      this.policiesList.map(policies => {
        if (policies.categoryId === x.categoryId) {
          const maxAgeLimit = policies.ageMax;
          if (maxAgeLimit !== 0 && Number(age) > maxAgeLimit) {
            this.toastr.warning('La póliza: ' + policies.number
              + ' no permite dependientes con edad mayor a: ' + maxAgeLimit);
            isAgeAllowed = false;
          }
        }
      });
    });
    return isAgeAllowed;
  }

  onLoadDocuments(event) {
    this.uploadedFilesInvoice = event;
  }

  // Convertimos la imágen o PDF a base64
  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    const documents = new Documents();
    this.uploadedFilesInvoice = [];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    } else {
      this.getBase64(this.fileUpload).then(
        res => {
          documents.base64 = res.split(',')[1];
          documents.name = this.fileUpload.name;
          this.uploadedFilesInvoice.push(documents);
        }
      );
    }
  }

  onChangeInputFileAdaptation(e) {
    this.error = {active: false, msg: ''};
    this.fileUploadAdaptation = e.target.files[0];
    const documents = new Documents();
    this.uploadedFilesInvoiceAdaptation = [];
    if (0 > this.fileTypes.indexOf(this.fileUploadAdaptation.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    } else {
      this.getBase64(this.fileUploadAdaptation).then(
        res => {
          documents.base64 = res.split(',')[1];
          documents.name = this.fileUploadAdaptation.name;
          this.uploadedFilesInvoiceAdaptation.push(documents);
        }
      );
    }
  }

  invokeMakesCatalogList() {
    const data = {
      isMotorbike: false
    };
    this.api.getMakeCatalogist(data).then(
      (response: any) => {
        this.dataSourceMarca = response;

      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  invokeModelYearCatalogList() {
    this.api.getModelYearCatalogist().then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourceModelo = response;
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  invokeCarVersion(dataSearch) {
    this.api.getCarVersionCatalogist(dataSearch).then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourceVersion = response;
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  typeaheadOnSelectMarca(marca): void {
    this.searchMarcaSelected = marca;
    if (this.dataToSend.mark !== undefined && this.dataToSend.model !== undefined) {
      const searchVersion = {
        shortMake: this.searchMarcaSelected.id,
        longMake: this.searchMarcaSelected.name,
        modelYear: this.searchModeloSelected.id
      };

      this.invokeCarVersion(searchVersion);
    }
  }


  typeaheadOnSelectModelo(modelo): void {
    this.searchModeloSelected = modelo;
    if (this.dataToSend.mark !== undefined && this.dataToSend.model !== undefined) {
      const searchVersion = {
        shortMake: this.searchMarcaSelected.id,
        longMake: this.searchMarcaSelected.name,
        modelYear: this.searchModeloSelected.id
      };

      this.invokeCarVersion(searchVersion);
    }
  }

  public changeValorFactura(item) {
    this.facturevalue = item;
  }

  public deleteAdaptation(index) {
    this.invoiceAdaptationList.splice(index, 1);
  }

  public activateAdaptationRegistrationInCar() {
    this.attachAdaptatio = true;
  }

  onChangeAddTitular(event) {
    this.addTitular = event;
  }

  invokeServiceGetUseVehicleList() {
    this.api.getUseCatalog().then((data: any) => {
      if (data != null) {
        this.dataSourceUse = data;
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
}
