<div class="" xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <div class="receipt-card">
      <tabset #tabBranch [justified]="true">
        <ng-container  *ngFor="let branch of branches; i as index">
          <tab class="receipt-tab" [active] = isActive(branch.idBranch) heading="{{branch.nameBranch}}"
               id="{{branch.idBranch}}" (selectTab)="changeBranch($event)">
          </tab>
        </ng-container>

          <div class="row">
            <div class="col-lg-9 col-md-8 col-sm-7 col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <p class="title-section pull-left">Estadísticos</p>
                  <div class="btn-group pull-right" dropdown>
                    <button id="button-chart-types" dropdownToggle type="button"
                            class="btn btn-link dropdown-toggle subtitle-section-menu"
                            aria-controls="dropdown-chart-types">
                      {{lclStatisticsFilterSelected.description}} <span class="sx-iconsys-icon">1</span>
                    </button>
                    <ul id="dropdown-chart-types" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                        aria-labelledby="button-chart-types">
                      <li role="menuitem" *ngFor="let item of statisticsFilters">
                        <a class="dropdown-item" href="javascript:;"
                           (click)="changeStatisticsFilter(item)">{{item.description}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ng-container>
              <div class="row">

                <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 0">
                  <div class="title-section pull-right">
                    <div class="btn-group pull-right" dropdown>
                      <button id="button-data-types" dropdownToggle type="button"
                              class="btn btn-link dropdown-toggle subtitle-section-menu">
                        {{dataTypeFilterSelected.name}}
                        <span class="sx-iconsys-icon">1</span>
                      </button>
                      <ul id="dropdown-data-type" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                          aria-labelledby="button-data-types">
                        <li role="menuitem" *ngFor="let item of dataTypeFilters" (click)="changeDataTypeFilter(item)">
                          <a class="dropdown-item" href="javascript:;">{{item.name}}</a>
                        </li>
                      </ul>
                    </div>

                    <div class="btn-group pull-right" dropdown>
                      <button id="button-report-types_1" dropdownToggle type="button"
                              class="btn btn-link dropdown-toggle subtitle-section-menu">
                        {{staticsIncidentPolicyTypeSelected.description}}
                        <span class="sx-iconsys-icon">1</span>
                      </button>
                      <ul id="dropdown-report-type_1" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                          role="menu" aria-labelledby="button-report-types">
                        <li role="menuitem" *ngFor="let policyType of policyTypeList"
                            (click)="changePolicyTypeStaticsIncident(policyType)">
                          <a class="dropdown-item" href="javascript:;">{{policyType.description}}</a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 2">
                  <div class="title-section pull-right">
                    <div class="btn-group pull-right" dropdown>
                      <button id="button-report-types" dropdownToggle type="button"
                              class="btn btn-link dropdown-toggle subtitle-section-menu">
                        {{staticsAccidentsPolicyTypeSelected.description}}
                        <span class="sx-iconsys-icon">1</span>
                      </button>
                      <ul id="dropdown-report-type" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                          aria-labelledby="button-report-types">
                          <li role="menuitem" *ngFor="let policyType of policyTypeList"
                              (click)="changePolicyTypeStaticsAccidents(policyType)">
                            <a class="dropdown-item" href="javascript:;">{{policyType.description}}</a>
                          </li>

                        <ng-template #nobranch>
                          <li role="menuitem">
                            <a class="dropdown-item" href="javascript:;">Sin tipo de póliza</a>
                          </li>
                        </ng-template>
                      </ul>
                    </div>
                  </div>
                </div>


                <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 3">
                  <div class="title-section pull-right">
                    <div class="btn-group pull-right" dropdown>
                      <button id="button-policy-use-types" dropdownToggle type="button"
                              class="btn btn-link dropdown-toggle subtitle-section-menu"
                              aria-controls="dropdown-policy-use-type">
                        {{insuranceSelected.name}}
                        <span class="sx-iconsys-icon">1</span>
                      </button>
                      <ul id="dropdown-policy-use-type" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                          role="menu" aria-labelledby="button-policy-use-types">
                        <li role="menuitem" *ngFor="let item of staticsInsuranceList" (click)="selectInsurance(item)">
                          <a class="dropdown-item" href="javascript:;">{{item.name}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 5">
                  <div class="title-section pull-right">
                    <div class="btn-group pull-right" dropdown>
                      <button id="button-types-companies" dropdownToggle type="button"
                              class="btn btn-link dropdown-toggle subtitle-section-menu">
                        {{staticsCompanyTypeSelected}}
                        <span class="sx-iconsys-icon">1</span>
                      </button>
                      <ul id="dropdown-type-company" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                          aria-labelledby="button-report-types">
                        <li role="menuitem" *ngFor="let company of dataTypeFiltersDebtor"
                            (click)="changeCompanyTypeStaticsDebtorLife(company)">
                          <a class="dropdown-item" href="javascript:;">{{company}}</a>
                        </li>

                      </ul>
                    </div>

                    <div class="btn-group pull-right" dropdown>
                      <button id="button-data-types2" dropdownToggle type="button"
                              class="btn btn-link dropdown-toggle subtitle-section-menu">
                        {{dataTypeFilterSelectedDebtorLife.name}}
                        <span class="sx-iconsys-icon">1</span>
                      </button>
                      <ul id="dropdown-data-type2" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                          aria-labelledby="button-data-types">
                        <li role="menuitem" *ngFor="let item of dataTypeDetorFilter"
                            (click)="changeDataTypeFilterDebtor(item)">
                          <a class="dropdown-item" href="javascript:;">{{item.name}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="lclStatisticsFilterSelected.id === 7">
                  <div class="title-section pull-right">
                    <div class="btn-group pull-right" dropdown>
                      <button id="button-report-type" dropdownToggle type="button"
                              class="btn btn-link dropdown-toggle subtitle-section-menu">
                        {{staticsCollectionPolicyTypeSelected.description}}
                        <span class="sx-iconsys-icon">1</span>
                      </button>
                      <ul id="dropdown-report-type2" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                          aria-labelledby="button-report-type">
                        <div *ngIf="policyTypeCollecionList !== null && policyTypeCollecionList.length > 0 else nobranch">
                          <li role="menuitem" *ngFor="let policyType of policyTypeList"
                              (click)="changePolicyTypeStaticsCollection(policyType)">
                            <a class="dropdown-item" href="javascript:;">{{policyType.description}}</a>
                          </li>
                        </div>

                        <ng-template #nobranch>
                          <li role="menuitem">
                            <a class="dropdown-item" href="javascript:;">Sin tipo de póliza</a>
                          </li>
                        </ng-template>
                      </ul>
                    </div>
                  </div>
                </div>


                <div class="col-xs-12 chart-container display-flex" *ngIf="showStatics">
                  <div class="col-md-12 text-center" style="font-size: 20px; font-weight: bold; margin: auto;">Sin datos
                    que mostrar
                  </div>
                </div>

                <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 0">
                  <div class="chart-container">
                    <canvas #lineChart id="lineChart">{{ chart }}</canvas>
                  </div>
                </div>
                <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 1" [hidden]="showStatics">
                  <div class="chart-container">
                    <canvas #pieChart id="pieChart">{{ pieChart }}</canvas>
                  </div>
                </div>

                <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 7" [hidden]="showStatics">
                  <div class="chart-container">
                    <canvas #pieChart1 id="pieChart1">{{ pieChart1 }}</canvas>
                  </div>
                </div>

                <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 2" [hidden]="showStatics">
                  <div class="chart-container">
                    <canvas #line1Chart id="line1Chart">{{ line1Chart }}</canvas>
                  </div>
                </div>
                <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 3" [hidden]="showStatics">
                  <div class="row">
                    <div class="col-xs-6">
                      <p class="title-section">Uso de póliza</p>
                    </div>
                  </div>
                  <div class="col-xs-12">
                    <div class="chart-container">
                      <canvas id="line2Chart" #line2Chart></canvas>
                    </div>

                  </div>
                </div>
                <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 5" [hidden]="showStatics">

                  <div class="chart-container">
                    <canvas #line3Chart id="line3Chart">{{ chart }}</canvas>
                  </div>
                </div>
                <div class="col-xs-12" *ngIf="lclStatisticsFilterSelected.id === 6" [hidden]="showStatics">
                  <br/>
                  <div class="chart-container">
                    <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let p of totalPopulations">
                      <div class="card card-stats">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12 col-md-12 receipt-detail">
                              <div class="head">
                                <br>
                                <h4>{{p.name}}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="branchIdSelected !== '3' || branchIdSelected !== 3" class="card-footer ">
                          <hr/>
                          <div class="stats">
                            <strong>Asegurados: {{p.insured}} </strong><br/>
                            <strong>Dependientes: {{p.kinship}}</strong>
                          </div>
                        </div>
                        <div *ngIf="branchIdSelected === '3' || branchIdSelected === 3" class="card-footer ">
                          <hr/>
                          <div class="stats">
                            <strong>Autos Asegurados: {{p.insured}} </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </ng-container>
            </div>
            <!--+---------------------------------------------------------------------------------------------------+
                |-----------------------------------------| E V E N T O S |-----------------------------------------|
                +---------------------------------------------------------------------------------------------------+-->
            <div class="col-lg-3 col-md-4 col-sm-5 col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <p class="title-section">Eventos</p>
                </div>
              </div>
              <div class="event-card" infinite-scroll
                   [infiniteScrollDistance]="2"
                   [infiniteScrollThrottle]="50"
                   [scrollWindow]="false"
                   (scrolled)="onScroll()">
                <div class="event-item" *ngFor="let item of eventsList">
                  <div class="head">
                    <div class="title">{{item.typeEvent}}</div>
                    <div class="date">{{item.created}}</div>
                  </div>
                  <div *ngIf="item.relationEventId === '3'" class="body" (click)="openModalRequestMov(templateRequestInfo, item.id, item.typeEventId)">
                    {{item.description}}
                  </div>
                  <div *ngIf="item.relationEventId === '2'" class="body" (click)="displayIncidentDetail(item.id)">
                    {{item.description}}
                  </div>
                  <div *ngIf="item.relationEventId === '6'" class="body" (click)="displayRequestDetail(item.id)">
                    {{item.description}}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </tabset>
    </div>
  </div>


  <!------------------------------------------------------------------------------------------------------------------->
  <div class="row">
    <!--+---------------------------------------------------------------------------------------------------+
        |-----------------------------------------| G E S T I O N |-----------------------------------------|
        +---------------------------------------------------------------------------------------------------+-->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20">
      <div class="row">
        <div class="col-xs-12">
          <p class="title-section">Gestión</p>
        </div>
      </div>
      <div class="management-card">
        <tabset #gestionTabs id="gestion" [justified]="true">
          <!--+---------------------------------------------------------------------------------+
              |-------------------------------| R E P O R T E S |-------------------------------|
              +---------------------------------------------------------------------------------+-->
          <tab  id="0" [active]="true" heading="Reportes" class="h-100" style="padding: 10px 10px !important;"
               (selectTab)="onManagementTabSelect($event)" *ngIf="shared.gCurrentPolicyBenefit > 0 || shared.gCurrentPolicyCar > 0">
            <div class="reports-tab-body">
              <div class="btn-group pull-left" dropdown [dropup]="true" style="position:absolute;">
                <button id="button-data-types-reports" dropdownToggle type="button"
                        class="btn btn-xs btn-link dropdown-toggle subtitle-section-menu"
                        aria-controls="dropdown-data-types-reports">
                  {{lclDataTypeForReportsSelected.description}} <span class="sx-iconsys-icon">1</span>
                </button>
                <ul id="dropdown-data-types-reports" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu"
                    aria-labelledby="button-data-types-reports">
                  <li role="menuitem" *ngFor="let item of dataTypesList">
                    <a class="dropdown-item" href="javascript:;" (click)="changeLclDataTypeReport(item)"
                       *ngIf="item.enabled">{{item.description}}</a>
                  </li>
                </ul>
              </div>
              <div id="rep-reports" class="rep-left">
                <div id="rep-body" class="rep-body" style=" overflow-x: hidden; overflow-y: scroll;">
                  <div class="m-auto" *ngFor="let item of reportsTypesList">
                    <div class="card" [ngClass]="lclReportSelected== item.reportId?'card-selected':''"
                         (click)="onReportItemSelect(item)">
                      <div class="card-head">
                        <i class="glyphicon glyphicon-file"
                           [ngStyle]="{'color':(item.typeReport==1?'#326173':item.typeReport==2?'#113859':item.typeReport == 3? '#448aa6':'gray-light')}"></i>
                        <p style="font-size: 10px;">{{item.typeReport == 1 && item.reportName.includes('SINIESTRALIDAD') ? 'Siniestralidad' : item.typeReport == 2 ? 'Provisiones' : item.typeReport == 3 ? item.reportName: ''}}</p>
                      </div>
                      <div class="card-footer">
                        <div class="name">
                          {{item.reportDate | date: 'MMM yyyy'}}
                        </div>
                        <div class="icon">
                          <i class="glyphicon glyphicon-download" title="Descargar reporte {{item.reportName}}"
                             (click)="invokeServiceForDownloadReportFile(item.reportId, item.reportName)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="rep-detail" class="rep-right">
                <table class="table table-striped table-condensed" *ngIf="type == 1 && typeReport==1">
                  <thead>
                  <th>Ramo</th>
                  <th>Póliza</th>
                  <th>No. Incidente</th>
                  <th>Fecha de pago</th>
                  <th>Importe reclamado</th>
                  <th>Deducible</th>
                  <th>Coaseguro</th>
                  <th>Importe pagado con IVA</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of reportDataList">
                    <td>{{item.industry}}</td>
                    <td>{{item.policy}}</td>
                    <td>{{item.incidentNumber}}</td>
                    <td>{{item.paymentDate}}</td>
                    <td>{{item.amountClaimed | CustomCurrency}}</td>
                    <td>{{item.deductible | CustomCurrency}}</td>
                    <td>{{item.coaseguro | CustomCurrency}}</td>
                    <td>{{item.amountPaidIva | CustomCurrency}}</td>
                  </tr>
                  </tbody>
                </table>
                <table class="table table-striped table-condensed" *ngIf="type == 1 && typeReport ==2">
                  <thead>
                  <th>Póliza</th>
                  <th>Nombre</th>
                  <th>Parentesco</th>
                  <th>Movimiento</th>
                  <th>Fecha de alta y/o baja</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of reportDataList">
                    <td>{{item.policy}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.kinship}}</td>
                    <td>{{item.movement}}</td>
                    <td>{{item.created}}</td>
                  </tr>
                  </tbody>
                </table>
                <table class="table table-striped table-condensed" *ngIf="type == 2">
                  <thead>
                  <th>PÓLIZA</th>
                  <th>NOMASEG</th>
                  <th>VEHÍCULO</th>
                  <th>FECOCU</th>
                  <th>SINTOTAL</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of reportDataList">
                    <td>{{item.policy}}</td>
                    <td>{{item.nomaSeg}}</td>
                    <td>{{item.car}}</td>
                    <td>{{item.fecocu}}</td>
                    <td>{{item.sinTotal | CustomCurrency}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </tab>

          <!--+---------------------------------------------------------------------------------+
              |----------------------------| S O L I C I T U D E S |----------------------------|
              +---------------------------------------------------------------------------------+-->
          <tab id="1" heading="Solicitudes" (selectTab)="onManagementTabSelect($event)"
               *ngIf="shared.gCurrentPolicyBenefit>0 || shared.gCurrentPolicyCar > 0">
            <div class="row">
              <div class="col-xs-12">
                <div class="btn-group pull-left" dropdown [dropup]="false">
                  <button id="button-data-types-requests" dropdownToggle type="button"
                          class="btn btn-xs btn-link dropdown-toggle subtitle-section-menu"
                          aria-controls="dropdown-data-types-requests">
                    {{lclDataTypeForRequestsSelected.description}} <span class="sx-iconsys-icon">1</span>
                  </button>
                  <ul id="dropdown-data-types-requests" *dropdownMenu class="dropdown-menu dropdown-menu-left"
                      role="menu" aria-labelledby="button-data-types-v">
                    <li role="menuitem" *ngFor="let item of dataTypesList">
                      <a class="dropdown-item" href="javascript:;" (click)="changeLclDataTypeRequest(item)"
                         *ngIf="item.enabled">{{item.description}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="request-tab-body" infinite-scroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 (scrolled)="onScrollRequet()">
              <div id="benefits" *ngIf="branchIdSelected === '1' || branchIdSelected === 1">
                <table class="table table-condensed table-striped">
                  <thead>
                  <th>Folio</th>
                  <th>Titular</th>
                  <th>Parentesco</th>
                  <th>Tipo</th>
                  <th>Edad</th>
                  <th>Estado</th>
                  <th>Fecha de Solicitud</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let rq of requestsList" style="cursor: pointer" (click)="openModalRequestMov(templateRequestInfo, rq.requestId)">
                      <!--(click)="displayRequestDetail(rq.requestDetailId)">-->
                    <td>{{rq.folio}}</td>
                    <td>{{rq.name}}</td>
                    <td>{{rq.kinship}}</td>
                    <td>{{rq.typeRequest}}</td>
                    <td>{{rq.age}}</td>
                    <td>{{rq.statusRequest}}</td>
                    <td>{{rq.created}}</td>
                  </tr>
                  </tbody>
                </table>
                <div class="request-tab-footer" style="padding: 10px;position: absolute;bottom: 0;right: 0;left: 0;">
                  <div class="btn-group" role="group" aria-label="...">
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-xs btn-custom" (click)="shared.fOpenSidebar(3)"
                              title="Nueva solicitud"><i class="fa fa-plus-circle fa-sm"></i>&nbsp;&nbsp;Nueva solicitud
                      </button>
                    </div>
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-xs btn-custom" (click)="shared.fOpenSidebar(7)"
                              title="Carga masiva"><i class="fa fa-upload fa-sm"></i>&nbsp;&nbsp;Carga masiva
                      </button>
                    </div>
                  </div>

                  <button class="btn btn-xs btn-custom pull-right" href="javascript:;"
                          (click)="invokeServiceForDownloadFileRequest()">
                    <i class="fa fa-download fa-sm"></i>&nbsp;&nbsp;Descargar Información
                  </button>
                </div>
              </div>
              <div id="vehicles" *ngIf="branchIdSelected === '3' || branchIdSelected === 3">
                <table class="table table-condensed table-striped">
                  <thead>
                  <th>Folio</th>
                  <!--<th>Póliza</th>-->
                  <th>Tipo</th>
                  <th>Marca</th>
                  <th>Modelo</th>
                  <th>Versión</th>
                  <th>No. Motor</th>
                  <th>No. Serie</th>
                  <th>Fecha</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let rq of requestsVehicleList">
                    <td>{{rq.folio}}</td>
                    <!--<td>{{rq.policy}}</td>-->
                    <td>{{rq.type}}</td>
                    <td>{{rq.mark}}</td>
                    <td>{{rq.model}}</td>
                    <td>{{rq.version}}</td>
                    <td>{{rq.engineNumber}}</td>
                    <td>{{rq.serialNumber}}</td>
                    <td>{{rq.created}}</td>
                  </tr>
                  </tbody>
                </table>
                <div  *ngIf="(branchIdSelected === 3 || branchIdSelected === '3')" class="request-tab-footer" style="padding: 10px;position: absolute;bottom: 0;right: 0;left: 0;">
                  <div class="btn-group" role="group" aria-label="...">
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-xs btn-custom" (click)="shared.fOpenSidebar(15)"
                              title="Nueva solicitud"><i class="fa fa-plus-circle fa-sm"></i>&nbsp;&nbsp;Nueva solicitud
                      </button>
                    </div>
                    <!--
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-xs btn-custom" (click)="shared.fOpenSidebar(7)"
                              title="Carga masiva"><i class="fa fa-upload fa-sm"></i>&nbsp;&nbsp;Carga masiva
                      </button>
                    </div>
                    -->
                  </div>
                  <button class="btn btn-xs btn-custom pull-right" href="javascript:;"
                          (click)="invokeServiceForDownloadFileRequest()">
                    <i class="fa fa-download fa-sm"></i>&nbsp;&nbsp;Descargar Información
                  </button>
                </div>
              </div>
            </div>
          </tab>

          <!--+---------------------------------------------------------------------------------+
              |-----------------------------| S I N I E S T R O S |-----------------------------|
              +---------------------------------------------------------------------------------+-->
          <tab id="2" heading="Siniestros" (selectTab)="onManagementTabSelect($event)"
               *ngIf="shared.gCurrentPolicyBenefit > 0 && (branchIdSelected === '1' || branchIdSelected === 1)">
            <div class="incident-tab-body">
              <div id="incident-table" class="inc-left">
                <table class="table table-striped table-condensed table-hover">
                  <thead>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Siniestro</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let i of incidentsList" (click)="invokeServiceForGetIncidentDetail(i.incidentId)"
                      title="Ver detalle">
                    <td>{{i.name}}</td>
                    <td>{{i.incidentType}}</td>
                    <td>{{i.incidentNumber}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div id="incident-detail" class="inc-right">
                <div class="detail-head">
                  <div class="title">{{objIncidentDetail.insuredName}}</div>
                  <div class="link" (click)="displayIncidentDetail(objIncidentDetail.incidentId)">Ir a bitácora&nbsp;<i
                    class="glyphicon glyphicon-share-alt"></i></div>
                </div>
                <div class="detail-body">
                  <div id="d-left" class="d-left">
                    <div class="item"><strong>Titular:&nbsp;</strong>{{objIncidentDetail.insuredName}}</div>
                    <div class="item"><strong>Tipo de asegurado:&nbsp;</strong>{{objIncidentDetail.insuredType}}</div>
                    <div class="item"><strong>Edad:&nbsp;</strong>{{objIncidentDetail.age}}</div>
                    <div class="item"><strong>Fecha de alta:&nbsp;</strong>{{objIncidentDetail.created}}</div>
                    <div class="item"><strong>Tipo de siniestro:&nbsp;</strong>{{objIncidentDetail.incidentType}}</div>
                    <div class="item">
                      <strong>Póliza:&nbsp;</strong>{{objIncidentDetail.insurer + ': ' + objIncidentDetail.policyType + ' #' + objIncidentDetail.number}}
                    </div>
                  </div>
                  <div id="d-right" class="d-right">
                    <div class="item"><strong>No certificado:&nbsp;</strong>{{objIncidentDetail.numberCertificate}}
                    </div>
                    <div class="item"><strong>Descripción de siniestro:&nbsp;</strong>{{objIncidentDetail.description}}
                    </div>
                    <div class="incident-annex">
                      <div class="head">
                        <strong>Anexos&nbsp;<span
                          class="badge badge-custom">{{incidentAttachmentList?.length || 0}}</span></strong>
                      </div>
                      <div *ngFor="let ia of incidentAttachmentList">
                        <div class="col-lg-4 col-sm-6 text-center">
                          <a class="annex-link" href="javascript:;" (click)="invokeServiceForDownloadAnnexFile(ia)"
                             title="{{ia.name}}">
                            <span class="sx-iconsys-icon">d</span>
                            <div class="file-name">{{ia.name}}</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="request-tab-footer" style="padding: 10px;position: absolute;bottom: 0;right: 0;left: 0;">
              <button class="btn btn-xs btn-custom pull-right" href="javascript:;"
                      (click)="invokeServiceForDownloadFileIncident()">
                <i class="fa fa-download fa-sm"></i>&nbsp;&nbsp;Descargar Información
              </button>
            </div>
          </tab>

          <!--+---------------------------------------------------------------------------------+
              |--------------------------------| P O L I Z A S |--------------------------------|
              +---------------------------------------------------------------------------------+-->
          <tab id="3" heading="Pólizas" (selectTab)="onManagementTabSelect($event)"
               *ngIf="shared.gCurrentPolicyBenefit>0 || shared.gCurrentPolicyDebtorLife>0 || shared.gCurrentPolicyCar > 0">
            <div class="policy-tab-body" *ngIf="lclSectionSelected == 3"
                 style="overflow-x: hidden; overflow-y: scroll;">
              <div id="card" class="pol-left">
                <div id="c_body" class="content">
                  <div class="btn-group pull-left" dropdown [dropup]="true">
                    <button id="button-policies-types" dropdownToggle type="button"
                            class="btn btn-xs btn-link dropdown-toggle subtitle-section-menu"
                            aria-controls="dropdown-policies-types">
                      {{lclPolicyTypeSelected.description}} <span class="sx-iconsys-icon">1</span>
                    </button>
                    <ul id="dropdown-policies-types" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu"
                        aria-labelledby="button-policies-types">
                      <li role="menuitem" *ngFor="let type of policiesTypesList">
                        <a class="dropdown-item" href="javascript:;"
                           (click)="changeLclPolicyTypeSelected(type)">{{type.description}}</a>
                      </li>
                    </ul>
                  </div>
                  <swiper #swiperPolicy [config]="swiperPoliciesConf" class="swiper-policy">
                    <div class="swiper-pagination"></div>
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" *ngFor="let item of policiesList">
                        <div class="swiper-policy-card"
                             [ngStyle]="{'background': 'linear-gradient(206.57deg, rgba(255, 255, 255, 0.2) -90%, #1BA8B1 60%)'
                                        ,'box-shadow': '0px 15px 30px -20px '}">
                          <img src="{{item.imageCompany}}"/>
                          <div class="pol-number">{{item.description}}</div>
                        </div>
                      </div>
                    </div>
                  </swiper>
                </div>
              </div>
              <div id="policy" class="pol-center">
                <div class="title">{{lclPolicySelected.nameCompany}}</div>
                <div class="item">{{policyDetail.type}}</div>
                <div class="item">{{policyDetail.number}}</div>
                <div class="item"><strong>Desde:&nbsp;</strong>{{policyDetail.startDate | date:'d/M/yyyy'}}</div>
                <div class="item"><strong>Hasta:&nbsp;</strong>{{policyDetail.expirationDate | date:'d/M/yyyy'}}</div>
                <div id="anexos" class="policy-annex">
                  <div class="head">
                    <strong>Anexos&nbsp;<span
                      class="badge badge-custom">{{policyAttachmentsList?.length || 0}}</span></strong>
                    <div class="pull-right" style="cursor: pointer;"
                         (click)="invokeServiceForDownloadPolicyAttachmentZipFile(policyDetail)">Descargar todos&nbsp;<i
                      class="glyphicon glyphicon-download"></i></div>
                  </div>
                  <div *ngIf="policyAttachmentsList?.length > 0">
                    <div *ngFor="let oFile of policyAttachmentsList">
                      <div class="col-md-4 col-sm-4 col-xs-4 text-center">
                        <a class="annex-link" target="_blank"
                           [attr.href]="oFile.typeAttachment==2?oFile.url:undefined"
                           (click)="oFile.typeAttachment == 1?invokeServiceForDownloadPolicyAttachmentFile(oFile):undefined"
                           title="{{oFile.name}}">
                          <span class="sx-iconsys-icon">d</span>
                          <div class="file-name">{{oFile.name}}</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="annex-empty" *ngIf="policyAttachmentsList?.length <= 0">
                    <div>No existen anexos</div>
                  </div>
                </div>
              </div>
              <div id="detail" class="pol-right">
                <div class="p-head">
                  <div class="title">Detalles</div>
                  <div class="action"><a href="javascript:;" (click)="invokeServiceForDownloadPolicyFile()">Versión
                    impresa</a></div>
                </div>
                <div class="p-detail">

                  <!--TODO Preguntar a Susy como crear automaticamente row para separalos-->
                  <!-- <div class="item-align" *ngFor="let item of policyCoverageList" > -->
                  <div *ngFor="let item of policyCoverageList">
                    <div class="col-xs-6 item">
                      <div>{{item.description}}</div>
                      <div>{{item.value}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="request-tab-footer" style="padding: 10px;position: absolute;bottom: 0;right: 0;left: 0;">
              <button class="btn btn-xs btn-custom pull-right" href="javascript:;"
                      (click)="invokeServiceForDownloadFilePolicy()">
                <i class="fa fa-download fa-sm"></i>&nbsp;&nbsp;Descargar Información
              </button>
            </div>
          </tab>

          <!--+---------------------------------------------------------------------------------+
              |-----------------------------| A S E G U R A D O S |-----------------------------|
              +---------------------------------------------------------------------------------+-->
          <tab id="4" heading="Asegurados" (selectTab)="onManagementTabSelect($event)"
               *ngIf="shared.gCurrentPolicyBenefit > 0 && (branchIdSelected === 1 || branchIdSelected === '1')">
            <div class="insured-tab-body" infinite-scroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 (scrolled)="onScrollInsured()">
              <table class="table table-condensed table-striped">
                <thead>
                <th>Nombre</th>
                <th>No. Empleado</th>
                <th>Dependiente</th>
                <th>Edad</th>
                <th>Estado</th>
                <th>Fecha de Alta</th>
                </thead>
                <tbody>
                <tr *ngFor="let i of insuredList" style="cursor: pointer" (click)="viewLikeInsured(i)">
                  <td>{{i.name}}</td>
                  <td>{{i.employeeNumber}}</td>
                  <td>{{i.dependet}}</td>
                  <td>{{i.age}}</td>
                  <td>{{i.status}}</td>
                  <td>{{i.created}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="request-tab-footer" style="padding: 10px;position: absolute;bottom: 0;right: 0;left: 0;">
              <button class="btn btn-xs btn-custom pull-right" href="javascript:;"
                      (click)="invokeServiceForDownloadFileInsured()">
                <i class="fa fa-download fa-sm"></i>&nbsp;&nbsp;Descargar Información
              </button>
            </div>
          </tab>

          <!--+---------------------------------------------------------------------------------+
              |------------------------------| V E H I C U L O S |------------------------------|
              +---------------------------------------------------------------------------------+-->
          <tab id="5" heading="Vehículos" (selectTab)="onManagementTabSelect($event)"
               *ngIf="branchIdSelected === 3 || branchIdSelected === '3'">
            <div class="request-tab-body" infinite-scroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 (scrolled)="onScrollcar()">
              <table class="table table-condensed table-striped">
                <thead>
                <th>Póliza</th>
                <th>Estatus</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Versión</th>
                <th>No. Serie</th>
                <th>Fecha</th>
                </thead>
                <tbody>
                <tr *ngFor="let rq of vehiclesList" style="cursor: pointer"
                    (click)="invokeServiceForDownloadPolicyFileAuto(rq)">
                  <td>{{rq.policy}}</td>
                  <td>{{rq.status}}</td>
                  <td>{{rq.mark}}</td>
                  <td>{{rq.model}}</td>
                  <td>{{rq.version}}</td>
                  <td>{{rq.serialNumber}}</td>
                  <td>{{rq.created}}</td>
                </tr>
                </tbody>
              </table>
            </div>


          </tab>

          <!--+---------------------------------------------------------------------------------+
             |-------------------------------| V I D A D E U D O R |-------------------------------|
             +---------------------------------------------------------------------------------+-->
          <tab id="6" heading="Vida Deudor" (selectTab)="onManagementTabSelect($event)"
               *ngIf="shared.gCurrentPolicyDebtorLife>0">
            <div class="reports-tab-body">
              <div class="btn-group pull-left" dropdown [dropup]="true" style="position:absolute;">
                <button id="button-data-types-debtor" dropdownToggle type="button"
                        class="btn btn-xs btn-link dropdown-toggle subtitle-section-menu"
                        aria-controls="dropdown-data-types-reports">
                  {{lclDataTypeForDebtorLifeSelected.monthName}} <span class="sx-iconsys-icon">1</span>
                </button>
                <ul id="dropdown-data-types-debtorlife" *dropdownMenu class="dropdown-menu dropdown-menu-left"
                    role="menu" aria-labelledby="button-data-types-reports">
                  <li role="menuitem" *ngFor="let item of debtorlifeDataListMonths">
                    <a class="dropdown-item" href="javascript:;"
                       (click)="changeLclDataTypeFile(item)">{{item.monthName}}</a>
                  </li>
                </ul>
              </div>
              <div id="rep-debtorlife" class="rep-left">
                <div id="rep-debtorlife1" class="rep-body">
                  <div class="m-auto" *ngFor="let item of debtorlifeDataList">
                    <div class="card" [ngClass]="lclDebtorLifeSelected== item.debtorLifeId?'card-selected':''">
                      <div class="card-head" (click)="onFileItemSelect(item)">
                        <i class="glyphicon glyphicon-file"
                           [ngStyle]="{'color':(item.typeFile==1?'#3BBBAC':item.typeFile==2?'#F5A623':item.typeFile==3?'#F5A623':'gray-light')}"></i>
                        <p>{{item.name}}</p>
                      </div>
                      <div class="card-footer">
                        <div class="name">
                          <div *ngIf="item.typeFile==2 && item.authorize==0">
                            <a (click)="invokeServiceForAuthorizeFile(item.debtorLifeId)">Validar</a>
                          </div>
                          <div *ngIf="item.typeFile==2 && item.authorize==1">
                            <p>Autorizado</p>
                          </div>
                          <div *ngIf="item.typeFile==2 && item.authorize==2">
                            <p>Rechazado</p>
                          </div>
                        </div>
                        <div class="icon">
                          <i class="glyphicon glyphicon-download" title="Descargar Archivo {{item.name}}"
                             (click)="invokeServiceForDownloadDebtorLifeFile(item.debtorLifeId, item.name)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="rep-detail1" class="rep-right">
                <table class="table table-striped table-condensed"
                       *ngIf="typeDebtorLife==1 && debtorlifeDataListFile != null">
                  <thead>
                  <th>N°CREDITO</th>
                  <th>RAZÓN SOCIAL</th>
                  <th>ACREDITADO</th>
                  <th>FECHA DE INICIO DEL CREDITO</th>
                  <th>SALDO INSOLUTO</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of debtorlifeDataListFile.debtorlifeList">
                    <td>{{item.creditNumber}}</td>
                    <td>{{item.company}}</td>
                    <td>{{item.accredited}}</td>
                    <td>{{item.startCredit}}</td>
                    <td>{{item.balance | CustomCurrency}}</td>
                  </tr>
                  </tbody>
                </table>
                <table class="table table-striped table-condensed"
                       *ngIf="typeDebtorLife==2 && debtorlifeDataListFile != null">
                  <thead>
                  <th>N°CREDITO</th>
                  <th>RAZÓN SOCIAL</th>
                  <th>ACREDITADO</th>
                  <th>FECHA DE INICIO DEL CREDITO</th>
                  <th>SALDO INSOLUTO</th>
                  <th>SUMA ASEGURADA</th>
                  <th>Prima</th>
                  <th>UDI</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of debtorlifeDataListFile.debtorlifeList">
                    <td>{{item.creditNumber}}</td>
                    <td>{{item.company}}</td>
                    <td>{{item.accredited}}</td>
                    <td>{{item.startCredit}}</td>
                    <td>{{item.balance | CustomCurrency}}</td>
                    <td>{{item.amountInsured | CustomCurrency}}</td>
                    <td>{{item.premium | CustomCurrency}}</td>
                    <td>{{item.udi | CustomCurrency}}</td>
                  </tr>
                  </tbody>
                </table>
                <div class="request-tab-footer" style="padding: 10px;position: absolute;bottom: 0;right: 0;left: 0;">
                  <div class="btn-group" role="group" aria-label="...">
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-xs btn-custom" (click)="shared.fOpenSidebar(13)"
                              title="Carga masiva"><i class="fa fa-upload fa-sm"></i>&nbsp;&nbsp;Carga Archivo
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </tab>

          <!--+---------------------------------------------------------------------------------+
            |-------------------------------| R E C I B O S |-------------------------------|
            +---------------------------------------------------------------------------------+-->
          <tab id="7" heading="Cobranza" (selectTab)="onManagementTabSelect($event)"
               *ngIf="shared.gCurrentPolicyBenefit > 0 || shared.gCurrentPolicyCar > 0">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="receipt-card">
                <div class="receipt-left">
                  <div class="btn-group pull-left" dropdown [dropup]="true">
                    <button id="button-receipts-types" dropdownToggle type="button"
                            class="btn btn-xs btn-link dropdown-toggle subtitle-section-menu"
                            aria-controls="dropdown-receipts-types">
                      {{lclReceiptStatusSelected.description}} <span class="sx-iconsys-icon">1</span>
                    </button>
                    <ul id="dropdown-receipts-types" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu"
                        aria-labelledby="button-receipts-types">
                      <li role="menuitem" *ngFor="let status of receiptStatusList">
                        <a class="dropdown-item" href="javascript:;"
                           (click)="changeLclReceiptStatus(status)">{{status.description}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="request-tab-body" infinite-scroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 (scrolled)="onScrollRequet()">
              <div id="collection">
                <table class="table table-condensed table-striped">
                  <thead>
                  <th>Aseguradora</th>
                  <th>No. Póliza</th>
                  <th>Categoría</th>
                  <th>Tipo Póliza</th>
                  <th>Forma de pago</th>
                  <th>Id</th>
                  <th>Fecha de pago</th>
                  <th>Prima Neta</th>
                  <th>Derechos</th>
                  <th>Recargos</th>
                  <th>IVA</th>
                  <th>Prima Total</th>
                  <th>Estatus</th>
                  <th>Descargar Recibos</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let rq of collectionList" style="cursor: pointer">
                    <td>{{rq.insuranceName}}</td>
                    <td>{{rq.numberPolicy}}</td>
                    <td>{{rq.categoryName}}</td>
                    <td>{{rq.typePolicy}}</td>
                    <td>{{rq.payment}}</td>
                    <td>{{rq.number}}</td>
                    <td>{{rq.paymentDate}}</td>
                    <td>{{rq.netPremium | currency: '$'}}</td>
                    <td>{{rq.rigths === null ? 0 : rq.rigths| currency: '$' }}</td>
                    <td>{{rq.charges === null ? 0 : rq.charges| currency: '$' }}</td>
                    <td>{{rq.iva | currency: '$'}}</td>
                    <td>{{rq.totalPremium | currency: '$'}}</td>
                    <td>{{rq.status}}</td>
                    <td> <button class="btn btn-xs btn-custom" href="javascript:;"
                                 (click)="openModalReceiptAttachmentsMov(templateReceiptAnnexes, rq.receiptId)">
                      <i class="fa fa-download fa-sm"></i>
                    </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="request-tab-footer" style="padding: 10px;position: absolute;bottom: 0;right: 0;left: 0;">
              <button class="btn btn-xs btn-custom pull-right" href="javascript:;"
                      (click)="invokeServiceForDownloadFileReceipt()">
                <i class="fa fa-download fa-sm"></i>&nbsp;&nbsp;Descargar Información
              </button>
            </div>
          </tab>

          <div class="button-expand" (click)="maximizeSection()"
               *ngIf="lclSectionSelected == 0 || lclSectionSelected == 3 || lclSectionSelected == 4">
            <i class="glyphicon glyphicon-resize-full"></i>
          </div>

        </tabset>
      </div>
    </div>
  </div>
</div>
<ng-template #templateRequestInfo>
  <div class="modal-header">
    SOLICITUD
  </div>
  <div class="modal-body">
    <div *ngIf="typeEvendId !== '3' && typeEvendId !== '7' && typeEvendId !== 3 && typeEvendId !== 7" class="insured-tab-body">
      <table class="table table-condensed table-striped">
        <thead>
        <th>Movimiento</th>
        <th>Nombre</th>
        <th>Póliza</th>
        <th>Tipo de asegurado</th>
        </thead>
        <tbody>
        <tr *ngFor="let i of requestInfoList" style="cursor: pointer">
          <td>{{i.operation}}</td>
          <td>{{i.name}}</td>
          <td>{{i.numberPolicy}}</td>
          <td>{{i.typeKinship}}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="typeEvendId === '3' || typeEvendId === '7'" class="insured-tab-body">
      <table class="table table-condensed table-striped">
        <thead>
        <th>Movimiento</th>
        <th>Marca</th>
        <th>Modelo</th>
        <th>Versión</th>
        <th>Serie</th>
        </thead>
        <tbody>
        <tr *ngFor="let i of requestInfoList" style="cursor: pointer">
          <td>{{i.operation}}</td>
          <td>{{i.mark}}</td>
          <td>{{i.model}}</td>
          <td>{{i.version}}</td>
          <td>{{i.serial}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <p *ngIf="requestInfoList.length !== 0 && requestInfoList[0].comments !== undefined && requestInfoList[0].comments !== null" class="data"><strong>Comentarios: </strong> {{ requestInfoList[0].comments }}</p>
  </div>
</ng-template>

<ng-template #templateReceiptAnnexes>
  <div class="modal-header">
    ANEXOS <span class="badge badge-custom">{{receiptAttachmentsList?.length}}</span><div class="pull-right" style="cursor: pointer" (click)="invokeServiceForDownloadReceiptAttachmentZipFile()">Descargar todos&nbsp;<i class="glyphicon glyphicon-download"></i></div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div  *ngFor="let oFile of receiptAttachmentsList">
        <div class="col-lg-4 col-md-6 col-sm-3 col-xs-4 text-center">
          <a class="annex-link" href="javascript:;" (click)="invokeServiceForDownloadAttachmentFile(oFile)" title="{{oFile.name}}">
            <span class="sx-iconsys-icon">d</span>
            <div class="file-name">{{oFile.name}}</div>
          </a>
        </div>
      </div>

      <div class="head">

      </div>
    </div>
  </div>
</ng-template>
