// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*api_rest: 'https://app.surexs.com/api',
  api_rocket: 'wss://chat.surexs.com/websocket',
  api_zulip: 'https://chat.surexs.com',
  api_rest_restructured: 'https://app.surexs.com/api',*/
   api_rest_restructured: 'https://servicesq.ammia.io/api/admin',
   api_rocket: '', // ws://134.209.117.135:3000/websocket
   api_zulip: '',
   api_rest: 'https://servicesq.ammia.io/api/admin',
   api_rest_ammia_web_surexs: 'https://services.ammia.io/api/ammia/v1.0/public'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
