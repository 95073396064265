<div style="position:relative;min-height: 100%;width: 340px;padding: 20px">
  <div style="height: 126px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #1BA8B1;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Nueva Solicitud
    </p>
    <div style="text-align: left">
      <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;">Solicitud</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 235px;border-top: 1px solid #E1E1E1;"></span>
    </div>
  </div>



  <div style="min-height: calc(100% - 180px);width: 100%;color: #1BA8B1">

    <!--+---------------------------------------------------------------------------------------------------+
        |-----------------------------------------|  A C C E S O S |----------------------------------------|
        +---------------------------------------------------------------------------------------------------+-->
    <div class="form-group form-group-sm">
      <label>Tipo de solicitud</label>
      <div class="btn-group btn-group-justified" role="group" aria-label="...">
        <div class="btn-group" role="group">
          <button [disabled]="currentRoleId != 3" type="button" class="btn btn-default" [ngClass]="lclRequestType==0?'action-selected':''" (click)="selectRequestType(0)" style="height: 30px;color: #9499B8;font-size: 11px;font-weight: 500;">Alta</button>
        </div>
        <div class="btn-group" role="group">
          <button [disabled]="currentRoleId != 3" type="button" class="btn btn-default" [ngClass]="lclRequestType==1?'action-selected':''" (click)="selectRequestType(1)" style="height: 30px;color: #9499B8;font-size: 11px;font-weight: 500;">Baja</button>
        </div>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default" [ngClass]="lclRequestType==2?'action-selected':''" (click)="selectRequestType(2)" style="height: 30px;color: #9499B8;font-size: 11px;font-weight: 500;">General</button>
        </div>
      </div>
    </div>


    <form id="requestForm" [ngSwitch]="lclRequestType" #f="ngForm" (ngSubmit)="sendRequestData()" [noValidate]="false">

      <!--+---------------------------------------------------------------------------------------------------+
          |-------------------------------------------|  A L T A  |-------------------------------------------|
          +---------------------------------------------------------------------------------------------------+-->
      <div *ngSwitchCase="0">

        <div class="form-group form-group-sm">
          <div class="row">
            <div class="col-xs-6">
              <div class="radio">
                <label>
                  <input type="radio" name="upType" [value]="0" [(ngModel)]="dataToSend.upType" (click)="changeUpType(0)">
                  Asegurado
                </label>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="radio">
                <label>
                  <input type="radio" name="upType" [value]="1" [(ngModel)]="dataToSend.upType" (click)="changeUpType(1)">
                  Dependiente
                </label>
              </div>
            </div>
          </div>
        </div>
        <div [ngSwitch]="dataToSend.upType">

          <!--|||||||||||||||||||||||||||||||||||  A S E G U R A D O  |||||||||||||||||||||||||||||||||||-->
          <div *ngSwitchCase="0">
            <div class="form-group form-group-sm">
              <label class="control-label">Pólizas</label>
              <div class="row">
                <ng-container *ngFor = "let subranch of policiesList;  let i = index" >
                  <div class="form-group col-md-6">
                    <label class="item" style="font-size: small">{{subranch.subranch + ' ' +subranch.numberPolicy}}</label>
                    <br>
                    <ng-container *ngFor = "let category of subranch.policyCategoriesList; let cat = index">
                      <input type="checkbox" [(ngModel)]="category.selected" [ngModelOptions]="{standalone: true}" />
                      <label style="font-size: xx-small; vertical-align: middle; color: #1BA8B1"> {{category.category}}</label>
                      <br>
                    </ng-container>
                  </div>
                </ng-container>
                  <!--
                  <ng-multiselect-dropdown
                    [placeholder]="'Pólizas seleccionadas'"
                    [data]="policiesList"
                    [(ngModel)]="policiesSelectedItems"
                    [settings]="dropdownSettingsPolicies"
                    (onSelect)="changeStatusOnSalaryField()"
                    (onSelectAll)="changeStatusOnSalaryField()"
                    (onDeSelect)="changeStatusOnSalaryField()"
                    name="policies">
                  </ng-multiselect-dropdown>
                  -->
                </div>
            </div>
            <div style="text-align: left;padding-bottom: 10px">
              <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;">Asegurado</span>
              <span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 220px;border-top: 1px solid #E1E1E1;"></span>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Nombre(s)</label>
              <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required upperCaseText>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Apellido Paterno</label>
              <input type="text" class="form-control" [(ngModel)]="dataToSend.pLastname" name="pLastname" required upperCaseText>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Apellido Materno</label>
              <input type="text" class="form-control" [(ngModel)]="dataToSend.sLastname" name="sLastname" required upperCaseText>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Género</label>
              <div class="form-group">
                <label class="radio-inline" *ngFor="let item of gendersCatalog">
                  <input type="radio" name="gender" [value]="item.genderId" [(ngModel)]="dataToSend.gender" required>
                  {{item.description}}
                </label>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Correo electrónico</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="fa fa-envelope"></i></div>
                <input type="text" class="form-control" [(ngModel)]="dataToSend.email" name="email" required>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Número de empleado</label>
              <input type="text" class="form-control" [(ngModel)]="dataToSend.employeeNumber" name="employeeNumber" required>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Fecha de nacimiento</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
                <input type="text"
                       placeholder="00-00-0000"
                       class="form-control"
                       bsDatepicker
                       [bsConfig]="bsBirthdate"
                       [(ngModel)]="dataToSend.birthdate"
                       name="birthdate"
                       required>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Fecha de alta</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
                <input type="text"
                       placeholder="00-00-0000"
                       class="form-control"
                       bsDatepicker
                       [bsConfig]="bsEntryDate"
                       [(ngModel)]="dataToSend.entryDate"
                       name="entryDate"
                       required>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Salario</label>
              <div class="input-group">
                <div class="input-group-addon">$</div>
                <input type="number" step="0.01" class="form-control" [(ngModel)]="dataToSend.salary" name="salary" >
              </div>
            </div>

            <div class="form-group form-group-sm">
              <label class="control-label">RECONOCIMIENTO DE ANTIGÜEDAD</label>
              <div class="form-group">
                <label class="btn btn-custom btn-sm btn-block"><i class="fa fa-folder-open"></i>&nbsp;Buscar...
                  <input type="file" style="display: none" (change)="onChangeInputFile($event)" name="antiquie">
                </label>
              </div>
            </div>

            <div class="form-group form-group-sm">
              <label class="control-label">Nombre de archivo</label>
              <div class="form-group">
                <label class="text-primary" *ngIf="fileUpload == undefined" [hidden]="error.active">----------</label>
                <label class="text-primary" style="width: 100%" *ngIf="fileUpload != undefined" [hidden]="error.active">
                  {{ fileUpload.name }} <span class="pull-right">{{shared.formatBytes(fileUpload.size,2)}}</span>
                </label>
                <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
              </div>
            </div>
            <hr>
            <!---------------||||| Dependientes del asegurado |||||------------------->
            <div style="text-align: left;padding-bottom: 10px">
              <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;cursor: pointer;" title="Agregar dependientes" (click)="activateDependentRegistrationInInsured()">
                <i class="fa fa-plus-circle"></i>&nbsp;Dependientes
              </span>
              <span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 145px;border-top: 1px solid #E1E1E1;"></span>
              <span class="badge pull-right">
                <i *ngIf="dependentsList?.length <= 0" class="fa fa-user-alt fa-sm"></i>
                <span style="cursor: pointer" *ngIf="dependentsList?.length > 0" >+{{dependentsList?.length}}</span>
              </span>
            </div>
            <div *ngIf="attachDependents">
              <div class="form-group form-group-sm">
                <label class="control-label">Nombre(s)</label>
                <input type="text" class="form-control" [(ngModel)]="dataToSendDep.name" name="nameDep" required upperCaseText>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Apellido Paterno</label>
                <input type="text" class="form-control" [(ngModel)]="dataToSendDep.pLastname" name="pLastnameDep" required upperCaseText>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Apellido Materno</label>
                <input type="text" class="form-control" [(ngModel)]="dataToSendDep.sLastname" name="sLastnameDep" required upperCaseText>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Género</label>
                <div class="form-group">
                  <label class="radio-inline" *ngFor="let item of gendersCatalog">
                    <input type="radio" name="genderDep" [value]="item.genderId" [(ngModel)]="dataToSendDep.gender" required>
                    {{item.description}}
                  </label>
                </div>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Fecha de nacimiento</label>
                <div class="input-group">
                  <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
                  <input type="text"
                         placeholder="00-00-0000"
                         class="form-control"
                         bsDatepicker
                         [bsConfig]="bsBirthdate"
                         [(ngModel)]="dataToSendDep.birthdate"
                         name="birthDateDep"
                         required>
                </div>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Parentesco</label>
                <select class="form-control" [(ngModel)]="dataToSendDep.relationship" name="relationshipDep" required>
                  <option value="0" disabled selected>Seleccionar...</option>
                  <option *ngFor="let item of relationshipList" [value]="item.kinshipId">{{item.name}}</option>
                </select>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Fecha de alta</label>
                <div class="input-group">
                  <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
                  <input type="text"
                         placeholder="00-00-0000"
                         class="form-control"
                         bsDatepicker
                         [bsConfig]="bsEntryDate"
                         [(ngModel)]="dataToSendDep.entryDate"
                         name="entryDateDep"
                         required disabled>
                </div>
              </div>
            </div>


          </div>

          <!--|||||||||||||||||||||||||||||||||  D E P E N D I E N T E  |||||||||||||||||||||||||||||||||-->
          <div *ngSwitchCase="1">
            <div class="form-group has-feedback">
              <label class="control-label">Asegurado</label>
              <span class="glyphicon glyphicon-search form-control-feedback" aria-hidden="true"></span>
              <input [(ngModel)]="searchItemSelected"
                     [typeaheadAsync]="true"
                     [typeahead]="dataSource"
                     (typeaheadLoading)="changeTypeaheadLoading($event)"
                     (typeaheadOnSelect)="typeaheadOnSelect($event)"
                     [typeaheadOptionsLimit]="7"
                     typeaheadWaitMs="1000"
                     typeaheadOptionField="name"
                     placeholder="Buscar…"
                     class="form-control"
                     name="insured"
                     autocomplete="off"
                     required>
              <div *ngIf="typeaheadLoading">Loading</div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Pólizas</label>
              <div class="row">
                <ng-container *ngFor = "let subranch of policiesList;  let i = index" >
                  <div class="form-group col-md-6">
                    <label class="item" style="font-size: small">{{subranch.subranch + ' ' +subranch.numberPolicy}}</label>
                    <br>
                    <ng-container *ngFor = "let category of subranch.policyCategoriesList; let cat = index">
                      <input type="checkbox" [(ngModel)]="category.selected" [ngModelOptions]="{standalone: true}" />
                      <label style="font-size: xx-small; vertical-align: middle; color: #1BA8B1"> {{category.category}}</label>
                      <br>
                    </ng-container>
                  </div>
                </ng-container>
                <!--
                <div class="col-xs-12">
                  <ng-multiselect-dropdown
                    [placeholder]="'Pólizas seleccionadas'"
                    [data]="policiesList"
                    [(ngModel)]="policiesSelectedItems"
                    [settings]="dropdownSettingsPolicies"
                    (onSelect)="changeStatusOnSalaryField()"
                    (onSelectAll)="changeStatusOnSalaryField()"
                    (onDeSelect)="changeStatusOnSalaryField()"
                    name="policies"
                    required>
                  </ng-multiselect-dropdown>
                </div>-->
              </div>
            </div>
            <div style="text-align: left;padding-bottom: 10px">
              <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;">Dependiente</span>
              <span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 170px;border-top: 1px solid #E1E1E1;"></span>
              <span class="badge pull-right">
                <i *ngIf="dependentsList?.length <= 0" class="fa fa-user-alt fa-sm"></i>
                <span style="cursor: pointer" *ngIf="dependentsList?.length > 0" title="{{ displayForm?'Ver dependientes':'Ocultar dependientes'}}" (click)="displayForm = !displayForm">+{{dependentsList?.length}}</span>
              </span>
            </div>
            <div [collapse]="!displayForm">
              <div class="form-group form-group-sm">
                <label class="control-label">Nombre(s)</label>
                <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required upperCaseText>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Apellido Paterno</label>
                <input type="text" class="form-control" [(ngModel)]="dataToSend.pLastname" name="pLastname" required upperCaseText>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Apellido Materno</label>
                <input type="text" class="form-control" [(ngModel)]="dataToSend.sLastname" name="sLastname" required upperCaseText>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Género</label>
                <div class="form-group">
                  <label class="radio-inline" *ngFor="let item of gendersCatalog">
                    <input type="radio" name="gender" [value]="item.genderId" [(ngModel)]="dataToSend.gender">
                    {{item.description}}
                  </label>
                </div>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Fecha de nacimiento</label>
                <div class="input-group">
                  <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
                  <input type="text"
                         placeholder="00-00-0000"
                         class="form-control"
                         bsDatepicker
                         [bsConfig]="bsBirthdate"
                         [(ngModel)]="dataToSend.birthdate"
                         name="entryDate"
                         required>
                </div>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Parentesco</label>
                <select class="form-control" [(ngModel)]="dataToSend.relationship" name="relationship" required>
                  <option [value]="0" disabled [selected]="true">Seleccionar...</option>
                  <option *ngFor="let item of relationshipList" [value]="item.kinshipId">{{item.name}}</option>
                </select>
              </div>
              <div class="form-group form-group-sm">
                <label class="control-label">Fecha de alta</label>
                <div class="input-group">
                  <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
                  <input type="text"
                         placeholder="00-00-0000"
                         class="form-control"
                         bsDatepicker
                         [bsConfig]="bsEntryDate"
                         [(ngModel)]="dataToSend.entryDate"
                         name="entryDate"
                         required>
                </div>
              </div>
            </div>
            <div [collapse]="displayForm">
              <table class="table table-condensed table-striped">
                <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Parentesco</th>
                  <th>Alta</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of dependentsList">
                    <td>{{item.name + ' ' + item.firstName + ' ' + item.lastName}}</td>
                    <td>{{item.kinship}}</td>
                    <td>{{item.startDate | date: 'dd-MM-yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!--+---------------------------------------------------------------------------------------------------+
          |-------------------------------------------|  B A J A  |-------------------------------------------|
          +---------------------------------------------------------------------------------------------------+-->
      <div *ngSwitchCase="1">
        <div class="form-group form-group-sm">
          <div class="row">
            <div class="col-xs-6">
              <div class="radio">
                <label>
                  <input type="radio" name="upType" [value]="0" [(ngModel)]="dataToSend.upType" (click)="changeUpType(0)">
                  Asegurado
                </label>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="radio">
                <label>
                  <input type="radio" name="upType" [value]="1" [(ngModel)]="dataToSend.upType" (click)="changeUpType(1)">
                  Dependiente
                </label>
              </div>
            </div>
          </div>
        </div>
        <div [ngSwitch]="dataToSend.upType">

          <!--|||||||||||||||||||||||||||||||||||  A S E G U R A D O  |||||||||||||||||||||||||||||||||||-->
          <div *ngSwitchCase="0">
            <div class="form-group has-feedback">
              <label class="control-label">Asegurado</label>
              <span class="glyphicon glyphicon-search form-control-feedback" aria-hidden="true"></span>
              <input [(ngModel)]="searchItemSelected"
                     [typeaheadAsync]="true"
                     [typeahead]="dataSource"
                     (typeaheadLoading)="changeTypeaheadLoading($event)"
                     (typeaheadOnSelect)="typeaheadOnSelect($event)"
                     [typeaheadOptionsLimit]="7"
                     typeaheadWaitMs="1000"
                     typeaheadOptionField="name"
                     placeholder="Buscar…"
                     class="form-control"
                     name="insured"
                     autocomplete="off">
              <div *ngIf="typeaheadLoading">Loading</div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Pólizas</label>
              <div class="row">
                <ng-container *ngFor = "let subranch of policiesList;  let i = index" >
                  <div class="form-group col-md-6">
                    <label class="item" style="font-size: small">{{subranch.subranch + ' ' +subranch.numberPolicy}}</label>
                    <br>
                    <ng-container *ngFor = "let category of subranch.policyCategoriesList; let cat = index">
                      <input type="checkbox" [(ngModel)]="category.selected" [ngModelOptions]="{standalone: true}" />
                      <label style="font-size: xx-small; vertical-align: middle; color: #1BA8B1"> {{category.category}}</label>
                      <br>
                    </ng-container>
                  </div>
                </ng-container>
                <!--
                <div class="col-xs-12">
                  <ng-multiselect-dropdown
                    [placeholder]="'Pólizas seleccionadas'"
                    [data]="policiesList"
                    [(ngModel)]="policiesSelectedItems"
                    [settings]="dropdownSettingsPolicies"
                    (onSelect)="changeStatusOnSalaryField()"
                    (onSelectAll)="changeStatusOnSalaryField()"
                    (onDeSelect)="changeStatusOnSalaryField()"
                    name="policies">
                  </ng-multiselect-dropdown>
                </div>-->
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Fecha de baja</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
                <input type="text"
                       placeholder="00-00-0000"
                       class="form-control"
                       bsDatepicker
                       [bsConfig]="bsLeavingDate"
                       [(ngModel)]="dataToSend.leavingDate"
                       name="leavingDate">
              </div>
            </div>
          </div>

          <!--|||||||||||||||||||||||||||||||||  D E P E N D I E N T E  |||||||||||||||||||||||||||||||||-->
          <div *ngSwitchCase="1">
            <div class="form-group has-feedback">
              <label class="control-label">Asegurado</label>
              <span class="glyphicon glyphicon-search form-control-feedback" aria-hidden="true"></span>
              <input [(ngModel)]="searchItemSelected"
                     [typeaheadAsync]="true"
                     [typeahead]="dataSource"
                     (typeaheadLoading)="changeTypeaheadLoading($event)"
                     (typeaheadOnSelect)="typeaheadOnSelect($event)"
                     [typeaheadOptionsLimit]="7"
                     typeaheadWaitMs="1000"
                     typeaheadOptionField="name"
                     placeholder="Buscar…"
                     class="form-control"
                     name="insured"
                     autocomplete="off">
              <div *ngIf="typeaheadLoading">Loading</div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Póliza</label>
              <div class="row">
                <ng-container *ngFor = "let subranch of policiesList;  let i = index" >
                  <div class="form-group col-md-6">
                    <label class="item" style="font-size: small">{{subranch.subranch + ' ' +subranch.numberPolicy}}</label>
                    <br>
                    <ng-container *ngFor = "let category of subranch.policyCategoriesList; let cat = index">
                      <input type="checkbox" [(ngModel)]="category.selected" [ngModelOptions]="{standalone: true}" />
                      <label style="font-size: xx-small; vertical-align: middle; color: #1BA8B1"> {{category.category}}</label>
                      <br>
                    </ng-container>
                  </div>
                </ng-container>
                <!--<div class="col-xs-12">
                  <ng-multiselect-dropdown
                    [placeholder]="'Pólizas seleccionadas'"
                    [data]="policiesList"
                    [(ngModel)]="policiesSelectedItems"
                    [settings]="dropdownSettingsPolicies"
                    (onSelect)="changeStatusOnSalaryField()"
                    (onSelectAll)="changeStatusOnSalaryField()"
                    (onDeSelect)="changeStatusOnSalaryField()"
                    name="policies"
                    required>
                  </ng-multiselect-dropdown>
                </div> -->
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Dependientes</label>
              <div class="row">
                <div class="col-xs-12">
                  <ng-multiselect-dropdown
                    [placeholder]="'Dependientes seleccionados'"
                    [data]="dependentsList"
                    [(ngModel)]="dependentsSelectedItems"
                    [settings]="dropdownSettingsDependents"
                    name="dependents">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
            <div class="form-group form-group-sm">
              <label class="control-label">Fecha de baja</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="fa fa-calendar-alt"></i></div>
                <input type="text"
                       placeholder="00-00-0000"
                       class="form-control"
                       bsDatepicker
                       [bsConfig]="bsLeavingDate"
                       [(ngModel)]="dataToSend.leavingDate"
                       name="leavingDate">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--+---------------------------------------------------------------------------------------------------+
          |----------------------------------------|  G E N E R A L  |----------------------------------------|
          +---------------------------------------------------------------------------------------------------+-->
      <div *ngSwitchCase="2">
        <div class="form-group form-group-sm">
          <label class="control-label">Póliza</label>
          <div class="row">
            <div class="col-xs-12">
              <!--<ng-multiselect-dropdown
                [placeholder]="'Pólizas seleccionadas'"
                [data]="policiesList"
                [(ngModel)]="policiesSelectedItems"
                [settings]="dropdownSettingsPolicies"
                (onSelect)="changeStatusOnSalaryField()"
                (onSelectAll)="changeStatusOnSalaryField()"
                (onDeSelect)="changeStatusOnSalaryField()"
                name="policies">
              </ng-multiselect-dropdown>-->
              <ng-container *ngFor = "let subranch of policiesList;  let i = index" >
                <div class="form-group col-md-6">
                  <label class="item" style="font-size: small">{{subranch.subranch + ' ' +subranch.numberPolicy}}</label>
                  <br>
                  <ng-container *ngFor = "let category of subranch.policyCategoriesList; let cat = index">
                    <input type="checkbox" [(ngModel)]="category.selected" [ngModelOptions]="{standalone: true}" />
                    <label style="font-size: xx-small; vertical-align: middle; color: #1BA8B1"> {{category.category}}</label>
                    <br>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Asunto</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.subject" name="subject" required upperCaseText>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Descripción</label>
          <textarea rows="3" class="form-control" [(ngModel)]="dataToSend.description" name="description" required upperCaseText></textarea>
        </div>
        <div style="text-align: left;padding-bottom: 10px">
          <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;">Beneficiario</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 216px;border-top: 1px solid #E1E1E1;"></span>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Nombre(s)</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Apellido Paterno</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.pLastname" name="pLastname" required>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Apellido Materno</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.sLastname" name="sLastname" required>
        </div>
        <div class="form-group form-group-sm">
          <div class="checkbox">
            <label style="font-size: 11px;">
              <input type="checkbox" [(ngModel)]="dataToSend.incumbent" name="incumbent"> El beneficiario es titular
            </label>
          </div>
        </div>
        <div class="form-group form-group-sm">
          <label class="control-label">Número de empleado</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.employeeNumber" name="employeeNumber" required>
        </div>
      </div>
    </form>
  </div>
  <div class="text-center" [hidden]="!displayForm">
    <div class="btn-group btn-group-justified" role="group" aria-label="...">
      <div class="btn-group" role="group" *ngIf="(lclRequestType == 0 && dataToSend.upType == 0 && attachDependents) || (lclRequestType == 0 && dataToSend.upType == 1)">
        <button type="button" class="btn btn-sm btn-custom" (click)="addNewDependent()">Nuevo dependiente</button>
      </div>
      <div class="btn-group" role="group">
        <button type="submit" class="btn btn-sm btn-custom" form="requestForm" >Reportar</button>
      </div>
    </div>
  </div>
</div>
