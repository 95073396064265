

<div id="main" style="width: fit-content; min-width: 100%">
  <app-navbar></app-navbar>
  <div class="content-page">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
<app-sidebar></app-sidebar>
