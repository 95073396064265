import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {RecoveryPasswordMdlComponent} from '../recovery-password-mdl/recovery-password-mdl.component';
import {ValidateCodeMdlComponent} from '../validate-code-mdl/validate-code-mdl.component';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  user: any = {};
  loading = false;
  notify: any;
  bsModalRef: BsModalRef;
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {

    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern(/^[a-z0-9_-]{6,18}$/)]]
    });

  }

  ngOnInit() {
  }

  authentication() {
    this.api.authentication(this.loginForm.value)
      .then(
        (user) => {
          this.user = user;
          if (user) {
            localStorage.setItem('token', window.btoa(this.loginForm.value.username + ':' + this.loginForm.value.password));
            localStorage.setItem('user-id', this.user.userId);
            localStorage.setItem('roleId', this.user.rol.roleId);
            localStorage.setItem('rpass', this.user.passwordChat);
            localStorage.setItem('zulip', JSON.stringify(this.user.zulip));
            if (this.user.changePassword) {
              this.openModalWithComponentValideCode(user);
            } else {
              this.redirectCorrectHome();
            }
          }
        }, error => {
          this.loading = false;
          this.launchNotify();
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
        }
      );
  }
  redirectCorrectHome() {
    this.api.getUserRoles()
      .then(
        (data: any) => {
          let goToAdmin = false;
          this.shared.gUserRoles = data;
          localStorage.setItem('userRoles', JSON.stringify(data));
          data.forEach((item, index) => {
            // if (item.roleId === 3 && item.defaultRole) {
            if (item.roleId === 3) {
              goToAdmin = true;
              localStorage.setItem('roleId', item.roleId);
            }

            if (index === (data.length - 1) && goToAdmin) {
              this.router.navigate(['/admin/home']);
            } else if (index === (data.length - 1) && !goToAdmin) {
              this.openModalInsured();
            }
          });
        }, error => {
          console.info(error);
        });
  }

  private launchNotify() {
    console.info('Notificacion');
    let message = 'El nombre de usuario y la contraseña que ingresaste no coinciden con nuestros registros. ';
    message = message + 'Por favor, revisa e inténtalo de nuevo.';
    this.toastr.warning(message, `Notificación`);
  }

  openModalWithComponentRecovery() {
    this.bsModalRef = this.modalService.show(RecoveryPasswordMdlComponent, {});
    this.bsModalRef.setClass('modal-sm');
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
      } else if (result === false) {
        console.info('Modal cancel');
      } else {
        console.info('Modal close');
      }
    });
  }


  openModalWithComponentValideCode(user) {
    const initialState = {user};
    this.bsModalRef = this.modalService.show(ValidateCodeMdlComponent, {initialState});
    this.bsModalRef.setClass('modal-sm');
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.redirectCorrectHome();
      } else if (result === false) {
        console.info('Modal cancel');
      } else {
        console.info('Modal close');
      }
    });
  }

  openModalInsured() {
    this.bsModalRef = this.modalService.show('templateRestorePassword', {class: 'modal-sm', keyboard: false});
  }
}
