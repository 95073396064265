<div style="position:relative;height: auto;width: 500px;padding: 20px">
  <div style="height: 126px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #1BA8B1;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Cotizador
    </p>

    <div style="display: flex;">
      <span style="white-space: nowrap;vertical-align: middle;color: #1BA8B1;font-size: 16px;font-weight: bold;">
        Cliente
      </span>
      <hr style="margin: auto auto auto 8px; height: 1px;width: 100%;border-top: 1px solid #E1E1E1;">
    </div>
  </div>

  <div style="min-height: calc(100vh - 200px);width: 100%;color: #1BA8B1">
<!--
    <form id="requestForm" [ngSwitch]="lclRequestType" #f="ngForm" (ngSubmit)="sendRequestData()" [noValidate]="false">
      <div class="form-group form-group-sm">
        <label class="control-label">Nombre(s)</label>
        <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
      </div>

      <div class="row">
        <div class="form-group form-group-sm col-md-6">
          <label class="control-label">Apellido paterno</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
        </div>
        <div class="form-group form-group-sm col-md-6">
          <label class="control-label">Apellido materno</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
        </div>
      </div>
      <div class="form-group form-group-sm">
        <label class="control-label">Email</label>
        <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
      </div>
      <div class="row">
        <div class="form-group form-group-sm col-md-6">
          <label class="control-label">Edad</label>
          <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
        </div>
        <div class="form-group form-group-sm col-md-6">
          <label class="control-label">Género</label>
          <div class="form-group">-->
            <!--label class="radio-inline" *ngFor="let item of gendersCatalog"-->
         <!--   <label class="radio-inline" *ngFor="let item of ['Femenino','Masculino']">
              <input type="radio" name="gender" [(ngModel)]="dataToSend.gender">
              {{item}}
            </label>
          </div>
        </div>
      </div>
    </form>-->

    <div style="display: flex;" class="mt-10">
      <span style="white-space: nowrap;vertical-align: middle;color: #1BA8B1;font-size: 16px;font-weight: bold;">
        Seguro
      </span>
      <hr style="margin: auto auto auto 8px; height: 1px;width: 100%;border-top: 1px solid #E1E1E1;">
    </div>

    <div class="form-group form-group-sm mt-10">
      <label class="control-label">Tipo de póliza</label>
      <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
    </div>

    <div class="form-group form-group-sm mt-10">
      <label class="control-label">Tipo de vehículo</label>
      <div class="btn-group btn-group-justified" role="group" aria-label="...">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default">Auto</button>
        </div>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-default">Pickup</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-group-sm col-md-6">
        <label class="control-label">Marca</label>
        <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
      </div>
      <div class="form-group form-group-sm col-md-6">
        <label class="control-label">Modelo</label>
        <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
      </div>
      <div class="form-group form-group-sm col-md-6">
        <label class="control-label">Versión</label>
        <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
      </div>
      <div class="form-group form-group-sm col-md-6">
        <label class="control-label">Código Postal</label>
        <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
      </div>
    </div>

  </div>

  <div class="text-center">
    <button type="submit" class="btn btn-sm btn-custom btn-block" form="requestForm" >Cotizar</button>
  </div>

</div>
