 import {Component, OnInit, ViewChild} from '@angular/core';
 import {SharedService} from '../shared-service.service';
 import {WebsocketService} from '../websocket.service';
 import {FormBuilder, FormGroup, Validators} from '@angular/forms';
 // import { PushNotificationsService} from 'ng-push';
 import {DatePipe} from '@angular/common';
 import {environment} from '../../environments/environment';
 import {ApiService} from '../api.service';
 const API_ZULIP = environment.api_zulip;
 import * as zulip from 'zulip-js';
 import {PushNotificationService} from 'ng-push-notification';

 @Component({
   selector: 'app-chat',
   templateUrl: './chat.component.html',
   styleUrls: ['./chat.component.css']
 })
 export class ChatComponent implements OnInit {
   lclContentSidebar: number;
   userId: string;
   chatForm: FormGroup;
   msgList: Array<any> = [];
   surexsChatAgents: Array<any> = [];
   zulipConfig: any = {};
   zulipConection: any = [];
   @ViewChild('chatContainer') private chatContainer: any;

   constructor(public shared: SharedService,
               private wsRocket: WebsocketService,
               private fb: FormBuilder,
               private pushNotification: PushNotificationService,
               private dPipe: DatePipe,
               private api: ApiService) {
     this.pushNotification.requestPermission().then();
     this.chatForm = this.fb.group({
       message: ['', [Validators.required]],
     });
     /*this.shared.fStreamRoomMessagesObservable().subscribe(
       fStreamRoomMessages => {
         if (fStreamRoomMessages) {
           this.msgList = this.shared.gMsgList;
           this.userId = localStorage.getItem('ruid');
           this.streamingRoomMessages();
         }
       });*/

     this.shared.fContentSidebarObservable().subscribe(
       sbId => {
         this.lclContentSidebar = sbId;
         if (this.lclContentSidebar === 1) {
           setTimeout(() => {
             this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
           }, 300);
           setTimeout(() => {
             this.shared.gMessageCount = 0;
           }, 1000);
         }
       }
     );
   }

   ngOnInit() {
      this.wsRocket.connectToHost();
      this.connectToHostZulip();
   }
 /*
   streamingRoomMessages() {
     this.wsRocket.streamRoomMessages().subscribe(
       (data) => {
         console.info(data);
         this.msgList.push(data.fields.args[0]);
         if (this.lclContentSidebar !== 1) {
           this.shared.gMessageCount++;
           const lastMessage = this.msgList[this.msgList.length - 1].msg;
           this.webNotify(lastMessage);
         }
         setTimeout(() => {
           this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
         }, 300);
       },
       (err) => console.log(err),
       () => console.log('Completed')
     );
   }
   sendMessage(text: string) {
     console.log('I am sending :' + this.chatForm.value.message);
     text = this.chatForm.value.message;
     this.wsRocket.sendChatMessage(text).subscribe(
       (data) => console.log('Send message response :' + JSON.stringify(data))
     );
     this.chatForm.reset();
   }
 */
   webNotify(message) {
        const options = {  // set options
          body: message,
          icon: 'assets/img/favicon.ico',  // adding an icon
          silent: false,
          sound: 'assets/sounds/chime.mp3'
        };
        this.pushNotification.show('Soporte Surexs', options);

          /*
          const options = {  // set options
            body: message,
            icon: 'assets/img/favicon.ico',  // adding an icon
            silent: false,
            sound: 'assets/sounds/chime.mp3'
          };
          this.pushNotifications.create('Soporte Surexs', options).subscribe(  // creates a notification
            res => console.log(res),
            err => console.log(err)
          );*/
   }

   /***
    * Método para la conexión server  zulip
    */
   connectToHostZulip() {
     this.zulipConfig = JSON.parse(localStorage.getItem('zulip'));
     this.userId = this.zulipConfig.email;
     const config = {
       username: this.zulipConfig .email,
       password: this.zulipConfig .password,
       realm: API_ZULIP
     };
     zulip(config).then(zulip1 => {
       this.zulipConection = zulip1;
       console.log(this.zulipConection.config);
       this.createSubscribeStream();
       this.getMessages();
       this.streamRoomMessagesSubscription();
     });
   }

   /***
    * Método realtime para recibir los mensajes zulip
    */
   public streamRoomMessagesSubscription() {
     const handleEvent = async (event) => {
        console.log('Got Event:', event);
        if (event.type === 'message' && event.message.display_recipient === this.zulipConfig.stream) {
         event.message.timestamp = this.getFormatDate(event.message.timestamp);
         this.msgList.push(event.message);
         if (this.lclContentSidebar !== 1) {
           this.shared.gMessageCount++;
           const lastMessage = this.msgList[this.msgList.length - 1].content;
           this.webNotify(lastMessage);
         }
         setTimeout(() => {
           this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
         }, 300);
       }
     };
     this.zulipConection.callOnEachEvent(handleEvent, ['message']);
   }

   /***
    * Método que obtiene el historial zulip
     */
   getMessages() {
     const readParams = {
       stream: this.zulipConfig.stream,
       anchor: 'newest',
       num_before: 100,
       num_after: 0,
       narrow: [
         {operator: 'stream', operand: this.zulipConfig.stream},
       ],
       content_type: 'text/plain'
     };
     const regex = /(<([^>]+)>)/ig;
     this.zulipConection.messages.retrieve(readParams).then(res => {
      console.log(res.messages);
      for (const item in res.messages) {
         res.messages[item].content = res.messages[item].content.replace(regex, '');
         res.messages[item].timestamp = this.getFormatDate(res.messages[item].timestamp);
         this.msgList.push(res.messages[item]);
     }
      console.log(this.msgList);
     });
   }
   getFormatDate(timestamp) {
     const date = new Date(timestamp * 1000);
     return this.dPipe.transform(date, 'dd MMM yyyy hh:mm:ss');

   }

   sendMessage(text: string) {
      console.log('I am sending :' + this.chatForm.value.message);
      text = this.chatForm.value.message;
      this.sendChatMessage(text);
      this.chatForm.reset();

   }

   /**
    * Método para enviar mensaje zulip
    * @param pMsg
    */
   sendChatMessage(pMsg) {
     const request = {
       type: 'stream',
       to: this.zulipConfig.stream,
       topic: 'stream events',
       content: pMsg,
     };
     this.zulipConection.messages.send(request).then(res => {
     });

   }
   /**
    * Método para subscribir a un usuario a uno o varios streams
    */
   createSubscribeStream() {
     this.api.getAgentsChatSurexs()
       .subscribe(
         (response: any) => {
           response.forEach((item, index) => {
             this.surexsChatAgents.push(item.userId);
           });
           this.surexsChatAgents.push(this.zulipConfig.zulipId);
            // crear canal
           const namesStreams = [{name: this.zulipConfig.stream}];
           const anotherUserParams = {
             invite_only: true,
             subscriptions: JSON.stringify(namesStreams),
             principals: JSON.stringify(this.surexsChatAgents),
           };
           console.log(namesStreams);
           console.log(anotherUserParams);
           this.zulipConection.users.me.subscriptions.add(anotherUserParams).then((data) => {
             return data;
           });
         }, error => {
           console.error(error);
         }
       );
   }

   /*subscriptionEventMessage() {
     const params = {
       event_types: ['message'],
     };
     this.zulipConection.queues.register(params).then((data) => {
       console.log(data);
     });
   }*/


 }
