<div style="position:relative;height: 100%;width: 340px;">
  <div style="height: 130px;width: 100%; border-bottom: 1px solid #C4C4C4; text-align: center;padding: 20px">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #1BA8B1;font-size: 16px;font-weight: 600;line-height: 19px;margin-top: 33px">
      Cuenta
    </p>
  </div>
  <div class="row" style="height: calc(100% - 200px)">
    <div class="col-xs-12">
      <div class="modal-body">
        <div class="image-profile-container">
          <img class="image-profile img-responsive" src="{{userUpdate.imageProfile}}" />
          <div class="btn-float-edit-image">
            <label><span class="sx-iconsys-icon i-icon">e</span>
              <input type="file" style="display: none;" (change)="onUploadChange($event)"  accept=".png, .jpg, .jpeg">
            </label>
            <!--<span class="sx-iconsys-icon i-icon">
              <input type="file" style="display: none;" (change)="onUploadChange($event)" >
            </span>-->
          </div>
        </div>
        <div class="title-profile"><strong>{{userUpdate.name}}</strong></div>
        <div class="subtitle-profile">{{userUpdate.position}}</div>
        <div class="subtitle-profile">{{userUpdate.email}}</div>
        <div class="subtitle-profile" *ngIf="!banderapass">
          <span>************</span>
          <div class="btn-float-edit-data">
            <span class="sx-iconsys-icon i-icon" (click)="changuepassword()">e</span>
          </div>
        </div>
        <div class="p-top-20">
          <div class="form-group form-group-sm">
            <label class="sr-only control-label" for="oldPass">Contraseña actual</label>
            <input type="password" id="oldPass" class="form-control" [(ngModel)]="oldpassword" *ngIf="banderapass" placeholder="Contraseña actual">
          </div>
          <div class="form-group form-group-sm">
            <label class="sr-only control-label" for="newPass">Contraseña nueva</label>
            <input type="password" id="newPAss" class="form-control" [(ngModel)]="newpassword" *ngIf="banderapass" placeholder="Contraseña nueva">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer text-center">
    <button class="btn btn-xs btn-custom" (click)="updateProfile()">Actualizar</button>
  </div>
</div>
<!--
<div class="modal-header">
  <h4 class="modal-title text-center">Cuenta</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xs-12">
      <div class="image-profile-container">
        <img class="image-profile img-responsive" src="{{userUpdate.imageProfile}}" />
        <div class="btn-float-edit-image">
          <label><span class="sx-iconsys-icon i-icon">e</span>
             <input type="file" style="display: none;" (change)="onUploadChange($event)"  accept=".png, .jpg, .jpeg">
          </label>
        </div>
      </div>
      <div class="title-profile"><strong>{{userUpdate.name}}</strong></div>
      <div class="subtitle-profile">{{userUpdate.position}}</div>
      <div class="subtitle-profile">{{userUpdate.email}}</div>
      <div class="subtitle-profile" *ngIf="!banderapass">
        <span>************</span>
        <div class="btn-float-edit-data">
          <span class="sx-iconsys-icon i-icon" (click)="changuepassword()">e</span>
        </div>
      </div>
      <div class="p-top-20">
        <div class="form-group form-group-sm">
          <label class="sr-only control-label" for="oldPass">Contraseña actual</label>
          <input type="password" id="oldPass" class="form-control" [(ngModel)]="oldpassword" *ngIf="banderapass" placeholder="Contraseña actual">
        </div>
        <div class="form-group form-group-sm">
          <label class="sr-only control-label" for="newPass">Contraseña nueva</label>
          <input type="password" id="newPAss" class="form-control" [(ngModel)]="newpassword" *ngIf="banderapass" placeholder="Contraseña nueva">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer text-center">
  <button class="btn btn-custom" (click)="updateProfile()">Actualizar</button>
</div>
-->
