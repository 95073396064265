<div class="modal-header">
  <h4 class="modal-title pull-left">Validación de código</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="myForm" novalidate>
    <p class="description">Puede encontrar el código de validación en su correo electrónico</p>
    <input type="text" class="form-control border-input" [(ngModel)]="code" placeholder="Ingresa código" [maxLength]="4" formControlName="code">
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-custom" (click)="onCancel()" >Cancelar</button>
  <button class="btn btn-custom" (click)="sendcode(code)" [disabled]="myForm.invalid">Aceptar</button>
</div>
