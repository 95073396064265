/**
 * @fileoverview Define el comportamiento de todas las secciones del dashboard Admin
 * @version 1.0.0
 * @author Juan Ramírez
 */

import {AfterViewInit, Component, ElementRef, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {TabDirective, TabsetComponent} from 'ngx-bootstrap/tabs';
import {SwiperEvent, SwiperOptions} from 'swiper';
import {SwiperComponent} from 'ngx-useful-swiper';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import { Chart } from 'chart.js/auto';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import { registerables} from 'chart.js';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
Chart.register(...registerables);

const API_REST = environment.api_rest;
const URL_PDF_RECEIPT_ATTACHMENT = API_REST + '/receipts/anexes/';
@Component({
  selector: 'app-admin-home-general',
  templateUrl: './admin-home-general.component.html',
  styleUrls: ['./admin-home-general.component.css']
})
export class AdminHomeGeneralComponent implements OnInit, AfterViewInit {
  @ViewChild('swiperReceipt') private swiperReceipt: SwiperComponent;
  @ViewChild('swiperPolicy') private swiperPolicy: SwiperComponent;
  @ViewChild('lineChart', {static: false}) private chartRef: ElementRef;
  @ViewChild('pieChart', {static: false}) private pieChartRef: ElementRef;
  @ViewChild('pieChart1', {static: false}) private pieChart1Ref: ElementRef;
  @ViewChild('line1Chart', {static: false}) private line1ChartRef: ElementRef;
  @ViewChild('line2Chart', {static: false}) private line2ChartRef: ElementRef;
  @ViewChild('line3Chart', {static: false}) private line3ChartRef: ElementRef;
  @ViewChild('gestionTabs', {static: false}) staticTabs: TabsetComponent;
  @ViewChild('tabBranch') branchId: TabsetComponent;
  URL_PDF_RECEIPT_ATTACHMENT = URL_PDF_RECEIPT_ATTACHMENT;
  chart: any;
  pieChart: any;
  pieChart1: any;
  line1Chart: any;
  line2Chart: any;
  line3Chart: any;
  insuranceSelected: any = {};
  dataAccident: any = [];
  totalPopulations: any = [];
  lclSectionSelected: number;
  swiperReceiptsConf: SwiperOptions = {
    effect: 'coverflow',
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 7,
    height: 230,
    direction: 'vertical',
    mousewheel: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows : false,
    },
    init: false
  };
  swiperPoliciesConf: SwiperOptions = {
    effect: 'coverflow',
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0,
    height: 200,
    direction: 'vertical',
    mousewheel: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows : false,
    }
  };

  eventPage = 1;
  statisticsFilters: any = [];
  lclStatisticsFilterSelected: any = {};
  policyTypeList: any = [];
  policyTypeCollecionList: any = [];
  branches: any = [];
  showStatics = true;
  dataTypeFilters: any = [];
  branchIdSelected: any;
  staticsIncidentPolicyTypeSelected: any = {};
  dataTypeFilterSelected: any = {};
  staticsAccidentsPolicyTypeSelected: any = {};
  staticsInsuranceList = [];
  staticsCollectionPolicyTypeSelected: any = {};

  dataTypesList: any = [];
  lclDataTypeForReportsSelected: any = [];
  lclDataTypeForDebtorLifeSelected: any = [];
  lclDataTypeForRequestsSelected: any = [];
  eventsList: any = [];
  totalEventsRecords = 0;
  requestsList: any = [];
  requestsVehicleList: any = [];
  vehiclesList: any = [];
  incidentsList: any = [];
  insuredList: any = [];
  totalInsuredRecords = 0;
  objIncidentDetail: any = {};
  incidentAttachmentList: any = [];

  // Variables para recibos ->
  insuranceList: any = [];
  lclInsuranceIdSelected: number;
  receiptStatusList: any = [];
  lclReceiptStatusSelected: any = {};
  receiptsList: any = [];
  receiptDetail: any = {};
  receiptAttachmentsList: any = [];

  // Variables para reportes ->
  reportsTypesList: any = [];
  reportDataList: any = [];
  reportTblHeaders: any = [];
  lclReportSelected: number; // Almaccena el Id del Reporte selecccionado
  type: number;
  typeReport: number;

  // Variables para pólizas
  policiesTypesList: any = [];
  lclPolicyTypeSelected: any = {};
  policiesList: any = [];
  lclPolicySelected: any = {};
  policyDetail: any = {};
  policyCoverageList: any = [];
  policyAttachmentsList: any = [];

  // Variables para request
  totalRequestRecords = 0;
  requestPage = 1;

  // Variables para asegurados
  insuredPage = 1;
  totalCarRecords = 0;
  // Variables para autos
  carPage = 1;

  // Variables para vida deudor ->
  monthListHistory: any = [];
  debtorlifeDataListMonths: any = [];
  debtorlifeDataList: any = [];
  debtorlifeTblHeaders: any = [];
  lclDebtorLifeSelected: number; // Almaccena el Id del Reporte selecccionado
  typeDebtorLife: number;
  debtorlifeDataListFile: any = [];
  dataTypeFiltersDebtor: any = [];
  staticsCompanyTypeSelected: any = {};
  dataTypeDetorFilter: any = [];
  dataTypeFilterSelectedDebtorLife: any = {};
  statisticId: number;
  collectionList: any = [];
  filter: any = {};

  requestInfoList: any = [];
  modalRequestRef: BsModalRef;
  typeEvendId: any;
  modalReceiptRef: BsModalRef;
  isUpdatedForCustomer: boolean;

  /**
   * @constructor
   * @param router
   * @param api Servicio con las llamadas a la API Rest
   * @param shared Proporciona las variables/funciones globales
   * @param toastr Proporciona el acceso para lanzar notificaciones
   * @param modalService
   */
  constructor(private router: Router,
              private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService,
              private modalService: BsModalService) {



    /**
     * Observable para monitorear sí es necesario recargar la informacion de Eventos y Solicitudes
     */
    this.shared.fUpdateRequestsObservable().subscribe(
      fRequests => {
        if (fRequests) {
          this.eventPage = 1; // Reiniciamos el contador de paginas de eventos
          this.eventsList = []; // Reiniciamos la lista de eventos
          this.requestPage = 1;
          this.requestsList = [];
          this.invokeServiceForGetEventLogs();
          this.invokeServiceForGetRequestList();
          this.invokeServiceForGetVehicleRequestList();
          this.shared.fUpdateIncidents.next(false);
        }
      }
    );

    this.shared.gCurrentCustomerIdObservable().subscribe(
      gCustomerId => {
        if (gCustomerId !== -1) {
          console.log('reinicio');
          this.isUpdatedForCustomer = true;
          this.eventPage = 1; // Reiniciamos el contador de paginas de eventos
          this.eventsList = []; // Reiniciamos la lista de eventos
          this.insuredPage = 1;
          this.totalInsuredRecords = 0;
          this.insuredList = [];
          this.requestPage = 1;
          this.requestsList = [];
          this.totalRequestRecords = 0;
          this.vehiclesList = [];


          this.shared.fUpdateIncidents.next(false);
          /*setTimeout( () => {
            if (this.staticTabs !== undefined) {
                this.staticTabs.tabs[0].active = true;
            }
          }, 1000);*/
          setTimeout(() => {
            this.loadFilterStatics();
          }, 500);
          this.invokeBranchForTabs();
          this.invokeServiceForGetEventLogs();
          this.invokeServiceForGetInsuranceWithReceipts();
          this.invokeServiceForGetDataTypes();
          this.invokeServiceForGetMonthsDebtors();
          this.invokeServiceForGetPoliciesTypeList();
        }
      }
    );

    this.shared.gCurrentBranchIdObservable().subscribe(
      gbranchId => {
        if (gbranchId !== -1 && !this.isUpdatedForCustomer) {
          console.log('reinicio');
          this.eventPage = 1; // Reiniciamos el contador de paginas de eventos
          this.eventsList = []; // Reiniciamos la lista de eventos
          this.insuredPage = 1;
          this.totalInsuredRecords = 0;
          this.insuredList = [];
          this.requestPage = 1;
          this.requestsList = [];
          this.totalRequestRecords = 0;
          this.vehiclesList = [];
          this.shared.fUpdateIncidents.next(false);

          this.reportsTypesList = [];
          this.reportDataList = [];
          this.reportTblHeaders = [];
          this.lclReportSelected = 0; // Almaccena el Id del Reporte selecccionado
          this.type = 0;
          this.typeReport = 0;


          /*setTimeout( () => {
            if (this.staticTabs !== undefined) {
              this.staticTabs.tabs[0].active = true;
            }
          }, 1000);*/
          setTimeout(() => {
            this.loadFilterStatics();
          }, 500);
          this.invokeServiceForGetEventLogs();
          this.invokeServiceForGetInsuranceWithReceipts();
          this.invokeServiceForGetDataTypes();
          this.invokeServiceForGetMonthsDebtors();
          this.invokeServiceForGetPoliciesTypeList();
          this.branchIdSelected = gbranchId;
          localStorage.setItem('branchId', String(gbranchId));
        }
      }
    );
    /**
     * Observable para monitorear sí es necesario recargar la informacion de vida deudor
     */
    this.shared.fUpdateDebtorLifeObservable().subscribe(
      fDebtorlife => {
        if (fDebtorlife) {
          this.invokeServiceForGetMonthsDebtors();
          this.shared.fUpdateDebtorLife.next(false);
        }
      }
    );
  }

  ngOnInit() {
    this.branchIdSelected = 1;
    localStorage.removeItem('insuredView');

    this.dataTypeFilters = [
      {
        id: 1,
        name: 'Cantidad'
      },
      {
        id: 2,
        name: 'Montos'
      }
    ];
    this.dataTypeFilterSelected = this.dataTypeFilters[0];
    this.lclSectionSelected = 0;
    this.dataTypeDetorFilter = [
      {
        id: 1,
      name: 'UDI'
      },
      {
        id: 2,
        name: 'PRIMA'
      }
    ];

    this.dataTypeFilterSelectedDebtorLife = this.dataTypeDetorFilter[0];

  }

  ngAfterViewInit() {
    if (this.staticTabs.tabs.length !== 0) {
      this.staticTabs.tabs[0].active = true;
    }

    this.waitForTabBranchComponentLoad().then(() => {
      console.log('Child component is loaded.');
      if (this.branchId.tabs.length !== 0) {
        for (let i = 0; i < this.branchId.tabs.length; i++) {
          if (this.branchId.tabs[i].id === localStorage.getItem('branchId')) {
            this.branchId.tabs[i].active = true;
          }
        }
      }

    });

    this.staticTabs.tabs.sort();
  }

  waitForTabBranchComponentLoad(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.branchId) {
        resolve();
      } else {
        const intervalId = setInterval(() => {
          if (this.branchId) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100); // Check every 100ms if the child component is loaded
      }
    });
  }
  loadFilterStatics() {
    this.statisticId = 1;
    if (this.shared.gCurrentPolicyDebtorLife > 0 && this.shared.gCurrentPolicyBenefit == 0) {
        this.statisticsFilters = [
          {
            id: 5,
            description: 'Vida deudor'
          }
        ];
        this.statisticId = 0;
        this.lclSectionSelected = 3;
    } else if (this.shared.gCurrentPolicyDebtorLife > 0 && this.shared.gCurrentPolicyBenefit > 0) {
               this.statisticsFilters = [
                 {
                   id: 0,
                   description: this.branchIdSelected === 1 || this.branchIdSelected === '1' ? 'Siniestralidad de empleados' : 'Siniestralidad flotilla'
                 },
                 /*{
                   id: 1,
                   description: 'Estatus de usuarios'
                 },*/
                 {
                   id: 2,
                   description: 'Siniestralidad/Prima neta devengada'
                 },
                 {
                   id: 3,
                   description: 'Uso de póliza'
                 },
                 {
                   id: 5,
                   description: 'Vida deudor'
                 },
                 {
                   id: 6,
                   description: this.branchIdSelected === 1 || this.branchIdSelected === '1' ? 'Población total' : 'Parque Vehicular'
                 },
                 {
                   id: 7,
                   description: 'Estatus de cobranza'
                 }/*,
                 {
                   id: 4,
                   description: 'Ver todas'
                 }*/
               ];
   } else {
        this.statisticsFilters = [
          {
            id: 0,
            description: this.branchIdSelected === 1 || this.branchIdSelected === '1' ? 'Siniestralidad de empleados' : 'Siniestralidad flotilla'
          },
          /*{
            id: 1,
            description: 'Estatus de usuarios'
          },*/
          {
            id: 2,
            description: 'Siniestralidad/Prima neta devengada'
          },
          {
            id: 3,
            description: 'Uso de póliza'
          },
          {
            id: 6,
            description: this.branchIdSelected === 1 || this.branchIdSelected === '1' ? 'Población total' : 'Parque Vehicular'
          },
          {
            id: 7,
            description: 'Estatus de cobranza'
          }/*,
          {
            id: 4,
            description: 'Ver todas'
          },*/
        ];
    }
    this.lclStatisticsFilterSelected = this.statisticsFilters[this.statisticId];
    this.getStaticsPolicyType();
  }

  getStaticsPolicyType() {
    if (this.statisticId === 1) {
      this.api.getStaticsPolicyType().then((dataPolicyType: any) => {
        this.policyTypeList = dataPolicyType;
        if (this.policyTypeList !== null && this.policyTypeList.length > 0) {
          this.getChartAccidents(this.policyTypeList[0]);
        } else {
          this.staticsAccidentsPolicyTypeSelected = 'Sin tipo de póliza';
          this.showStatics = true;
          this.policyTypeList = [];
          if (this.line1Chart !== undefined) {
            this.line1Chart.destroy();
          }
        }
      });
    } else {
      this.getFilterDebtorLife();
    }
  }
  cleanList() {
    this.insuredPage = 1;
    this.totalInsuredRecords = 0;
    this.insuredList = [];
    this.requestPage = 1;
    this.requestsList = [];
    this.totalRequestRecords = 0;
    this.vehiclesList = [];
  }

  changeStatisticsFilter(statisticsFilter) {
    this.lclStatisticsFilterSelected = statisticsFilter;

    switch (statisticsFilter.id) {
      case 0: {
        if (this.policyTypeList !== null && this.policyTypeList.length > 0) {
          this.staticsIncidentPolicyTypeSelected = this.policyTypeList[0];
          this.getChartIncident(this.staticsIncidentPolicyTypeSelected);
        }
        break;
      }
      case 1: {
        this.getChartStatusUser();
        break;
      }
      case 2: {
        if (this.staticsAccidentsPolicyTypeSelected.typePolicyId === null ||
          this.staticsAccidentsPolicyTypeSelected.typePolicyId === undefined) {
          if (this.policyTypeList.length !== null && this.policyTypeList.length > 0) {
            this.getChartAccidents(this.policyTypeList[0]);
          }
        } else {
          this.getChartAccidents(this.staticsAccidentsPolicyTypeSelected);
        }
        break;
      }
      case 3: {
        this.getChartInsurancePolicy();
        break;
      }
      case 4: {
        this.router.navigate(['/admin/statics']);
        break;
      }
      case 5: {
        this.getFilterDebtorLife();
        // this.getCharDebtorLife('DEUTSCHE BANK COAC');
        break;
      }
      case 6: {
        this.getStaticsPopulation();
        break;
      }
      case 7: {
        this.getChartStatusCollection(this.policyTypeList[0]);
        break;
      }

    }

  }

  getChartStatusCollection(typePolicy) {

    if (this.pieChart1 !== undefined) {
      this.pieChart1.destroy();
    }

    this.api.getStaticsPolicyType().then((dataPolicyType: any) => {
      this.policyTypeCollecionList = dataPolicyType;
      this.staticsCollectionPolicyTypeSelected = this.policyTypeCollecionList[0];
      if (this.policyTypeCollecionList !== null && this.policyTypeCollecionList.length > 0) {
        // this.getChartIncident(this.policyTypeList[0]);
        this.api.getStatusCollection(typePolicy.typePolicyId).then((data: any) => {
          const labels = [];
          const dt = [];
          const bckgnd = [];
          for (const obj in data) {
            labels.push(data[obj].title);
            dt.push(data[obj].value);
            bckgnd.push('#1BA8B1');
          }

          this.pieChart1 = new Chart(this.pieChart1Ref.nativeElement, {
            type: 'pie',
            data: {
              datasets: [{
                data: dt,
                backgroundColor: [
                  'rgba(59, 187, 172,0.79)',
                  'rgba(89, 89, 89, 1)',
                  'rgba(33, 185, 235,1)',
                  'rgba(1, 24, 38, 1)',
                ]
              }],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels
            },
            options: {
              maintainAspectRatio: false,
            }
          });
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        });
      } else {
        this.staticsAccidentsPolicyTypeSelected = 'Sin tipo de póliza';
        this.showStatics = true;
        this.policyTypeList = [];
        if (this.line1Chart !== undefined) {
          this.line1Chart.clear();
          this.line1Chart.destroy();
        }
      }
    });
  }

  getChartStatusUser() {
    this.api.getStaticsUser().then((data: any) => {

      // console.log(data);
      const labels = [];
      const dt = [];
      const bckgnd = [];
      for (const obj in data) {
        labels.push(data[obj].title);
        dt.push(data[obj].value);
        bckgnd.push('#1BA8B1');

      }

      this.pieChart = new Chart(this.pieChartRef.nativeElement, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: dt,
            backgroundColor: [
              '#244652',
              '#1BA8B1',
              '#21B9EB',
              '#244652'
            ]
          }],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels
        },
        options: {
          maintainAspectRatio: false,
        }
      });
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  changeBranch(branchid) {
    this.isUpdatedForCustomer = false;
    this.shared.gCurrentBranchId.next(branchid.id);
  }

  changePolicyTypeStaticsIncident(policyType) {
    this.getChartIncident(policyType);
  }

  changePolicyTypeStaticsAccidents(policyType) {
    this.getChartAccidents(policyType);
  }

  changePolicyTypeStaticsCollection(policyType) {
    this.getChartStatusCollection(policyType);
  }

  changeCompanyTypeStaticsDebtorLife(company) {
    this.staticsCompanyTypeSelected = company;
    this.getCharDebtorLife(company);
  }

  public changeDataTypeFilter(dataType) {
    this.dataTypeFilterSelected = dataType;
    this.getChartIncident(this.staticsIncidentPolicyTypeSelected);
  }

  public changeDataTypeFilterDebtor(dataType) {
    this.dataTypeFilterSelectedDebtorLife = dataType;
    this.getCharDebtorLife(this.staticsCompanyTypeSelected);
  }

  getChartIncident(policyType) {
    this.staticsIncidentPolicyTypeSelected = policyType;
    this.api.getStaticsIncident(policyType.typePolicyId, this.dataTypeFilterSelected.id).then((data: any) => {

      if (data == null) {
        this.showStatics = true;
        return false;
      }
      if (this.chart !== undefined) {
        this.chart.destroy();
      }
      this.showStatics = false;
      const labels = [];
      const dt = [];
      const bckgnd = [];
      for (const obj in data) {
        labels.push(data[obj].incidentType);
        dt.push(data[obj].number);
        bckgnd.push('#1BA8B1');

      }

      let labelName = '';
      switch (this.dataTypeFilterSelected.id) {
        case 1:
          labelName = 'Casos';
          break;
        case 2:
          labelName = 'Monto';
          break;
      }
      this.chart = new Chart(this.chartRef.nativeElement, {
        type: 'bar',
        data: {
          labels,
          datasets: [{
            label: labelName,
            data: dt,
            backgroundColor: bckgnd,
            barPercentage: 0.1,
          }]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
                beginAtZero: true
            },
            x: {
              grid: {
                display: false
              }
            }
          },
          animation: {
            duration: 1000,
            delay: 0,
            loop: false
          },
          // responsiveAnimationDuration: true,
          responsive: true
        }
      });


    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }


  getChartAccidents(policyType) {
    this.staticsAccidentsPolicyTypeSelected = policyType;
    this.api.getStaticsAccidents(policyType.typePolicyId).then((data: any) => {
      if (data == null) {
        this.showStatics = true;
        return false;
      }

      this.showStatics = false;

      // console.log(data);
      const labels = [];
      const dt = [];
      const labelsC = [];
      const dtC = [];
      const bckgnd = [];
      for (const obj in data.incidents) {
        labels.push(data.incidents[obj].month);
        dt.push(data.incidents[obj].premium);
      }

      for (const obj in data.colletions) {
        labelsC.push(data.colletions[obj].month);
        dtC.push(data.colletions[obj].premium);
      }

      setTimeout(() => {
        if (this.line1Chart !== undefined) {
          this.line1Chart.render();
          this.line1Chart.destroy();
        }
        this.line1Chart = new Chart(this.line1ChartRef.nativeElement, {
        type: 'line',
        data: {
          labels, // your labels array
          datasets: [
            {
              label: 'Siniestralidad',
              data: dt, // your data array
              borderColor: '#244652',
              fill: false
            },
            {
              label: 'Prima neta devengada',
              data: dtC, // your data array
              borderColor: '#1BA8B1',
              fill: false
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            x: {
              display: true
            },
            y: {
              display: true
            },
          }
        }
      });
      }, 1000);


    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  selectInsurance(policyType) {
    this.insuranceSelected = policyType; // Se le envía el id del ramo seleccionado

    this.api.getStaticsInsurancePremium(policyType.typePolicyId).then((data: any) => {

      if (data == null) {
        this.showStatics = true;
        return false;
      }
      this.showStatics = false;

      // console.log(data);
      // console.log('*******************');
      const labels = [];
      const dt = [];
      const bckgnd = [];
      for (const obj in data) {
        labels.push(data[obj].month);
        dt.push(data[obj].premium);
        bckgnd.push('#1BA8B1');

      }

      this.line2Chart = new Chart(this.line2ChartRef.nativeElement, {
        type: 'line',
        data: {
          labels, // your labels array
          datasets: [
            {
              data: dt, // your data array
              borderColor: '#244652',
              fill: true
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
          },
          scales: {
            x: {
              display: true
            },
            y: {
              display: true
            },
          }
        }
      });

    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });


  }


  getChartInsurancePolicy() {

    this.api.getStaticsInsurance().then((dataI: any) => {

      // console.log(dataI);
      let list = [];
      list = dataI;

      this.staticsInsuranceList = dataI;

      if (list.length > 0 ) {

        this.insuranceSelected = dataI[0];


        this.api.getStaticsInsurancePremium(list[0].insuranceId).then((data: any) => {

          if (data == null) {
            this.showStatics = true;
            return false;
          }
          this.showStatics = false;


          // console.log(data);
          // console.log('*******************');
          const labels = [];
          const dt = [];
          const bckgnd = [];
          for (const obj in data) {
            labels.push(data[obj].month);
            dt.push(data[obj].premium);
            bckgnd.push('#1BA8B1');

          }

          this.line2Chart = new Chart(this.line2ChartRef.nativeElement, {
            type: 'line',
            data: {
              labels, // your labels array
              datasets: [
                {
                  data: dt, // your data array
                  borderColor: '#244652',
                  fill: true
                }
              ]
            },
            options: {
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
              },
              scales: {
                x: {
                  display: true
                },
                y: {
                  display: true
                },
              }
            }
          });

        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        });


      }

    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  getFilterDebtorLife() {
    this.api.getStaticsDebtorLifeType().then( (data: any) => {
      this.dataTypeFiltersDebtor = data;
      if (this.dataTypeFiltersDebtor !== null) {
        this.staticsCompanyTypeSelected = this.dataTypeFiltersDebtor[0];
        this.getCharDebtorLife(this.dataTypeFiltersDebtor[0]);
      }
    });
  }
  getCharDebtorLife(company) {
    // this.staticsCompanyTypeSelected = company;
    this.api.getStaticsDebtorLife(company, this.dataTypeFilterSelectedDebtorLife.id).then((data: any) => {

      if (data == null) {
        this.showStatics = true;
        return false;
      }
      if (this.line3Chart !== undefined) {
        this.line3Chart.destroy();
      }
      this.showStatics = false;
      const labels = [];
      const dt = [];
      const bckgnd = [];
      for (const obj in data) {
        labels.push(data[obj].monthName);
        dt.push(data[obj].total);
        bckgnd.push('#1BA8B1');

      }

      let labelName = '';
      switch (this.dataTypeFilterSelectedDebtorLife.id) {
        case 1:
          labelName = 'UDI Pagada';
          break;
        case 2:
          labelName = 'Prima neta acumulada ';
          break;
      }
      this.line3Chart = new Chart(this.line3ChartRef.nativeElement, {
        type: 'bar',
        data: {
          labels,
          datasets: [{
            label: labelName,
            data: dt,
            backgroundColor: bckgnd,
            barPercentage: 0.1,
          }]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
                beginAtZero: true
            },
            x: {
              grid: {
                display: false
              }
            }
          },
          // responsiveAnimationDuration: true,
          animation: {
            duration: 1000,
            delay: 0,
            loop: false
          },
          responsive: true
        }
      });
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  getStaticsPopulation() {
    this.api.getStaticsPopulations()
      .then(
        (response: any) => {
          if (response != null) {
            this.showStatics = false;
            this.totalPopulations = response;
            console.log(this.totalPopulations);
          }
        }, error => {
          console.info(error);
        });
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |------------------------------------------|  E V E N T O S  |------------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  /**
   * Método para obtener una lista de Eventos
   * la lista se obtiene a través del servicio REST @getEventLog
   * se indican los parametros de paginado Número de Pagina, Tamaño del pagiinado
   *
   * 1.- Invocamos el servicio rest y recuperamos la respuesta en nuestra variable @eventsList
   */
  invokeServiceForGetEventLogs() {
    this.api.getEventLog(this.eventPage, 5)
      .then(
        (response: any) => {
          if (response != null) {
          this.totalEventsRecords = response.total;
          this.eventsList = this.eventsList.concat(response.eventAdminList);
          }
      }, error => {
        console.info(error);
      });
  }

  onScroll() {
    if (this.totalEventsRecords === this.eventsList.length) {
      return;
    }
    this.eventPage++;
    this.invokeServiceForGetEventLogs();
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |------------------------------------------|  R E C I B O S  |------------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  /**
   * Método para invocar los datos correspondientes al Tab(pestaña) de Recibos seleccionado
   *
   * 1.- Seteamos el Id de la Compañía correspondiente al Tab a la variable local @lclInsuranceIdSelected
   * 2.- Creamos un timeout para permitir el renderizado correcto del elemento Swiper
   * 3.- Seteamos el primer objeto de la lista de Status de Recibos(@receiptStatusList) a la variable locacl @lclReceiptStatusSelected
   * 4.- Invocamos el método que recupera la lista de recibos correspondientes a la Compañía seleccionada(Tab seleccionado)
   *
   * @param data Propiedades del Tab seleccionado
   */
  onReceiptTabSelect(data: TabDirective): void {
    this.lclInsuranceIdSelected = Number(data.id);
    setTimeout( () => {
      this.swiperReceipt.swiper.on('slideChange', () => {
        this.swiperReceiptEventHandler();
      });
    }, 500);
    this.lclReceiptStatusSelected = this.receiptStatusList[0];
    this.invokeServiceForGetInsuranceReceiptsList();
  }

  /**
   * Método para obtener una lista de recibos correspondientes a una compañía
   * la lista se obtiene a través del servicio REST @getInsuranceWithReceipts
   *
   * 1.- Invocamos el servicio rest y recuperamos la respuesta en nuestra variable @insuranceList
   * 2.- Seteamos el Id de la primer compañía de la lista a nuestra variable local @lclInsuranceIdSelected
   * 3.- Invocamos el método que recupera la lista de Estatus de Recibos
   */
  invokeServiceForGetInsuranceWithReceipts() {
    this.api.getInsuranceWithReceipts()
      .then(
        (response) => {
          if (response !== null) {
            this.insuranceList = response;
            this.lclInsuranceIdSelected = this.insuranceList[0].insuranceId;
            this.invokeServiceForGetReceiptStatusList();
          }
        }, error => {
          console.error(error);
    });
  }
  invokeServiceForGetReceiptStatusList() {
    this.api.getReceiptStatusList()
      .then(
        (response) => {
          this.receiptStatusList = response;
          this.changeLclReceiptStatus(this.receiptStatusList[0]);
        }, error => {
          console.error(error);
        }
      );
  }
  changeLclReceiptStatus(receiptStatus) {
    this.lclReceiptStatusSelected = receiptStatus;
    this.invokeServiceForGetReceiptList();
  }
  invokeServiceForGetInsuranceReceiptsList() {
    this.api.getInsuranceReceiptsList(this.lclReceiptStatusSelected.statusId, this.lclInsuranceIdSelected, 1, 10)
      .then((response) => {
        if (response !== null && response !== undefined) {
          const oResponse: any = response;
          this.receiptsList = oResponse.receiptList;
        } else {
          this.receiptsList = [];
          this.receiptDetail = [];
        }


        /*this.swiperReceipt.swiper.init();
        this.swiperReceipt.swiper.on('slideChange', () => {
          this.swiperReceiptEventHandler();
        });
        this.invokeServiceForGetAttachmentsByReceipt(this.receiptDetail.receiptId);*/
      }, error => {
        console.error(error);
      });
  }
  invokeServiceForGetAttachmentsByReceipt(template: TemplateRef<any>, receiptId) {
    this.api.getReceiptAttachment(receiptId)
      .then(
        (response) => {
          const oResponse: any = response;
          if (oResponse === null) {
            this.toastr.warning('No se encuentra ningún archivo anexado al recibo', 'Notificación');
          } else {
            this.modalRequestRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
            this.receiptAttachmentsList = oResponse;
            this.modalRequestRef.onHidden.subscribe(() => {
              this.receiptAttachmentsList = [];
            });
          }
        }, error => {
            console.error(error);
        });
  }
  swiperReceiptEventHandler() {
    this.receiptDetail = this.receiptsList[this.swiperReceipt.swiper.activeIndex];
    // this.invokeServiceForGetAttachmentsByReceipt(this.receiptDetail.receiptId);
  }
  invokeServiceForDownloadAttachmentFile(attached) {
    this.api.getAttachmentFile(attached.annexId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          if (attached.name) {
            downloadLink.setAttribute('download', attached.name);
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        });
  }
  invokeServiceForDownloadReceiptAttachmentZipFile() {
    this.api.getReceiptAttachmentZipFile(this.receiptDetail.receiptId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', `recibos_poliza_${this.receiptDetail.numberPolicy}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        });
  }

  public invokeServiceForDownloadFileReceipt() {
    this.api.getFileReceipt()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'recibos.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }


  /*+---------------------------------------------------------------------------------------------------------------------+
    |----------------------------------------------+~~~~~~~~~~~~~~~~~~~~~~~+----------------------------------------------|
    |---------------------------------------------<|  G  E  S  T  I  O  N  |>---------------------------------------------|
    |----------------------------------------------+~~~~~~~~~~~~~~~~~~~~~~~+----------------------------------------------|
    +---------------------------------------------------------------------------------------------------------------------+*/

  onManagementTabSelect(data: TabDirective): void {
    this.cleanList();
    this.lclSectionSelected = Number(data.id);

    switch (this.lclSectionSelected) {
      case 0:
        // console.info('ENTRO AQUI');
        this.invokeServiceForGetReportsType();
        break;
      case 1:
        this.invokeServiceForGetRequestList();
        this.invokeServiceForGetVehicleRequestList();
        break;
      case 2:
        this.invokeServiceForGetIncidentsList();
        break;
      case 3:
        this.invokeServiceForGetPoliciesTypeList();
        setTimeout( () => {
          this.swiperPolicy.swiper.on('slideChange', () => {
            this.swiperPolicyEventHandler();
          });
        }, 500);
        break;
      case 4:
        this.invokeServiceForGetInsuredList();
        break;
      case 5:
        // this.invokeServiceForGetVehicleRequestList(); <- Este ver como se acommoda
        this.invokeServiceForGetVehiclesList();
        break;
      case 6:
        this.invokeServiceForGetMonthsDebtors();
        break;
      case 7:
        this.invokeServiceForGetReceiptStatusList();
        break;
    }
  }
  maximizeSection() {
    switch (this.lclSectionSelected) {
      case 0:
        this.router.navigate(['/admin/reports']);
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        this.router.navigate(['/admin/policies']);
        break;
      case 4:
        if (this.insuredList.length > 0) {
          this.shared.fInsuredDataReadonly = true;
          localStorage.setItem('insuredView', JSON.stringify(this.insuredList[0]));
          this.router.navigate(['/admin/insured']);
        }
        break;
    }
  }

  /**
   * Método para recuperar los tipos de datos que se van a mostrar
   * 1 = Beneficios
   * 2 = Autos
   * 3 = Otros
   */
  private invokeServiceForGetDataTypes() {
    this.dataTypesList = [];
    this.api.getDataTypes().subscribe(response => {
      this.dataTypesList = response;
      for (const item of this.dataTypesList) {
        if (item.enabled) {
          this.lclDataTypeForReportsSelected = item;
          this.lclDataTypeForRequestsSelected = item;
          this.invokeServiceForGetReportsType();
          break;
        }
      }
    }, error => {
      console.error(error);
    });
  }



  /*+-------------------------------------------------------------------------------------------------------+
    |-----------------------------------------|  R E P O R T E S  |-----------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  /**
   * Método para invocar los datos correspondientes al seleccionar un reporte en específico
   * 1.- Seteamos el identificador de reporte a la variable local :lclReportSelected
   * 2.- Invocamos el método que recupera la información del reporte
   *
   * @param report objeto Reporte seleccionado
   */
  public onReportItemSelect(report) {
    this.lclReportSelected = report.reportId;
    this.type = report.type;
    this.typeReport = report.typeReport;
    this.invokeServiceForGetReportDataByReportId();
  }

  public changeLclDataTypeReport(dataTypeSelected) {
    this.lclDataTypeForReportsSelected = dataTypeSelected;
    this.invokeServiceForGetReportsType();
  }

  /**
   * Método para obtener la lista de Tipos de Reportes
   *
   * 1.- Invocamos el servicio REST @getReportsType y almacenamos la respuesta en la variable de tipo array :reportsTypesList
   * 2.- Cambiamos el formato de la propiedad :reportDate de Strinng a Date
   * 3.- Llamamos el método @onReportItemSelect y le pasamos como parametro el Id de Repoorte correspondiente al primer elemento del arreglo
   */
  private invokeServiceForGetReportsType() {
    this.api.getReportsType(this.lclDataTypeForReportsSelected.requestTypeId)
      .subscribe(
        (response: any) => {
          if (response !== null && response !== undefined && response.length > 0) {
            this.reportsTypesList = response;
            this.reportsTypesList.map(x => x.reportDate = new Date(x.reportDate.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')));
            this.onReportItemSelect(this.reportsTypesList[0]);
          } else {
            this.reportsTypesList = [];
            this.reportDataList = [];
          }
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Método para obtener la información correspondiente al reporte especificado por la variable :lclReportSelected
   *
   * 1.- Invocamos el servicio REST getReportDataByReportId y almacenamos la respuesta en la variable de tipo array :reportDataList
   */
  private invokeServiceForGetReportDataByReportId() {
    this.api.getReportDataByReportId(this.lclReportSelected)
      .subscribe(response => {
        this.reportDataList = response;
      },  error => {
        console.error(error);
      }
    );
  }

  /**
   * Método para recuperar el archivo correspondiente al reporte
   *
   * 1.- Invocamos el servicio REST @getReportFile el cual retorna una respuesta de tipo arraybuffer
   * 2.- Generamos un elemento 'a' en el DOM para asignarle como 'href' nuestro archivo comvertido en binario
   *     y posteriormente hacer un click automático para descargar el archivo
   * 3.- Removemos el elemento 'a' del DOM
   *
   * @param reportId Id del reporte seleccionado
   * @param name Nombre del archivo a descargar
   */
  invokeServiceForDownloadReportFile(reportId, name) {
    this.api.getReportFile(reportId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |---------------------------|  S O L I C I T U D E S   B E N E F I C I O S  |---------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  public changeLclDataTypeRequest(dataTypeSelected) {
    this.lclDataTypeForRequestsSelected = dataTypeSelected;
    switch (this.lclDataTypeForRequestsSelected.requestTypeId) {
      case 1:
        this.invokeServiceForGetRequestList();
        break;
      case 2:
        this.invokeServiceForGetVehicleRequestList();
        break;
      case 3:
        break;
    }
  }

  invokeServiceForGetRequestList() {
    this.api.getRequests(this.requestPage, 10)
      .subscribe(
        (response: any) => {
          if (response.total > 0) {
            this.totalRequestRecords = response.total;
            this.requestsList = this.requestsList.concat(response.requestAdminList);
          }
          // this.requestsList = response.requestAdminList;
        }, error => {
          console.error(error);
        });
  }
  public invokeServiceForDownloadFileRequest() {
    this.api.getFileRequest()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'solicitudes.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  onScrollRequet() {
    if (this.totalRequestRecords === this.requestsList.length) {
      return;
    }
    this.requestPage++;
    this.invokeServiceForGetRequestList();
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |----------------------------|  S O L I C I T U D E S   V E H I C U L O S  |----------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  invokeServiceForGetVehicleRequestList() {
    this.api.getVehicleRequests(1, 10)
      .subscribe(
        (response: any) => {
          // requestId
          this.requestsVehicleList = response.requestCarList;
        }, error => {
          console.error(error);
        });
  }

  public invokeServiceForDownloadFileVehicleRequest() {
    this.api.getFileVehicleRequest()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'solicitudes.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |----------------------------------------|  V E H I C U L O S  |----------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  private invokeServiceForGetVehiclesList() {
    this.api.getVehiclesList(this.carPage, 10)
      .subscribe(
        (response: any) => {
          this.totalCarRecords = response.total;
          if (response.total > 0) {
            this.totalCarRecords = response.total;
            this.vehiclesList = this.vehiclesList.concat(response.carList);
          }
        }, error => {
          console.error(error);
        });
  }

  public invokeServiceForDownloadFileVehicles() {
    this.api.getFileVehicle()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'Vehiculos.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Descarga el PDF de la póliza auto
   */
  public invokeServiceForDownloadPolicyFileAuto(a) {
    const car = {
      carId: a.carId
    };
    this.api.getCarCertificate(car)
      .subscribe(
        (response: any) => {
          if (response !== null) {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', a.policy + '.pdf');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          } else {
            this.toastr.info('No se encuentra el certificado, por favor contacta a tu ejecutivo Surex');
          }
        }, error => {
          console.error(error);
        }
      );
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |---------------------------------------|  S I N I E S T R O S  |---------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  invokeServiceForGetIncidentsList() {
    this.api.getIncidentsList(1, 10)
      .then(
        (response) => {
          // incidentId
          const oResponse: any = response;
          this.incidentsList = oResponse.incidentAdminList;
          if (this.incidentsList.length > 0) {
            this.invokeServiceForGetIncidentDetail(this.incidentsList[0].incidentId);
          } else {
            this.objIncidentDetail = {};
            this.incidentAttachmentList = [];
          }
        }, error => {
          console.error(error);
        });
  }
  invokeServiceForGetIncidentDetail(incidentId) {
    this.api.getDetailIncident(incidentId)
      .then((response) => {
        this.objIncidentDetail = response;
        console.info(this.objIncidentDetail);
      }, error => {
        console.error(error);
      });

    this.api.getIncidentAttachments(incidentId)
      .then(
        (response) => {
          this.incidentAttachmentList = response;
        }, error => {
          console.error(error);
        });
  }
  invokeServiceForDownloadAnnexFile(annex) {
    this.api.getIncidentAnnexFile(annex.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          if (annex.name) {
            downloadLink.setAttribute('download', annex.name);
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        });
  }
  displayIncidentDetail(incidentId) {
    this.shared.fOpenSidebar(5);
    this.shared.gIncidentId.next(incidentId);
  }
  public invokeServiceForDownloadFileIncident() {
    this.api.getFileIncident()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'Siniestros.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |------------------------------------------|  P O L I Z A S  |------------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  invokeServiceForGetPoliciesTypeList() {
    this.api.getTypePolicies()
      .then(
        (response) => {
          if (response !== null) {
            this.policiesTypesList = response;
            this.lclPolicyTypeSelected = this.policiesTypesList[0];
            this.invokeServiceForGetPoliciesList();
          }
        }, error => {
          console.error(error);
        }
      );
  }
  changeLclPolicyTypeSelected(policyType) {
    this.lclPolicyTypeSelected = policyType;
    this.invokeServiceForGetPoliciesList();
  }
  invokeServiceForGetPoliciesList() {
    this.api.getUserPoliciesType(this.lclPolicyTypeSelected.typeId)
      .then(
        (response) => {
          if (response !== null) {
            this.policiesList = response;
            this.policiesList = this.policiesList.slice(0, 50);
            this.lclPolicySelected = this.policiesList[0];
            this.invokeServiceForGetPolicyDetail(this.policiesList[0].policyId);
            this.invokeServiceForGetPolicyMoreDetail(this.policiesList[0].policyId);
            this.invokeServiceForGetPolicyAttatchment(this.policiesList[0].policyId);
          }
        }, error => {
          console.error(error);
        }
      );
  }
  swiperPolicyEventHandler() {
    this.lclPolicySelected = this.policiesList[this.swiperPolicy.swiper.activeIndex];
    this.invokeServiceForGetPolicyDetail(this.lclPolicySelected.policyId);
    this.invokeServiceForGetPolicyMoreDetail(this.lclPolicySelected.policyId);
    this.invokeServiceForGetPolicyAttatchment(this.lclPolicySelected.policyId);
  }
  invokeServiceForGetPolicyDetail(policyId) {
    this.api.getDetailPolicy(policyId)
      .then(
        (response) => {
          this.policyDetail = response;
        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceForGetPolicyMoreDetail(policyId) {
    this.api.getDetailPolicyjson(policyId)
      .then(
        (response) => {
          this.policyCoverageList = response;
          this.policyCoverageList = this.policyCoverageList.slice(0, 9);
        }, error => {
          this.policyCoverageList = [];
          console.error(error);
        }
      );
  }
  invokeServiceForGetPolicyAttatchment(policyId) {
    this.api.getAttachmentlPolicy(policyId)
      .then(
        (response) => {
          this.policyAttachmentsList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Descarga el PDF de la póliza
   */
  public invokeServiceForDownloadPolicyFile() {
    this.api.getPolicyFile(this.lclPolicySelected.policyId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', this.lclPolicySelected.description + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Descarga la información de la póliza en csv
   */
  public invokeServiceForDownloadFilePolicy() {
    this.api.getFilePolicy()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'Polizas.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  public invokeServiceForDownloadPolicyAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  public invokeServiceForDownloadPolicyAttachmentZipFile(policy) {
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  /*+-------------------------------------------------------------------------------------------------------+
    |---------------------------------------|  A S E G U R A D O S  |---------------------------------------|
    +-------------------------------------------------------------------------------------------------------+*/

  /**
   * Método para obtener una lista de Asegurados
   * la lista se obtiene a través del servicio REST @getInsuredList
   * se indican los parametros de paginado Número de Pagina, Tamaño del pagiinado
   *
   * 1.- Invocamos el servicio rest y recuperamos la respuesta en nuestra variable @insuredList
   */
  invokeServiceForGetInsuredList() {
    this.api.getInsuredList(this.insuredPage, 10)
      .subscribe(
        (response: any) => {
          if (response.total > 0) {
            this.totalInsuredRecords = response.total;
            this.insuredList = this.insuredList.concat(response.insuredList);
          }
        }, error => {
          console.error(error);
        });
  }

  public invokeServiceForDownloadFileInsured() {
    this.api.getFileInsured()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'Asegurados.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  onScrollInsured() {
    if (this.totalInsuredRecords === this.insuredList.length) {
      return;
    }
    this.insuredPage++;
    this.invokeServiceForGetInsuredList();
  }

  onScrollcar() {
    if (this.totalCarRecords === this.vehiclesList.length) {
      return;
    }
    this.carPage++;
    this.invokeServiceForGetVehiclesList();
  }

  public viewLikeInsured(insured) {
    this.shared.fInsuredDataReadonly = true;
    localStorage.setItem('insuredView', JSON.stringify(insured));
    this.router.navigate(['/admin/insured']);
  }

  displayRequestDetail(requestId) {
    this.shared.fOpenSidebar(6);
    this.shared.gRequestId = new BehaviorSubject<number>(-1);
    this.shared.gRequestId.next(requestId);
  }

  /*-----------------------------------------------------------------------------------*/
  public hexToRgb(hex, opacity) {
    const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));

    return `rgba(${rgb.toString()}, ${opacity})`;
  }

  /*+-----------------------------------------------------------------------------------------------------------+
    |-----------------------------------------|  V I D A D E U D O R  |-----------------------------------------|
    +-----------------------------------------------------------------------------------------------------------+*/

  /**
   * Método para invocar los datos correspondientes al seleccionar un archivo en específico
   * 1.- Seteamos el identificador del archivo a la variable local :lclDebtorLifeSelected
   * 2.- Invocamos el método que recupera la información del archivo @invokeServiceForGetFileDataByDebtorLifeId
   *
   * @param report objeto Reporte seleccionado
   */
  public onFileItemSelect(debtorlife) {
    this.lclDebtorLifeSelected = debtorlife.debtorLifeId;
    this.typeDebtorLife = debtorlife.typeFile;
    this.invokeServiceForGetFileDataByDebtorLifeId();
  }

  public changeLclDataTypeFile(dataTypeSelected) {
    // console.log(dataTypeSelected);
    this.lclDataTypeForDebtorLifeSelected = dataTypeSelected;
    this.invokeServiceForGetFilesDebtors(dataTypeSelected);
  }

  /**
   * Método para obtener la lista de meses historial de archivos
   *
   * 1.- Invocamos el servicio REST @getMonthsdebtors y almacenamos la respuesta en la variable de tipo array :debtorlifeDataListMonths
   * 2.- Llamamos el método @invokeServiceForGetFilesDebtors para listar los archivos del mes seleccionado
   */
  private invokeServiceForGetMonthsDebtors() {
    this.api.getMonthsdebtors(1)
      .subscribe(
        (response: any) => {
          if (response !== null && response !== undefined && response.length > 0) {
            this.debtorlifeDataListMonths = response;
            for (const item of this.debtorlifeDataListMonths) {
                this.lclDataTypeForDebtorLifeSelected = item;
            }
            this.invokeServiceForGetFilesDebtors(this.lclDataTypeForDebtorLifeSelected);
          } else {
            this.debtorlifeDataListMonths = [];
          }
        }, error => {
          console.error(error);
        }
      );
  }

  /****
   * Método para obtener la información a desplegar en la tabla.
   * @param months
   */
  private invokeServiceForGetFilesDebtors(months) {
    this.api.getFilesdebtors(months)
      .subscribe(
        (response: any) => {
          if (response !== null && response !== undefined && response.length > 0) {
            this.debtorlifeDataList = response;
            this.onFileItemSelect(this.debtorlifeDataList[0]);
          } else {
            this.debtorlifeDataList = [];
          }
        }, error => {
          console.error(error);
        }
      );
  }

  /****
   * Método para obtener la información correspondiente al archivo especificado por la variable :lclDebtorLifeSelected
   * 1.- Invocamos el servicio REST getDebtorLifeDataByDebtorLifeId
   * y almacenamos la respuesta en la variable de tipo array :debtorlifeDataListFile
   */
  private invokeServiceForGetFileDataByDebtorLifeId() {
    this.api.getDebtorLifeDataByDebtorLifeId(this.lclDebtorLifeSelected)
      .subscribe(response => {
          this.debtorlifeDataListFile = response;
        },  error => {
          console.error(error);
        }
      );
  }
  /**
   * Método para recuperar el archivo correspondiente al archivo
   *
   * 1.- Invocamos el servicio REST @getDebtoLifeFile el cual retorna una respuesta de tipo arraybuffer
   * 2.- Generamos un elemento 'a' en el DOM para asignarle como 'href' nuestro archivo comvertido en binario
   *     y posteriormente hacer un click automático para descargar el archivo
   * 3.- Removemos el elemento 'a' del DOM
   *
   * @param DebtorlifeId Id del archivo seleccionado
   * @param name Nombre del archivo a descargar
   */
  invokeServiceForDownloadDebtorLifeFile(DebtorlifeId, name) {
    this.api.getDebtoLifeFile(DebtorlifeId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', name + '_' + this.lclDataTypeForDebtorLifeSelected.monthName + '.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceForAuthorizeFile(fileDebtorLifeId) {
    this.shared.gFileDebtorLifeId = fileDebtorLifeId;
    this.shared.fOpenSidebar(14);
  }

  invokeBranchForTabs() {
    this.api.getBranchsByClient().subscribe(
      (response: any) => {
        this.branches = response;

        if (this.branches !== undefined && this.branches.length !== 0) {
          this.branchIdSelected = localStorage.getItem('branchId');
        }

      }, error => {
        this.toastr.info('Sin pólizas activas');
      }
    );
  }

  isActive(nose:any): boolean {
   if (this.branchIdSelected === nose.toString()) {
     return true;
   } else {
     return false;
   }
  }

  openModalRequestMov(template: TemplateRef<any>, requestId, typeeventId) {
    this.typeEvendId = typeeventId;
    this.modalRequestRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});

    this.api.getRequestByIdrequest(requestId).subscribe(
      (response: any) => {
        this.requestInfoList = response;
      }, error => {
        this.toastr.info('Sin pólizas activas');
      }
    );

    this.modalRequestRef.onHidden.subscribe(() => {
      this.requestInfoList = [];
    });
  }

  /*+-----------------------------------------------------------------------------------------------------------+
   |-----------------------------------------|  RECIBOS  |-----------------------------------------|
   +-----------------------------------------------------------------------------------------------------------+*/
  openModalReceiptAttachmentsMov(template: TemplateRef<any>, receiptId) {
    this.receiptDetail.receiptId = receiptId;
    this.invokeServiceForGetAttachmentsByReceipt(template, receiptId);

  }
  invokeServiceForGetReceiptList() {
    const filter = {
      status: this.lclReceiptStatusSelected.statusId
    };
    this.api.getReceptisByBranchid(filter)
      .subscribe(
        (response: any) => {
          // requestId
          this.collectionList = response;
        }, error => {
          console.error(error);
        });
  }

}
