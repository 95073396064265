import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Chart } from 'chart.js';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {SharedService} from '../shared-service.service';

@Component({
  selector: 'app-admin-statics',
  templateUrl: './admin-statics.component.html',
  styleUrls: ['./admin-statics.component.css']
})
export class AdminStaticsComponent implements OnInit {

  // insuranceList = [];
  // insuranceSelected: any = {};

  data = [{
    x: 1,
    y: 10
  }, {
    x: 2,
    y: 20
  }];

  dataTypeFilters: any = [];

  @ViewChild('lineChart') private chartRef;
  @ViewChild('pieChart', {static: false}) private pieChartRef: ElementRef;
  @ViewChild('line1Chart') private line1ChartRef;
  @ViewChild('line2Chart') private line2ChartRef;
  @ViewChild('line3Chart') private line3ChartRef;
  chart: any;
  pieChart: any;
  line1Chart: any;
  line2Chart: any;
  line3Chart: any;
  policyTypeList = [];
  staticsAccidentsPolicyTypeSelected: any = {};
  staticsIncidentPolicyTypeSelected: any = {};
  dataTypeFilterSelected: any = {};
  showStaticsAccidents = true;
  showStaticsAccidentsNoData = false;
  showStaticsIncident = true;
  showStaticsInsurancePremium = true;
  // variables vida deudor
  dataTypeDetorFilter: any = [];
  dataTypeFilterSelectedDebtorLife: any = {};
  dataTypeFiltersDebtor: any = [];
  staticsCompanyTypeSelected: any = {};
  showStaticsDebtorlife = true;
  debtorLife: number;
  totalPopulations: any = [];
  branchIdSelected: any;
  staticsCollectionPolicyTypeSelected: any = {};
  policyTypeCollecionList: any = [];
  constructor(
    private api: ApiService,
    private router: Router,
    private shared: SharedService
  ) {
    this.branchIdSelected = localStorage.getItem('branchId');
    this.debtorLife = shared.gCurrentPolicyDebtorLife;
    this.shared.gCurrentCustomerIdObservable().subscribe(
      gCustomerId => {
        if (gCustomerId !== -1) {
          this.load();
          this.getStaticsPolicyType();
          this.getFilterDebtorLife();
          this.getStaticsPopulation();
        }
      }
    );
    this.dataTypeFilters = [
      {
        id: 1,
        name: 'Cantidad'
      },
      {
        id: 2,
        name: 'Montos'
      }
    ];

    this.dataTypeFilterSelected = this.dataTypeFilters[0];

    this.dataTypeDetorFilter = [
      {
        id: 1,
        name: 'UDI'
      },
      {
        id: 2,
        name: 'PRIMA'
      }
    ];
    this.dataTypeFilterSelectedDebtorLife = this.dataTypeDetorFilter[0];
  }

  getStaticsPolicyType() {
    this.api.getStaticsPolicyType().then( (dataPolicyType: any) => {
      this.policyTypeList = dataPolicyType;
      if (this.policyTypeList.length !== null && this.policyTypeList.length > 0) {
        this.staticsIncidentPolicyTypeSelected = this.policyTypeList[0];
        this.loadStaticsAccidents(this.policyTypeList[0]);
        this.loadStaticIncident(this.policyTypeList[0]);
        this.selectInsurance(this.policyTypeList[0]);
      }

      // console.log("policyType");
      // console.log(this.policyTypeList);
    });
  }

  changePolicyTypeStaticsAccidents(policyType) {
    this.loadStaticsAccidents(policyType  );
  }

  changePolicyTypeStaticsIncident(policyType) {
    this.staticsIncidentPolicyTypeSelected = policyType;
    this.loadStaticIncident(policyType);
    this.loadStaticsAccidents(policyType);
    this.selectInsurance(policyType);
  }

  public changeDataTypeFilter(dataType) {
    this.dataTypeFilterSelected = dataType;
    this.loadStaticIncident(this.staticsIncidentPolicyTypeSelected);
  }

  loadStaticIncident(policyType) {
    this.api.getStaticsIncident(policyType.typePolicyId, this.dataTypeFilterSelected.id).then((data: any) => {

      if (data == null) {
        this.showStaticsIncident = true;
        return false;
      }
      if (this.chart !== undefined) {
        this.chart.destroy();
      }
      this.showStaticsIncident = false;

      // console.log(data);
      let labels = [];
      let dt = [];
      let bckgnd = [];
      for (let obj in data){
        labels.push(data[obj].incidentType);
        dt.push(data[obj].number);
        bckgnd.push('#1BA8B1');

      }

      let labelName = '';
      switch (this.dataTypeFilterSelected.id) {
        case 1:
          labelName = 'Casos';
          break;
        case 2:
          labelName = 'Monto';
          break;
      }
      this.chart = new Chart(this.chartRef.nativeElement, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: labelName,
            data: dt,
            backgroundColor: bckgnd,
            barPercentage: 0.1,
          }]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
                beginAtZero: true
            },
            x: {
              grid: {
                display: false
              }
            }
          },
          animation: {
            duration: 1000,
            delay: 0,
            loop: false
          },
          // responsiveAnimationDuration: true,
          responsive: true
        }
      });


    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  loadStaticsAccidents(policyType) {
    this.staticsAccidentsPolicyTypeSelected = policyType;
    this.api.getStaticsAccidents(policyType.typePolicyId).then( (data: any) => {

      // console.log(data);

      if (data == null) {
        this.showStaticsAccidentsNoData = true;
        this.showStaticsAccidents = true;
        return false;
      }
      if (this.line1Chart !== undefined) {
        this.line1Chart.destroy();
      }

      this.showStaticsAccidents = false;
      this.showStaticsAccidentsNoData = false;

      let labels = [];
      let dt = [];
      let labelsC = [];
      let dtC = [];
      let bckgnd = [];
      for (let obj in data.incidents){
        labels.push(data.incidents[obj].month);
        dt.push(data.incidents[obj].premium);
      }

      for (let obj in data.colletions){
        labelsC.push(data.colletions[obj].month);
        dtC.push(data.colletions[obj].premium);
      }


      this.line1Chart = new Chart(this.line1ChartRef.nativeElement, {
        type: 'line',
        data: {
          labels, // your labels array
          datasets: [
            {
              label: 'Siniestralidad',
              data: dt, // your data array
              borderColor: '#3BBBAC',
              fill: false
            },
            {
              label: 'Prima neta devengada',
              data: dtC, // your data array
              borderColor: '#1BA8B1',
              fill: false
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            x: {
              display: true
            },
            y: {
              display: true
            },
          }
        }
      });


    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  selectInsurance(policyType) {
    this.api.getStaticsInsurancePremium(policyType.typePolicyId).then((data: any) => {

      if (data == null) {
        this.showStaticsInsurancePremium = true;
        return false;
      }
      if (this.line2Chart !== undefined) {
        this.line2Chart.destroy();
      }
      this.showStaticsInsurancePremium = false;

      // console.log(data);
      // console.log('*******************');
      let labels = [];
      let dt = [];
      let bckgnd = [];
      for (let obj in data){
        labels.push(data[obj].month);
        dt.push(data[obj].premium);
        bckgnd.push('#1BA8B1');

      }

      this.line2Chart = new Chart(this.line2ChartRef.nativeElement, {
        type: 'line',
        data: {
          labels, // your labels array
          datasets: [
            {
              data: dt, // your data array
              borderColor: '#3BBBAC',
              fill: true
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              display: true
            },
            y: {
              display: true
            },
          }
        }
      });

    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });


  }


  goToBack() {
    this.router.navigate(['/admin/home']);
  }
  changePolicyTypeStaticsCollection(policyType) {
    this.getStatics(policyType);
  }
  getStatics(typePolicy) {
    if (this.pieChart !== undefined) {
      this.pieChart.destroy();
    }
    this.api.getStaticsPolicyType().then((dataPolicyType: any) => {
      this.policyTypeCollecionList = dataPolicyType;
      this.staticsCollectionPolicyTypeSelected = this.policyTypeCollecionList[0];
      if (this.policyTypeCollecionList !== null && this.policyTypeCollecionList.length > 0) {
        // this.getChartIncident(this.policyTypeList[0]);
        this.api.getStatusCollection(typePolicy.typePolicyId).then((data: any) => {
          const labels = [];
          const dt = [];
          const bckgnd = [];
          for (const obj in data) {
            labels.push(data[obj].title);
            dt.push(data[obj].value);
            bckgnd.push('#1BA8B1');
          }

          this.pieChart = new Chart(this.pieChartRef.nativeElement, {
            type: 'pie',
            data: {
              datasets: [{
                data: dt,
                backgroundColor: [
                  'rgba(59, 187, 172,0.79)',
                  'rgba(89, 89, 89, 1)',
                  'rgba(33, 185, 235,1)',
                  'rgba(1, 24, 38, 1)',
                ]
              }],

              // These labels appear in the legend and in the tooltips when hovering different arcs
              labels
            },
            options: {
              maintainAspectRatio: false,
            }
          });
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        });
      } else {
        this.staticsAccidentsPolicyTypeSelected = 'Sin tipo de póliza';
        this.policyTypeList = [];
        if (this.pieChart !== undefined) {
          this.pieChart.clear();
          this.pieChart.destroy();
        }
      }
    });
  }

  load() {
    setTimeout(() => {
      this.getStatics(this.policyTypeList[0]);
      // console.log(this.chartRef.nativeElement);
    }, 1000);
  }

  ngOnInit() {
  }

  changeCompanyTypeStaticsDebtorLife(company) {
    this.staticsCompanyTypeSelected = company;
    this.getCharDebtorLife(company);
  }

  public changeDataTypeFilterDebtor(dataType) {
    this.dataTypeFilterSelectedDebtorLife = dataType;
    this.getCharDebtorLife(this.staticsCompanyTypeSelected);
  }

  getFilterDebtorLife() {
    this.api.getStaticsDebtorLifeType().then( (data: any) => {
      this.dataTypeFiltersDebtor = data;
      if (this.dataTypeFiltersDebtor !== null ) {
        this.staticsCompanyTypeSelected = this.dataTypeFiltersDebtor[0];
        this.getCharDebtorLife(this.dataTypeFiltersDebtor[0]);
      }
    });
  }


  getCharDebtorLife(company) {
    // this.staticsCompanyTypeSelected = company;
    this.api.getStaticsDebtorLife(company, this.dataTypeFilterSelectedDebtorLife.id).then((data: any) => {

      if (data == null) {
        this.showStaticsDebtorlife = true;
        return false;
      }
      if (this.line3Chart !== undefined) {
        this.line3Chart.destroy();
      }
      this.showStaticsDebtorlife = false;
      let labels = [];
      let dt = [];
      let bckgnd = [];
      for (let obj in data){
        labels.push(data[obj].monthName);
        dt.push(data[obj].total);
        bckgnd.push('#1BA8B1');
      }

      let labelName = '';
      switch (this.dataTypeFilterSelectedDebtorLife.id) {
        case 1:
          labelName = 'Sumatoria de UDI Pagada';
          break;
        case 2:
          labelName = 'Sumatoria de prima neta acumulada ';
          break;
      }
      this.line3Chart = new Chart(this.line3ChartRef.nativeElement, {
        type: 'bar',
        data: {
          labels,
          datasets: [{
            label: labelName,
            data: dt,
            backgroundColor: bckgnd,
            barPercentage: 0.1,
          }]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
                beginAtZero: true
            },
            xAxes: {
              grid: {
                display: false
              }
            }
          },
          // responsiveAnimationDuration: true,
          animation: {
            duration: 1000,
            delay: 0,
            loop: false
          },
          responsive: true
        }
      });
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  getStaticsPopulation() {
    this.api.getStaticsPopulations()
      .then(
        (response: any) => {
          if (response != null) {
            this.totalPopulations = response;
            console.log(this.totalPopulations);
          }
        }, error => {
          console.info(error);
        });
  }
}
