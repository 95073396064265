<div style="position:relative;height: 100%;width: 340px;padding: 20px">
  <div style="height: 126px;width: 100%; text-align: center;">
    <span class="sx-iconsys-icon" aria-hidden="true" style="float: right;font-size: 22px;font-weight: normal;color: #C4C4C4;cursor: pointer;" (click)="shared.fCloseSidebar()">x</span>
    <p style="color: #1BA8B1;font-size: 16px;font-weight: 600;line-height: 19px;padding-top: 33px;padding-bottom: 33px">
      Reportar
    </p>
    <div style="text-align: left">
      <span style="display: inline-block;vertical-align: middle;color: #1BA8B1;">Reporte</span><span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 240px;border-top: 1px solid #E1E1E1;"></span></div>
  </div>



  <div style="min-height: calc(100% - 180px);width: 100%;color: #1BA8B1">
    <form id="incidentForm" (ngSubmit)="sendIncidentData()" #f="ngForm">
      <div class="form-group form-group-sm">
        <label for="name">Nombre del Siniestro</label>
        <input id="name" type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required>
      </div>
      <div class="form-group form-group-sm">
        <label>Póliza</label>
        <div class="row">
          <div class="col-xs-6" *ngFor="let policy of policiesList; let i = index">
            <div style="cursor: pointer" (click)="selectThisPolicy(policy, i)">
              <div class="badge-policy" [ngStyle]="{'background-color':  (thisStyle.index == i)?thisStyle.color:''} ">
                <img src="{{policy.imageCompany}}" [ngStyle]="{'filter':  (thisStyle.index == i)?'invert(0)':'invert(0.4)'} "/>
              </div>
              <div class="policy-summary">
                <div class="title">{{policy.nameCompany}}</div>
                <div class="subtitle">{{policy.description}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group form-group-sm">
        <label for="typeincident_id">Tipo de siniestro</label>
        <select id="typeincident_id" class="form-control" [(ngModel)]="dataToSend.incidentTypeId" name="typeincident_id" required>
          <option *ngFor="let iType of catIncidentTypes" [value]="iType.incidentTypeId">{{iType.description}}</option>
        </select>
      </div>
      <div class="form-group form-group-sm">
        <label for="description">Descripción</label>
        <textarea id="description" rows="4" class="form-control" [(ngModel)]="dataToSend.description" name="description" required></textarea>
      </div>
      <div class="form-group form-group-sm">
        <label for="description">Número de contacto</label>
        <input id="contact" type="text" class="form-control" [(ngModel)]="dataToSend.contact" name="contact" required>
      </div>
    </form>
  </div>
  <div class="text-center">
    <button type="submit" class="btn btn-xs btn-custom" form="incidentForm">Reportar</button>
  </div>
</div>
