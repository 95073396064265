import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';

const API_REST = environment.api_rest_restructured;
const API_REST_AMMIA_WEB = environment.api_rest_ammia_web_surexs;

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }

  public authentication(credentials) {
    const formParam = new HttpParams()
      .set('username', credentials.username)
      .set('password', credentials.password);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      // this.http.post('http://localhost:8080/api/admin/security/login', formParam, {
      this.http.post(API_REST + '/security/login', formParam, {
        headers: HEADERS
      }).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });

  }

  public getPoliciesSize() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8082/api/policies/size').subscribe( res => {
      this.http.get(API_REST  + '/policies/size').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserPolicies() {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/policies/thumbnail/top').subscribe( res => {
      // this.http.get('http://localhost:8082/api'  + '/policies/thumbnail/top').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getTypePolicies() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8082/api/admin/policies/type/user').subscribe( res => {
      this.http.get(API_REST  + '/policies/type/user').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserincident(typePolicyId) {
    return new Promise((response, error) => {
        this.http.get(API_REST  + '/incident/recents/' + typePolicyId).subscribe( res => {
        // this.http.get('http://localhost:8088/api/incident/recents/' + typePolicyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDetailIncident(incidentId) {
    return new Promise((response, error) => {
        this.http.get(API_REST  + '/incident/detail/' + incidentId).subscribe( res => {
        // this.http.get('http://localhost:8088/api/admin/incident/detail/' + incidentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentsList(page, size) {
    return new Promise((response, error) => {
        this.http.get(`${API_REST}/incident/pages?page=${page}&size=${size}`).subscribe( res => {
        // this.http.get(`http://localhost:8088/api/incident/pages?page=${page}&size=${size}`).subscribe( res => {
        if (res == null) {
          response({total: 0, incidentAdminList: []});
        } else {
          response(res);
        }
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentAttachments(incidentId) {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/incident/' + incidentId + '/anexes').subscribe( res => {
      // this.http.get('http://localhost:8088/api/admin/incident/' + incidentId + '/anexes').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getIncidentLog(incidentId) {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/incident/incident/' + incidentId).subscribe( res => {
      // this.http.get('http://localhost:8088/api/admin/incident/incident/' + incidentId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getCommentsLog(eventId) {
    return new Promise((response, error) => {
        this.http.get(API_REST  + '/logs/comments/' + eventId).subscribe( res => {
        // this.http.get('http://localhost:8090/api'  + '/logs/comments/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getEventLog(page, size) {
    return new Promise((response, error) => {
       // this.http.get(`http://localhost:8094/api/admin/general/events/pages?page=${page}&size=${size}`).subscribe( res => {
       this.http.get(`${API_REST}/general/events/pages?page=${page}&size=${size}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getLogsAttachmentZipFile(eventId) {
    return this.http.get(`${API_REST}/logs/attachments/zip/${eventId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8090/api/logs/attachments/zip/${eventId}`, {responseType: 'arraybuffer'});
  }
  public getLogsAttachmentFile(attachmentId) {
    return this.http.get(`${API_REST}/logs/attachment/document/${attachmentId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8090/api/logs/attachment/document/${attachmentId}`, {responseType: 'arraybuffer'});
  }

  public getUserPoliciesType(id) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8082/api/admin/policies/type/thumbnail/' + id).subscribe( res => {
      this.http.get(API_REST +  '/policies/type/thumbnail/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserTypeIncident(id, policyType, page, size) {
      return this.http.get(`${API_REST}/incident/status/${id}/${policyType}/pages?page=${page}&size=${size}`).pipe(
      // return this.http.get(`http://localhost:8088/api/incident/status/${id}/${policyType}/pages?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, incidentList: []};
        } else {
          return res;
        }
      })
    );
  }

  public getDetailPolicy(id) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8082/api/policies/detail/' + id).subscribe( res => {
      this.http.get(API_REST +  '/policies/detail/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getAttachmentlPolicy(id) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8082/api/admin/policies/attachments/' + id).subscribe( res => {
      this.http.get(API_REST +  '/policies/attachments/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getSubgroupsWithCategories(policyId) {
    return this.http.get(`${API_REST}/policies/groups/${policyId}`);
    // return this.http.get(`http://localhost:8082/api/policies/groups/${policyId}`);
  }

  public getPolicyDetailByCategoryId(categoryId) {
    return this.http.get(`${API_REST}/policies/details/${categoryId}/json`);
    // return this.http.get(`http://localhost:8082/api/policies/details/${categoryId}/json`);
  }
  public getAmountsDetailByCategoryId(categoryId) {
    return this.http.get(`${API_REST}/policies/details/${categoryId}/amounts`);
    // return this.http.get(`http://localhost:8082/api/policies/details/${categoryId}/amounts`);
  }
  public getPolicyFile(policyId) {
    return this.http.get(`${API_REST}/policies/${policyId}/anexes`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8082/api/policies/${policyId}/anexes`, {responseType: 'arraybuffer'});
  }

  public getUserStatusIncident(id) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/incident/status/' + id).subscribe( res => {
      //   this.http.get('http://localhost:8088/api/incident/status/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserProfile() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8086/api/admin/users').subscribe( res => {
      this.http.get(API_REST +  '/users').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCatalogIncidentTypes(typeId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/incident/cat_type_incident/' + typeId).subscribe( res => {
        // this.http.get('http://localhost:8088/api/incident/cat_type_incident/' + typeId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public updateImageProfile(image): Observable<any> {
    return this.http.put(API_REST +  '/users/imageProfile/web', image);
    // return this.http.put('http://localhost:8086/api' +  '/users/imageProfile/web', image);
  }

  public updatePassword(oldpass, newpass) {
    const formParam = new HttpParams()
      .set('oldPassword', oldpass)
      .set('newPassword', newpass);

    const HEADERS = new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.put(API_REST + '/users/password', formParam, {
        // this.http.put('http://localhost:8086/api' + '/users/password', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public postIncidentData(data) {
    return new Promise((response, error) => {
      this.http.post(API_REST +  '/incident', data).subscribe( res => {
        // this.http.post('http://localhost:8088/api/incident', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getcountNotification() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8084/api/admin/notifications').subscribe( res => {
      this.http.get(API_REST +  '/notifications').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getNotificationUser() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8084/api/admin/notifications/user').subscribe( res => {
      this.http.get(API_REST +  '/notifications/user').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserId() {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/users/id').subscribe( res => {
        // this.http.get('http://localhost:8086/api' +  '/users/id').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getUserRoles() {
    return new Promise((response, error) => {
      // this.http.get( 'http://localhost:8086/api/admin/users/roles').subscribe( res => {
      this.http.get(API_REST +  '/users/roles').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getInsuredList(page, size) {
      return this.http.get(`${API_REST}/general/insured?page=${page}&size=${size}`).pipe(
      // return this.http.get(`http://localhost:8094/api/admin/general/insured?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, insuredList: []};
        } else {
          return res;
        }
      })
    );
  }

  public putNotification(notificationId): Observable<any> {
    // return this.http.put(' http://localhost:8084/api' +  '/notifications/' + notificationId, null);
    return this.http.put(API_REST +  '/notifications/' + notificationId, null);
  }

  public deleteNotification(notificationId): Observable<any> {
    // return this.http.delete('http://localhost:8084/api' +  '/notifications/' + notificationId);
   return this.http.delete(API_REST +  '/notifications/' + notificationId);
  }

  /*public getpolicyCertificate(policyId, userId) {
    return new Promise((response, error) => {
      this.http.get(API_REST + '/policies/attachments/certificate/' + policyId + '/' + userId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }*/

  public getPolicyCertificate(policyId) {
    return this.http.get(`${API_REST}/policies/attachments/certificate/${policyId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8082/api/policies/attachments/certificate/${policyId}`, {responseType: 'arraybuffer'});
  }

  public getCarCertificate(carId) {
    return this.http.post(`${API_REST}/policies/attachments/car/certificate`, carId, {responseType: 'arraybuffer'});
    // return this.http.post(`http://localhost:8082/api/admin/policies/attachments/car/certificate`, carId, {responseType: 'arraybuffer'});
  }

  public getPolicyAttachmentFile(attachmentFileId) {
    return this.http.get(`${API_REST}/policies/attachments/document/${attachmentFileId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8082/api/policies/attachments/document/${attachmentFileId}`, {responseType: 'arraybuffer'});
  }
  public getPolicyAttachmentZip(policyId) {
    return this.http.get(`${API_REST}/policies/attachments/document/zip/${policyId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8082/api/admin/policies/attachments/document/zip/${policyId}`, {responseType: 'arraybuffer'});
  }

  public postRecoveyPassword(email) {
    const formParam = new HttpParams()
      .set('email', email);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST + '/security/sendemail/recovery', formParam, {
        // this.http.post('http://localhost:8080/api' + '/security/sendemail/recovery', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validateToken(token) {
    const formParam = new HttpParams()
      .set('token', token);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST + '/security/validete/token', formParam, {
        // this.http.post('http://localhost:8080/api' + '/security/validete/token', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public recoveypassword(newPassword, token) {
    const formParam = new HttpParams()
      .set('token', token)
      .set('newPassword', newPassword.password);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.put(API_REST + '/security/recovery/password', formParam, {
        // this.http.put('http://localhost:8080/api' + '/security/recovery/password', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public validatecode(code, userId) {
    const formParam = new HttpParams()
      .set('code', code)
      .set('userId', userId);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST + '/security/valide/code', formParam, {
        // this.http.post('http://localhost:8080/api' + '/security/valide/code', formParam, {
        headers: HEADERS
      }).subscribe(res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getDetailPolicyjson(id) {
    return new Promise((response, error) => {
       // this.http.get('http://localhost:8082/api/policies/detail/json/' + id).subscribe( res => {
      this.http.get(API_REST +  '/policies/detail/json/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getPolicyDependents(policyId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/policies/dependents/' + policyId).subscribe( res => {
         // this.http.get('http://localhost:8082/api' +  '/policies/dependents/' + policyId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequestTypeGeneral(statusId, page, size) {
    // return this.http.get(`http://localhost:8092/api/requests/general/${statusId}/pages?page=${page}&size=${size}`).pipe(
    return this.http.get(`${API_REST}/requests/general/${statusId}/pages?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestList: []};
        } else {
          return res;
        }
      })
    );
  }
  public postRequestData(data) {
    return new Promise((response, error) => {
      // this.http.post(`http://localhost:8092/api/requests/general`, data).subscribe( res => {
      this.http.post(`${API_REST}/requests/general`, data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getRequestById(requestId) {
    // return this.http.get(`http://localhost:8092/api/admin/requests/general/${requestId}`);
    return this.http.get(`${API_REST}/requests/general/${requestId}`);
  }
  public getStatusCatRequests() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8092/api/requests/status').subscribe( res => {
      this.http.get(API_REST +  '/requests/status').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getRequests(page, size) {
    // return this.http.get(`http://localhost:8092/api/requests/pages?page=${page}&size=${size}`).pipe(
    return this.http.get(`${API_REST}/requests/pages?page=${page}&size=${size}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestAdminList: []};
        } else {
          return res;
        }
      })
    );
  }
  public getLogAttachments(eventId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/logs/attachments/' + eventId).subscribe( res => {
        // this.http.get('http://localhost:8090/api' +  '/logs/attachments/' + eventId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getFinderResult(param) {
    return this.http.get(`${API_REST}/general/searches?search=${param}`);
    // return this.http.get(`http://localhost:8094/api/general/searches?search=${param}`);
  }
  public getInsuranceWithReceipts() {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:8096/api/admin/receipts/insurance`).subscribe( res => {
      this.http.get(`${API_REST}/receipts/insurance`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getReceiptStatusList() {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:8096/api/admin/receipts/status`).subscribe( res => {
      this.http.get(`${API_REST}/receipts/status`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getInsuranceReceiptsList(statusId, insuranceId, page, size) {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:8096/api/receipts/${statusId}/insurance/${insuranceId}?page=${page}&size=${size}`).subscribe( res => {
      this.http.get(`${API_REST}/receipts/${statusId}/insurance/${insuranceId}?page=${page}&size=${size}`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getReceiptAttachment(receiptId) {
    return new Promise((response, error) => {
      // this.http.get(`http://localhost:8096/api/admin/receipts/${receiptId}/anexes`).subscribe( res => {
      this.http.get(`${API_REST}/receipts/${receiptId}/anexes`).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }
  public getAttachmentFile(annexId) {
    return this.http.get(`${API_REST}/receipts/anexes/${annexId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8096/api/admin/receipts/anexes/${annexId}`, {responseType: 'arraybuffer'});
  }
  public getReceiptAttachmentZipFile(receiptId) {
    return this.http.get(`${API_REST}/receipts/anexes/zip/${receiptId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8096/api/receipts/anexes/zip/${receiptId}`, {responseType: 'arraybuffer'});
  }

  public getStaticsIncident(policyTypeId, newFilter) {
    return new Promise((response, error) => {
        this.http.get(API_REST +  '/statistics/' + policyTypeId + '/accidents/' + newFilter).subscribe( res => {
        // this.http.get('http://localhost:8078/api/admin' +  '/statistics/' + policyTypeId + '/accidents/' + newFilter).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsUser() {
    return new Promise((response, error) => {
        this.http.get(API_REST +  '/statistics/users').subscribe( res => {
        // this.http.get('http://localhost:8078/api/admin' +  '/statistics/users').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStatusCollection(subranchId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/statistics/collection/status/' + subranchId).subscribe( res => {
      // this.http.get('http://localhost:8078/api/admin' +  '/statistics/collection/status/' + subranchId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsPopulations() {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/statistics/populations/customers').subscribe( res => {
      // this.http.get('http://localhost:8078/api/admin' +  '/statistics/populations/customers').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsAccidents(policyTypeId) {
    return new Promise((response, error) => {
      // this.http.get( 'http://localhost:8078/api/admin/statistics/accidents/' + policyTypeId).subscribe( res => {
      this.http.get(API_REST +  '/statistics/accidents/' + policyTypeId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsInsurance() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8078/api/admin/statistics/insurances').subscribe( res => {
      this.http.get(API_REST +  '/statistics/insurances').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsInsurancePremium(insuranceId) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/statistics/' + insuranceId + '/premiums').subscribe( res => {
      // this.http.get('http://localhost:8078/api/admin' +  '/statistics/' + insuranceId + '/premiums').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsPolicyType() {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8078/api/admin/statistics/types/policies').subscribe( res => {
      this.http.get(API_REST +  '/statistics/types/policies').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsDebtorLife(company, type) {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/statistics/debtors?company=' + company + '&type=' + type).subscribe( res => {
        // this.http.get('http://localhost:8078/api' +  '/statistics/debtors?company=' + company + '&type=' + type).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getStaticsDebtorLifeType() {
    return new Promise((response, error) => {
      this.http.get(API_REST +  '/statistics/debtors/filters').subscribe( res => {
        // this.http.get('http://localhost:8078/api' +  '/statistics/debtors/filters').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReportsType(dataType) {
    // return this.http.get(`http://localhost:8098/api/admin/reports/${dataType}/types`);
    return this.http.get(`${API_REST}/reports/${dataType}/types`);
  }

  public getReportDataByReportId(reportId) {
    // return this.http.get(`http://localhost:8098/api/admin/reports/${reportId}`);
    return this.http.get(`${API_REST}/reports/${reportId}`);
  }

  /**
   * @deprecated
   */
  public getProvisionalityReport() {
    return this.http.get(`${API_REST}/reports/requests`);
  }
  /**
   * @deprecated
   */
  public getAccidentRateReport() {
    return this.http.get(`${API_REST}/reports/incidents`);
  }

  public getReportFile(reportId) {
    return this.http.get(`${API_REST}/reports/downloads/${reportId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8098/api/admin/reports/downloads/${reportId}`, {responseType: 'arraybuffer'});
  }

  public getAgentsChatSurexs() {
    return this.http.get(`${API_REST}/general/users`);
    // return this.http.get(`http://localhost:8086/api/general/users`);
  }

  public getIncidentAnnexFile(annexId) {
    return this.http.get(`${API_REST}/incident/attachments/${annexId}`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8088/api/incident/attachments/${annexId}`, {responseType: 'arraybuffer'});
  }

  public getPoliciesMin() {
    // return this.http.get(`http://localhost:8092/api/requests/policies`);
    return this.http.get(`${API_REST}/requests/policies`);
  }

  public getPoliciesClientMin() {
    // return this.http.get(`http://localhost:8082/api/admin/policies/policiesClients`);
    return this.http.get(`${API_REST}/policies/policiesClients`);
  }

  public getPoliciesClientCarMin() {
    // return this.http.get(`http://localhost:8082/api/admin/policies/policiesCarClients`);
    return this.http.get(`${API_REST}/policies/policiesCarClients`);
  }

  public getPoliciesRequestTemp() {
    // return this.http.get(`http://localhost:8092/api/requests/policiesClients`);
    return this.http.get(`${API_REST}/requests/policiesClients`);
  }

  public getPoliciesDependent(dependentId) {
    // return this.http.get(`http://localhost:8092/api/requests/policies/dependent/${dependentId}`);
    return this.http.get(`${API_REST}/requests/policies/dependent/${dependentId}`);
  }
  public getVehiclePolicies() {
    // return this.http.get(`http://localhost:8092/api/requests/policies/cars`);
    return this.http.get(`${API_REST}/requests/policies/cars`);
  }
  public getVehiclePolicyByCarId(carId) {
    return this.http.get(`http://localhost:8092/api/admin/requests/policies/cars/${carId}`);
    // return this.http.get(`${API_REST}/requests/policies/cars/${carId}`);
  }
  public postVehicleRequestData(data) {
    // return this.http.post(`http://localhost:8092/api/requests/cars/registrations`, data);
    return this.http.post(`${API_REST}/requests/cars/registrations`, data);
  }
  public postCancellationVehicleRequestData(data) {
    // return this.http.post(`http://localhost:8092/api/admin/requests/cars/cancellations`, data);
    return this.http.post(`${API_REST}/requests/cars/cancellations`, data);
  }
  public postGeneralVehicleRequestData(data) {
    // return this.http.post(`http://localhost:8092/api/requests/general/cars`, data);
    return this.http.post(`${API_REST}/requests/general/cars`, data);
  }

  public postRequestInsuredAdd(data) {
    // return this.http.post(`http://localhost:8092/api/requests/insured/registrations`, data);
    return this.http.post(`${API_REST}/requests/insured/registrations`, data);
  }
  public getFinderInsured(data) {
    // return this.http.get(`http://localhost:8094/api/admin/general/insuredSearch?search=${data}`);
    return this.http.get(`${API_REST}/general/insuredSearch?search=${data}`);
  }

  public getFinderCar(data) {
    // return this.http.get(`http://localhost:8094/api/admin/general/carSearch?search=${data}`);
    return this.http.get(`${API_REST}/general/carSearch?search=${data}`);
  }
  public getPoliciesMinByInsuredId(insuredId) {
    // return this.http.get(`http://localhost:8092/api/requests/policies/${insuredId}`);
    return this.http.get(`${API_REST}/requests/policies/${insuredId}`);
  }

  public getPoliciesClientMinByInsuredId(insuredId) {
    // return this.http.get(`http://localhost:8082/api/policies/policySubranchCategoryByDependent/${insuredId}`);
    return this.http.get(`${API_REST}/policies/policySubranchCategoryByDependent/${insuredId}`);
  }

  public getPoliciesClientCarId(carId) {
    // return this.http.post(`http://localhost:8082/api/admin/policies/policySubranchCategoryByCarId`, carId);
    return this.http.post(`${API_REST}/policies/policySubranchCategoryByCarId`, carId);
  }

  public getRelationshipCat() {
    // return this.http.get(`http://localhost:8092/api/requests/kinships`);
    return this.http.get(`${API_REST}/requests/kinships`);
  }
  public postRequestDependentAdd(data) {
    // return this.http.post(`http://localhost:8092/api/requests/dependents/registrations`, data);
    return this.http.post(`${API_REST}/requests/dependents/registrations`, data);
  }
  public postRequestInsuredRemove(data) {
    // return this.http.post(`http://localhost:8092/api/requests/insured/cancellations`, data);
    return this.http.post(`${API_REST}/requests/insured/cancellations`, data);
  }
  public getDependentsByInsuredId(insuredId) {
    // return this.http.get(`http://localhost:8092/api/requests/${insuredId}/dependents`);
     return this.http.get(`${API_REST}/requests/${insuredId}/dependents`);
  }
  public postRequestDependentRemove(data) {
    // return this.http.post(`http://localhost:8092/api/requests/dependents/cancellations`, data);
    return this.http.post(`${API_REST}/requests/dependents/cancellations`, data);
  }
  public getGendersCatalog() {
    // return this.http.get(`http://localhost:8092/api/requests/genders`);
    return this.http.get(`${API_REST}/requests/genders`);
  }

  public getReportId(reportId) {
    return new Promise((response, error) => {
      // this.http.get('http://localhost:8098/api/admin/reports/' + reportId).subscribe( res => {
      this.http.get(API_REST  + '/reports/' + reportId).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReporFilters(reportId, id) {
    return new Promise((response, error) => {
      this.http.get(API_REST  + '/reports/' + reportId + '/filters/' + id).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReporFiltersSearch(reportId, kinship, date, movement) {
    return this.http.get(`${API_REST}/reports/${reportId}/filters?kinship=${kinship}&date=${date}&movement=${movement}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestList: []};
        } else {
          return res;
        }
      })
    );
  }

  public getFileRequest() {
    // return this.http.get(`http://localhost:8092/api/admin/requests/downloads`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/requests/downloads`, {responseType: 'arraybuffer'});
  }
  public getFileVehicleRequest() {
    // return this.http.get(`http://localhost:8092/api/requests/downloads/cars`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/requests/downloads/cars`, {responseType: 'arraybuffer'});
  }

  public getFileReceipt() {
    return this.http.get(`${API_REST}/receipts/downloads`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8096/api/receipts/downloads`, {responseType: 'arraybuffer'});
  }

  public getFileIncident() {
    return this.http.get(`${API_REST}/incident/downloads`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8088/api/incident/downloads`, {responseType: 'arraybuffer'});
  }
  public getFileVehicle() {
    return this.http.get(`${API_REST}/general/downloads/cars`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8094/api/general/downloads/cars`, {responseType: 'arraybuffer'});
  }

  public getFilePolicy() {
    return this.http.get(`${API_REST}/policies/downloads`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8082/api/policies/downloads`, {responseType: 'arraybuffer'});
  }

  public getFileInsured() {
    return this.http.get(`${API_REST}/general/downloads/insured`, {responseType: 'arraybuffer'});
    // return this.http.get(`http://localhost:8094/api/general/downloads/insured`, {responseType: 'arraybuffer'});
  }

  public getLayoutMassiveLoadInsured() {
    // return this.http.get(`http://localhost:8092/api/requests/imports/layouts`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/requests/imports/layouts`, {responseType: 'arraybuffer'});
  }

  public getRequestTemp() {
      // return this.http.get('http://localhost:8092/api/requests/getRequestTemp');
      return this.http.get(`${API_REST}/requests/getRequestTemp`);
  }

  public getRequestTempDetail(idRequestTemp) {
    // return this.http.get(`http://localhost:8092/api/requests/getRequestTempDetail/` + idRequestTemp);
    return this.http.get(`${API_REST}/requests/getRequestTempDetail/` + idRequestTemp);
  }

  public getRequestTempUpdated(idRequestTemp) {
    // return this.http.get(`http://localhost:8092/api/requests/getRequestTemp/` + idRequestTemp);
    return this.http.get(`${API_REST}/requests/getRequestTemp/` + idRequestTemp);
  }

  public editRequestTempDetail(RequestTempDetailList) {
    // return this.http.post(`http://localhost:8092/api/admin/requests/editRequestTemp`, RequestTempDetailList);
    return this.http.post(`${API_REST}/requests/editRequestTemp`, RequestTempDetailList);
  }

  public processMassiveRequest(RequestTempDetailList) {
    // return this.http.post(`http://localhost:8092/api/admin/requests/imports`, RequestTempDetailList);
    return this.http.post(`${API_REST}/requests/imports`, RequestTempDetailList);
  }

  public postMassiveLoadLayoutInsured(data) {
    return this.http.post(`${API_REST}/requests/saveRequestTemp`, data);
    // return this.http.post(`http://localhost:8092/api/requests/saveRequestTemp`, data);
  }

  public deleteRequestTemp(idRequestTemp) {
    // return this.http.delete(`http://localhost:8092/api/requests/deleteRequestTemp/` + idRequestTemp);
    return this.http.delete(`${API_REST}/requests/deleteRequestTemp/` + idRequestTemp);
  }

  public getLayoutMassiveLoadVehicle() {
    // return this.http.get(`http://localhost:8092/api/requests/imports/layouts/cars`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/requests/imports/layouts/cars`, {responseType: 'arraybuffer'});
  }
  public postMassiveLoadLayoutVehicle(data) {
    // return this.http.post(`http://localhost:8092/api/requests/imports/cars`, data);
    return this.http.post(`${API_REST}/requests/imports/cars`, data);
  }
  public getVehicleRequests(page, size) {
    // return this.http.get(`http://localhost:8092/api/admin/requests/cars/pages?size=${size}&page=${page}`).pipe(
    return this.http.get(`${API_REST}/requests/cars/pages?size=${size}&page=${page}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, requestCarList: []};
        } else {
          return res;
        }
      })
    );
  }

  public getVehiclesList(page, size) {
    return this.http.get(`${API_REST}/general/cars/pages?size=${size}&page=${page}`).pipe(
    // return this.http.get(`http://localhost:8094/api/admin/general/cars/pages?size=${size}&page=${page}`).pipe(
      map(res => {
        if (res === null) {
          return {total: 0, carList: []};
        } else {
          return res;
        }
      })
    );
  }
  public getFinderVehicleSerial(data) {
    // return this.http.get(`http://localhost:8092/api/requests/cars?serialNumber=${data}`);
    return this.http.get(`${API_REST}/requests/cars?serialNumber=${data}`);
  }
  public getDataTypes() {
    // return this.http.get(`http://localhost:8092/api/admin/requests/types`);
    return this.http.get(`${API_REST}/requests/types`);
  }

  public getCustomerList() {
    // return this.http.get(`http://localhost:8094/api/admin/general/customers`);
    return this.http.get(`${API_REST}/general/customers`);
  }

  public getMonthsdebtors(type) {
    // return this.http.get(`http://localhost:8082/api/admin/policies/debtors/months/${type}`);
    return this.http.get(`${API_REST}/policies/debtors/months/${type}`);
  }

  public getFilesdebtors(months) {
    // return this.http.get(`http://localhost:8082/api/policies/debtors/files?monthId=${months.monthId}&year=${months.year}`);
    return this.http.get(`${API_REST}/policies/debtors/files?monthId=${months.monthId}&year=${months.year}`);
  }

  public getDebtorLifeDataByDebtorLifeId(id) {
    // return this.http.get(`http://localhost:8082/api/policies/debtors/files/${id}?page=1&size=10`);
    return this.http.get(`${API_REST}/policies/debtors/files/${id}?page=1&size=10`);
  }

  public getDebtoLifeFile(fileId) {
    // return this.http.get(`http://localhost:8082/api/policies/debtors/files/downloads?fileId=${fileId}`, {responseType: 'arraybuffer'});
    return this.http.get(`${API_REST}/policies/debtors/files/downloads?fileId=${fileId}`, {responseType: 'arraybuffer'});
  }

  public postLoadLayoutDebtorLife(data, monthId, year) {
    return this.http.post(`${API_REST}/policies/debtors/customers?monthId=${monthId}&year=${year}`, data);
    // return this.http.post(`http://localhost:8082/api/policies/debtors/customers?monthId=${monthId}&year=${year}`, data);
  }

  public postAuthorizeFileDebtorLife(fileDebtorId, explication, authorize) {
    const formParam = new HttpParams()
      .set('debtorLifeId', fileDebtorId)
      .set('explication', explication)
      .set('authorize', authorize);

    const HEADERS = new HttpHeaders();
    HEADERS.append('content-type', 'application/x-www-form-urlencoded');
    return new Promise((response, error) => {
      this.http.post(API_REST + '/policies/debtors/validations', formParam, {
        // this.http.post('http://localhost:8082/api' + '/policies/debtors/validations', formParam, {
        headers: HEADERS
      }).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  /* -----------------------------------------------------------------------------------------------------
 --------------------------------  P O L I C Y   CAR----------------------------------------------------
 ----------------------------------------------------------------------------------------------------- */
  public getMakeCatalogist(data) {
    return new Promise((response, error) => {
      // this.http.post('https://qa.ammia.store/api/ammia/v1.0/public/emission/catalog/getMakesCatalogList', data).subscribe( res => {
      this.http.post(API_REST_AMMIA_WEB +  '/emission/catalog/getMakesCatalogList', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getModelYearCatalogist() {
    return new Promise((response, error) => {
      // this.http.get('https://qa.ammia.store/api/ammia/v1.0/public/emission/catalog/getModelYearCatalogList').subscribe( res => {
      this.http.get(API_REST_AMMIA_WEB +  '/emission/catalog/getModelYearCatalogList').subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getCarVersionCatalogist(data) {
    return new Promise((response, error) => {
      // this.http.post('https://qa.ammia.store/api/ammia/v1.0/public/emission/catalog/getCarVersionCatalogList', data).subscribe( res => {
      this.http.post(API_REST_AMMIA_WEB +  '/emission/catalog/getCarVersionCatalogList', data).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }


  public getBranchsByClient() {
    // return this.http.get(`http://localhost:8094/api/admin/general/getBranchByClient`);
    return this.http.get(`${API_REST}/general/getBranchByClient`);
  }

  public getRequestByIdrequest(requestId) {
    // return this.http.get(`http://localhost:8092/api/admin/requests/requestById/${requestId}`);
    return this.http.get(`${API_REST}/requests/requestById/${requestId}`);
  }

  public postRequestCarAdd(data) {
    // return this.http.post(`http://localhost:8092/api/admin/requests/cars/registrations`, data);
    return this.http.post(`${API_REST}/requests/cars/registrations`, data);
  }

  public getUseCatalog() {
    return new Promise((response, error) => {

      // this.http.post('http://localhost:8092/api/admin/requests/use', {}).subscribe( res => {
      this.http.post(`${API_REST}/requests/use`, {}).subscribe( res => {
        response(res);
      }, (err) => {
        error(err);
      });
    });
  }

  public getReceptisByBranchid(filter) {
    // return this.http.post(`http://localhost:8096/api/admin/receipts/receipts`, filter);
    return this.http.post(`${API_REST}/receipts/receipts`, filter);
  }

  public getReceptisPaginatedByBranchid() {
    // return this.http.post(`http://localhost:8096/api/admin/receipts/receiptsPaginated`, {});
    return this.http.post(`${API_REST}/requests/receiptsPaginated`, {});
  }
}
