<div>
  <div style="background-color: #ffffff; border-radius: 5px; padding-top: 30px;box-shadow: 0 5px 25px 0 rgba(41, 63, 121, 0.05);">
    <div class="row">
      <div class="col-md-12">
        <div class="title-section pull-left">Pólizas</div>
        <i class="glyphicon glyphicon-resize-small pull-right" (click)="goToBack()" style="cursor: pointer;" ></i>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col-md-2" style="border-right: 1px solid #EAECEF;">
        <div class="title-section pull-right" style="padding: 4px">
          <div class="btn-group pull-right" dropdown>
            <button id="button-report-types" dropdownToggle type="button" class="btn btn-link dropdown-toggle subtitle-section-menu" >
              {{typePolicySelected.description}}
              <span class="sx-iconsys-icon">1</span>
            </button>
            <ul id="dropdown-report-type" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-report-types">
              <li role="menuitem" *ngFor="let c of typePolicyList" (click)="getPoliciesByTypeId(c)">
                <a class="dropdown-item" href="javascript:;" >{{c.description}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="pull-left">
          <div class="pull-left">
            <i class="glyphicon glyphicon-list" [ngClass]="!reportGridViewTypeLeft?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer;" (click)="reportGridViewTypeLeft = false;"></i>
            <i class="glyphicon glyphicon-th-large" [ngClass]="reportGridViewTypeLeft?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer" (click)="reportGridViewTypeLeft = true;"></i>
          </div>
        </div>

        <div class="col-md-12 policy-panel scrollArea" [hidden]="!reportGridViewTypeLeft" style="overflow-y: auto; max-height: 55vh;">
          <ul>
            <li *ngFor="let u of policyList" style="display: flex;">
              <div class="policy-card" [ngClass]="{'policy-card-unselected' : policyDetail.policyId !== u.policyId}" (click)="displayPolicyDetail(u.policyId);"
                   [ngStyle]="{'background': 'linear-gradient(206.57deg, rgba(255, 255, 255, 0.2) -90%, ' + u.colorCompanyHex + ' 60%)'
                              ,'box-shadow': '0px 15px 30px -20px ' + hexToRgb(u.colorCompanyHex, 0.5)}">
                <img src="{{u.imageCompany}}" />
              </div>
            </li>

          </ul>
        </div>

        <div class="col-md-12" [hidden]="reportGridViewTypeLeft">
          <table class="table table-striped table-hover table-condensed">
            <thead>
            <tr>
              <th>Compañía</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of policyList">
              <td (click)="displayPolicyDetail(item.policyId);"  [ngClass]="{'policy-card-unselected-2' : policyDetail.policyId !== item.policyId}" style="color: #ffffff;" [ngStyle]="{'background': 'linear-gradient(206.57deg, rgba(255, 255, 255, 0.2) -90%, ' + item.colorCompanyHex + ' 60%)'}">

                  {{item.nameCompany}}

              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div class="col-md-3">
        <div class="p-summary">
          <p class="title-section">{{ policyDetail.nameCompany }}</p>
          <p class="data">{{ policyDetail.type }}</p>
          <p class="data">{{ policyDetail.number }}</p>
          <p class="data"><strong>Vigencia:</strong> {{ policyDetail.expirationDate | date: 'dd/MM/yyyy' }}</p>
          <p class="data"><strong>Contratado:</strong> {{ policyDetail.startDate | date: 'dd/MM/yyyy' }}</p>
          <div class="form-group" style="margin-bottom: 0">
            <div class="btn-group" dropdown [isDisabled]="subgroupList?.length <= 1">
              <button id="button-subgroups-types" dropdownToggle type="button" class="btn btn-xs btn-link dropdown-toggle dropdown-link-menu" aria-controls="dropdown-subgroups-types">
                {{lclSubgroupSelected.subGroupName}} <span class="sx-iconsys-icon">1</span>
              </button>
              <ul id="dropdown-subgroups-types" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="button-subgroups-types">
                <li role="menuitem" *ngFor="let item of subgroupList">
                  <a class="dropdown-item" href="javascript:;" (click)="changeLclSubgroupSelected(item)">{{item.subGroupName}}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group" style="margin-bottom: 0">
            <div class="btn-group" dropdown [isDisabled]="lclSubgroupSelected.categoryList?.length <= 1">
              <button id="button-categories-types" dropdownToggle type="button" class="btn btn-xs btn-link dropdown-toggle dropdown-link-menu" aria-controls="dropdown-categories-types">
                {{lclCategorySelected.categoryName}} <span class="sx-iconsys-icon">1</span>
              </button>
              <ul id="dropdown-categories-types" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="button-categories-types">
                <li role="menuitem" *ngFor="let item of lclSubgroupSelected.categoryList">
                  <a class="dropdown-item" href="javascript:;" (click)="changeLclCategorySelected(item)">{{item.categoryName}}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="action"><a href="javascript:;" (click)="invokeServiceForDownloadPolicyFile()">Versión impresa</a></div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-2 policy-detail">
            <span class="title">Prima neta</span>
            <br>
            <span class="subtitle">{{amountDetail.netPremium || 0 | CustomCurrency}}</span>
          </div>
          <div class="col-md-2 policy-detail">
            <span class="title">IVA</span>
            <br>
            <span class="subtitle">{{amountDetail.iva || 0 | CustomCurrency }}</span>
          </div>
          <div class="col-md-2 policy-detail">
            <span class="title">Derechos</span>
            <br>
            <span class="subtitle">{{amountDetail.rights || 0 | CustomCurrency}}</span>
          </div>
          <div class="col-md-2 policy-detail">
            <span class="title">Pagos parciales</span>
            <br>
            <span class="subtitle">{{amountDetail.partialPaymentCharges || 0 | CustomCurrency }}</span>
          </div>
          <div class="col-md-2 policy-detail">
            <span class="title">Prima total</span>
            <br>
            <span class="subtitle">{{amountDetail.totalPremium || 0 | CustomCurrency }}</span>
          </div>
        </div>
        <hr>
        <div class="row mt-20">
          <div class="col-md-3 policy-detail" *ngFor="let d of policyDetailJson" [hidden]="!d.visible">
            <span class="title">{{d.description}}</span>
            <br>
            <span class="subtitle">{{d.value}}</span>
          </div>
        </div>
        <div class="row mt-20">
          <div class="col-md-12 title-section">
            <div class="pull-left" style="display: flex;"> Anexos&nbsp;&nbsp;
              <div style="background-color: #E2E5F0;border-radius: 50%;width: 20px;height: 20px; display: flex;">
              <span style=" color: #7A7875; margin: auto;
                  font-size: 12px;
                  text-align: center;">{{policyAttachmentList.length}}</span>
              </div>
            </div>

            <div class="pull-right">
              <div class="pull-right">
                <i class="glyphicon glyphicon-list" [ngClass]="!reportGridViewType?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer;" (click)="reportGridViewType = false;"></i>
                <i class="glyphicon glyphicon-th-large" [ngClass]="reportGridViewType?'view-select':''" style="display: inline-block; margin-left: 5px;padding: 4px;vertical-align: middle;cursor: pointer" (click)="reportGridViewType = true;"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12" [hidden]="reportGridViewType">
          <table class="table table-striped table-hover table-condensed">
            <thead>
            <tr>
              <th>Nombre</th>
              <th>-</th>

            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of policyAttachmentList">
              <td>
                  {{item.name}}
              </td>
              <td>
                <a style="text-decoration: none;  cursor: pointer; font-size: 20px;" target="_blank" [attr.href]="item.typeAttachment==2?item.url:undefined" (click)="item.typeAttachment==1?invokeServiceForGetAttachmentFile(item):undefined" title="{{item.name}}">
                  <span class="sx-iconsys-icon" style="color: #aeb0b4;">d</span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="col-md-12" *ngIf="policyAttachmentList !== undefined && policyAttachmentList !== null && policyAttachmentList.length > 0">
            <button type="button" class="pull-right btn btn-xs btn-custom"><a style="text-decoration: none;color: #1BA8B1;" href="javascript:;" (click)="invokeServiceForGetPolicyAttachmentZipFile(policyDetail)">Descargar todos</a></button>
          </div>

        </div>

        <div class="col-md-12"  [hidden]="!reportGridViewType">
          <div class="col-md-2 file" *ngFor="let item of policyAttachmentList" >
            <a style="text-decoration: none; cursor: pointer;" target="_blank" [attr.href]="item.typeAttachment==2?item.url:undefined" (click)="item.typeAttachment==1?invokeServiceForGetAttachmentFile(item):undefined" title="{{item.name}}">
              <span style="font-size: 50px;color: #EFF2F7;" class="sx-iconsys-icon">d</span>
              <br>
              <span style="width: 100%;color: #1BA8B1;font-size: 12px;">
                {{item.name}}
              </span>
            </a>
          </div>

          <div class="col-md-12" *ngIf="policyAttachmentList !== undefined && policyAttachmentList !== null && policyAttachmentList.length > 0">
            <button type="button" class="pull-right btn btn-xs btn-custom"><a style="text-decoration: none;color: #1BA8B1;" href="javascript:;" (click)="invokeServiceForGetPolicyAttachmentZipFile(policyDetail)">Descargar todos</a></button>
          </div>





        </div>

      </div>


      <div class="col-md-12 title-section">
        <div class="col-md-12"><br><br><br></div>

      </div>
    </div>
  </div>
</div>
